/**
 * Import modules
 */
import { Form, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { FaAsterisk } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import Switch from 'react-switch';
/**
 * Import components
 */
import Message from '../Message/Message';
import Buttons from '../Buttons/Buttons';

/**
 * Import Contexts
 */
import { useLoadingContext } from '../../contexts/loadingContext';

/**
 * Import locals
 */
import './BackOfficeModal.scss';
import {
	createAssociation,
	createUser,
	createReciever,
} from '../../requests/AdminRequest';
import utility from '../../utility';
import contents from '../../contents/contents.json';

const initialNewUserState = {
	last_name: '',
	first_name: '',
	email: '',
	phone: '',
	roleId: '',
	role_id: '',
	status: 'active',
	subvention_create_right: false,
	subvention_modify_right: false,
	subvention_extract_right: false,
	add_documents_right: false,
	project_create_right: false,
	project_modify_right: false,
	project_extract_right: false,
	all_document_read_right: false,
	admin_right: false,
	association_id: '',
	reciever_id: '',
};

const initialNewAssociationState = {
	name: '',
	address: '',
	zipcode: '',
	city: '',
};

const initialNewRecieverState = {
	name: '',
	user_id: '',
	association_id: '',
	status: 'active',
};

const initialValidUserState = {
	last_name: false,
	first_name: false,
	email: false,
	phone: false,
	roleId: false,
	role_id: false,
	status: false,
};

const initialValidAssociationState = {
	name: false,
	address: false,
	zipcode: false,
	city: false,
};

const initialValidRecieverState = {
	name: false,
	user_id: false,
	association_id: false,
};

const BackOfficeModal = ({
	show,
	onHide,
	tabKey,
	adminInitialisation,
	setShowAddModal,
}) => {
	const { actions } = useLoadingContext();

	let history = useHistory();
	const [newUser, setNewUser] = useState(initialNewUserState);
	const [errorMessage, setErrorMessage] = useState(null);

	const [isNewUserValid, setIsNewUserValid] = useState(initialValidUserState);

	const [newAssociation, setNewAssociation] = useState(
		initialNewAssociationState
	);
	const [isNewAssociationValid, setIsNewAssociationValid] = useState(
		initialValidAssociationState
	);

	const [newReciever, setNewReciever] = useState(initialNewRecieverState);
	const [isNewRecieverValid, setIsNewRecieverValid] = useState(
		initialValidRecieverState
	);

	const [roleOptionHTML, setRoleOptionHTML] = useState([]);
	const [assosOptionsHTML, setAssosOptionsHTML] = useState([]);
	const [recieverOptionsHTML, setRecieverOptionsHTML] = useState([]);
	const [statusOptionsHTML, setStatusOptionsHTML] = useState([]);

	const handleNewUserInputChange = async (evt) => {
		const editUser = { ...newUser };
		const { id, value } = evt.target;
		setErrorMessage(null);

		switch (id) {
			case 'roleId':
				editUser[id] = parseInt(value);
				editUser['role_id'] = parseInt(value);
				if (value === '3') {
					editUser.association_id = '';
				}
				break;
			case 'association_id':
				editUser[id] = parseInt(value);
				break;
			case 'phone':
				editUser[id] = value.replace(
					/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
					`$1 $2 $3 $4 $5`
				);
				break;

			default:
				editUser[id] = value;
		}
		setNewUser(editUser);
	};

	const handleSwitchChange = async (value, evt, id) => {
		const editUser = { ...newUser };
		editUser[id] = value;
		setNewUser(editUser);
	};

	const handleNewAssociationInputChange = async (evt) => {
		const editAssociation = { ...newAssociation };
		const { id, value } = evt.target;
		setErrorMessage(null);

		editAssociation[id] = value;

		setNewAssociation(editAssociation);
	};

	const handleNewRecieverInputChange = async (evt) => {
		const editReciever = { ...newReciever };
		let { id, value } = evt.target;
		setErrorMessage(null);

		if (id === 'association_id' || id === 'user_id') {
			value = parseInt(value);
		}

		editReciever[id] = value;

		setNewReciever(editReciever);
	};

	const handleSubmit = async (evt) => {
		evt.preventDefault();

		actions.setter(true, true);

		let message = null;

		let testValidity;
		switch (tabKey) {
			case 'utilisateurs':
				testValidity = Object.values(isNewUserValid).indexOf(false);

				if (testValidity >= 0) {
					let property;
					switch (Object.entries(isNewUserValid)[testValidity][0]) {
						case 'last_name':
							property = 'Le nom';
							break;
						case 'first_name':
							property = 'Le prénom';
							break;
						case 'email':
							property = "L'email";
							break;
						case 'phone':
							property = 'Le téléphone';
							break;
						case 'roleId':
							property = 'Le role';
							break;
						case 'association_id':
							property = "L'association";
							break;

						default:
							property = Object.entries(isNewUserValid)[testValidity][0];
					}
					message = `${property} ${contents.messages.propertyError}`;
					setErrorMessage(message);
				}

				if (testValidity === -1) {
					const res = await createUser(newUser);
					switch (res.status) {
						case 409:
							setErrorMessage(contents.messages.duplicateMailError);
							break;

						case 404:
							history.push('/404');
							break;

						case 200:
							await setNewUser(initialNewUserState);
							await setIsNewUserValid(initialValidUserState);
							await setErrorMessage(null);
							await setShowAddModal(false);
							break;

						default:
							setErrorMessage(contents.messages.duplicateMailError);
							break;
					}
				}
				break;

			case 'associations':
				testValidity = Object.values(isNewAssociationValid).indexOf(false);

				if (testValidity >= 0) {
					const property =
						Object.entries(isNewUserValid)[testValidity][0] === 'name'
							? 'Le nom'
							: Object.entries(isNewUserValid)[testValidity][0] === 'address'
							? "L'adresse"
							: Object.entries(isNewUserValid)[testValidity][0] === 'zipcode'
							? 'Le code postal'
							: 'La ville';

					message = `${property} ${contents.messages.propertyError}`;
					setErrorMessage(message);
				}

				if (testValidity === -1) {
					const res = await createAssociation(newAssociation);
					switch (res.status) {
						case 404:
							history.push('/404');
							break;

						case 200:
							await setNewAssociation(initialNewAssociationState);
							await setIsNewAssociationValid({
								name: false,
								address: false,
								zipcode: false,
								city: false,
							});
							await setErrorMessage(null);
							await setShowAddModal(false);
							break;

						default:
							if (res.message === 'name must be unique') {
								message = contents.messages.uniqueError;
							}

							message = contents.messages.serverError;
							setErrorMessage(message);
							break;
					}
				}
				break;

			case 'beneficiaires':
				testValidity = Object.values(isNewRecieverValid).indexOf(false);

				if (testValidity >= 0) {
					const property =
						Object.entries(isNewRecieverValid)[testValidity][0] === 'name'
							? 'Le nom'
							: Object.entries(isNewRecieverValid)[testValidity][0] ===
							  'user_id'
							? "L'utilisateur"
							: Object.entries(isNewRecieverValid)[testValidity][0] ===
							  'association_id'
							? "L'association"
							: 'Le status';

					message = `${property} ${contents.messages.propertyError}`;
					setErrorMessage(message);
				}

				if (testValidity === -1) {
					const res = await createReciever(newReciever);
					switch (res.status) {
						case 404:
							history.push('/404');
							break;

						case 200:
							await setNewReciever(initialNewRecieverState);
							await setIsNewRecieverValid(initialValidRecieverState);
							await setErrorMessage(null);
							await setShowAddModal(false);
							break;

						default:
							message = contents.messages.serverError;
							setErrorMessage(message);
							break;
					}
				}
				break;
			default:
				break;
		}
		actions.setter(false, true);
	};

	const handleClickCancel = async (evt) => {
		evt.preventDefault();
		await setShowAddModal(!show);
		await setNewUser(initialNewUserState);
		await setNewAssociation(initialNewAssociationState);
		await setNewReciever(initialNewRecieverState);

		await setIsNewUserValid(initialValidUserState);
		await setIsNewAssociationValid(initialValidAssociationState);
		await setIsNewRecieverValid(initialValidRecieverState);

		await setErrorMessage(null);
	};

	/**
	 * Create Select's options
	 * Trigger: adminInitialisation, newReciever.association_id
	 */
	useEffect(() => {
		if (adminInitialisation?.roleList) {
			setAssosOptionsHTML(
				adminInitialisation.associationList &&
					adminInitialisation.associationList.map((asso, i) => (
						<option
							key={i + asso.id}
							value={asso.id}
						>
							{asso.name}
						</option >
					))
			);

			adminInitialisation.recieverList.filter(
				(reciever) => reciever.association_id === newUser.association_id
			).length > 0
				? setRecieverOptionsHTML(
						adminInitialisation.recieverList
							.filter(
								(receiver) =>
									receiver.association_id === newUser.association_id &&
									receiver.user_id === null
							)

							.map((receiver, i) => (
								<option
									key={i + receiver.id}
									value={receiver.id}
								>
									{receiver.name}
								</option >
							))
				  )
				: setRecieverOptionsHTML(
						<option
							key={'7b98cc72-d111-4d08-845f-04b8b574ec07'}
							value={null}
						>
							{contents.messages.noReceiversOptions}
						</option >
				  );

			setRoleOptionHTML(
				adminInitialisation.roleList.map((role, i) => (
					<option
						key={role.id + '-' + role.title + '-' + i}
						value={role.id}
					>
						{role.title2}
					</option >
				))
			);

			setStatusOptionsHTML(
				['active', 'inactive'].map((status, i) => (
					<option
						key={i + status}
						value={status}
					>
						{status === 'active'
							? contents.options.active
							: contents.options.inactive}
					</option >
				))
			);
		}
	}, [adminInitialisation, newUser, newReciever.association_id]);

	/**
	 * Validate new user
	 * Trigger: newUser
	 */
	useEffect(() => {
		const userToCreate = { ...newUser };
		delete userToCreate.name;
		const editIsValid = utility.regexValidation(newUser, isNewUserValid);
		setIsNewUserValid(editIsValid);
	}, [newUser]);

	/**
	 * Validate new association
	 * Trigger: newAssociation
	 */
	useEffect(() => {
		let editIsValid = { ...isNewAssociationValid };
		for (const [key, value] of Object.entries(newAssociation)) {
			new RegExp('^[a-zA-Z \\d]{2,}').test(value)
				? (editIsValid[key] = true)
				: (editIsValid[key] = false);
		}
		setIsNewAssociationValid(editIsValid);
	}, [newAssociation]);

	/**
	 * Validate new reciever
	 * Trigger: newReciever
	 */
	useEffect(() => {
		let editIsValid = { ...isNewRecieverValid };
		const createReciever = { ...newReciever };
		delete createReciever.user_id;
		for (const [key, value] of Object.entries(createReciever)) {
			new RegExp('^(?!\\s*$).+').test(value)
				? (editIsValid[key] = true)
				: (editIsValid[key] = false);
		}
		editIsValid.user_id = true;
		setIsNewRecieverValid(editIsValid);
	}, [newReciever]);

	return (
		<Modal
			key={'b640e24c-301b-11ec-8d3d-0242ac130003'}
			show={show}
			onHide={onHide}
			size={'xl'}
			aria-labelledby={'modals-content-option'}
			centered
			restoreFocus
			dialogClassName={'add-modals'}
		>
			<Modal.Header
				closeButton
			>
				<Modal.Title >
					{tabKey === 'utilisateurs'
						? contents.titles.BackOfficeModal.user.modalTitle
						: tabKey === 'associations'
						? contents.titles.BackOfficeModal.association.modalTitle
						: contents.titles.BackOfficeModal.receivers.modalTitle}
				</Modal.Title >
			</Modal.Header >
			<Modal.Body >
				<Message
					status={'error'}
					message={errorMessage}
				/>
				{tabKey === 'utilisateurs' ? (
					<Form
						key={'bcb3ce41-dd62-4299-8e22-9314a0011e55'}
						onSubmit={handleSubmit}
					>
						<fieldset
							className={'new-user-infos'}
						>
							<Form.Group
								className="mb-3 form-lastName"
								controlId="last_name"
							>
								<Form.Label >{contents.labels.lastname}</Form.Label >
								<Form.Control
									type="text"
									placeholder={contents.placeholders.lastname}
									onChange={handleNewUserInputChange}
									value={newUser.last_name}
								/>
								<FaAsterisk />
							</Form.Group >

							<Form.Group
								className="mb-3 form-firstName"
								controlId="first_name"
							>
								<Form.Label >{contents.labels.firstname}</Form.Label >
								<Form.Control
									type="text"
									placeholder={contents.placeholders.firstname}
									onChange={handleNewUserInputChange}
									value={newUser.first_name}
								/>
								<FaAsterisk />
							</Form.Group >

							<Form.Group
								className="mb-3 form-eMail"
								controlId="email"
							>
								<Form.Label >{contents.labels.mail}</Form.Label >
								<Form.Control
									type="text"
									placeholder={contents.placeholders.mail}
									onChange={handleNewUserInputChange}
									value={newUser.email}
								/>
								<FaAsterisk />
							</Form.Group >

							<Form.Group
								className="mb-3 form-phone"
								controlId="phone"
							>
								<Form.Label >{contents.labels.phone}</Form.Label >
								<Form.Control
									type="tel"
									placeholder={contents.placeholders.phone}
									onChange={handleNewUserInputChange}
									value={newUser.phone}
								/>
								<FaAsterisk />
							</Form.Group >
						</fieldset >

						<fieldset
							className={'new-user-group'}
						>
							<Form.Group
								className="mb-3 form-role"
								controlId="roleId"
							>
								<Form.Label >{contents.labels.role}</Form.Label >
								<Form.Select
									type="text"
									placeholder={contents.placeholders.role}
									onChange={handleNewUserInputChange}
									value={newUser.roleId}
								>
									<option
										key={'05af2618-af21-4b7d-a2c8-0961b4df7add'}
									>
										{contents.placeholders.role}
									</option >
									{roleOptionHTML}
								</Form.Select >
							</Form.Group >

							{newUser.roleId !== 3 ? (
								<Form.Group
									className="mb-3 form-association"
									controlId="association_id"
								>
									<Form.Label >{contents.labels.association}</Form.Label >

									<Form.Select
										type="text"
										placeholder={contents.placeholders.association}
										onChange={handleNewUserInputChange}
										value={newUser.assosId}
									>
										<option
											key={'4926a6d9-0617-444b-89ae-9edb3691dcee'}
										>
											{contents.placeholders.association}
										</option >
										{assosOptionsHTML}
									</Form.Select >
								</Form.Group >
							) : (
								<div
									className="mb-3 form-association"
								/>
							)}
							{newUser.roleId === 2 ? (
								<Form.Group
									className="mb-3 form-reciever"
									controlId="reciever_id"
								>
									<Form.Label >{contents.labels.receiver}</Form.Label >

									<Form.Select
										type="text"
										placeholder={contents.placeholders.receiver}
										onChange={handleNewUserInputChange}
										value={newUser.assosId}
									>
										<option
											key={'4926a6d9-0617-444b-89ae-9edb3691dcee'}
										>
											{contents.placeholders.receiver}
										</option >
										{recieverOptionsHTML}
									</Form.Select >
								</Form.Group >
							) : (
								<div
									className="mb-3 form-association"
								/>
							)}
							<Form.Group
								className="mb-3 status"
								controlId="status"
							>
								<Form.Label >{contents.labels.status}</Form.Label >
								<Form.Select
									type="text"
									placeholder={contents.placeholders.status}
									onChange={handleNewUserInputChange}
									value={newUser.status}
								>
									{statusOptionsHTML}
								</Form.Select >
							</Form.Group >
						</fieldset >

						<fieldset
							className={'lined'}
						/>

						<fieldset
							className={'new-user-rights'}
						>
							<h3 >{contents.titles.BackOfficeModal.user.subventions}</h3 >
							<Form.Group
								className="mb-3 switchs subvention_create_right"
								controlId="subvention_create_right"
							>
								<Form.Label >
									{contents.labels.subvention_create_right}
								</Form.Label >
								<div >
									<Switch
										id={'subvention_create_right'}
										onChange={handleSwitchChange}
										checked={newUser.subvention_create_right}
									/>
								</div >
							</Form.Group >
							<Form.Group
								className="mb-3 switchs subvention_modify_right"
								controlId="subvention_modify_right"
							>
								<Form.Label >
									{contents.labels.subvention_modify_right}
								</Form.Label >
								<div >
									<Switch
										id={'subvention_modify_right'}
										onChange={handleSwitchChange}
										checked={newUser.subvention_modify_right}
									/>
								</div >
							</Form.Group >

							<Form.Group
								className="mb-3 switchs subvention_extract_right"
								controlId="subvention_extract_right"
							>
								<Form.Label >
									{contents.labels.subvention_extract_right}
								</Form.Label >
								<div >
									<Switch
										id={'subvention_extract_right'}
										onChange={handleSwitchChange}
										checked={newUser.subvention_extract_right}
									/>
								</div >
							</Form.Group >
							<Form.Group
								className="mb-3 switchs add_documents_right"
								controlId="add_documents_right"
							>
								<Form.Label >{contents.labels.add_documents_right}</Form.Label >
								<div >
									<Switch
										id={'add_documents_right'}
										onChange={handleSwitchChange}
										checked={newUser.add_documents_right}
									/>
								</div >
							</Form.Group >
							<Form.Group
								className="mb-3 switchs admin_right"
								controlId="admin_right"
							>
								<Form.Label >{contents.labels.admin_right}</Form.Label >
								<div >
									<Switch
										id={'admin_right'}
										onChange={handleSwitchChange}
										checked={newUser.admin_right}
									/>
								</div >
							</Form.Group >
						</fieldset >

						<fieldset
							className={'new-user-projects'}
						>
							<h3 >{contents.titles.BackOfficeModal.user.projects}</h3 >
							<Form.Group
								className="mb-3 switchs project_create_right"
								controlId="project_create_right"
							>
								<Form.Label >{contents.labels.project_create_right}</Form.Label >
								<div >
									<Switch
										id={'project_create_right'}
										onChange={handleSwitchChange}
										checked={newUser.project_create_right}
									/>
								</div >
							</Form.Group >
							<Form.Group
								className="mb-3 switchs project_modify_right"
								controlId="project_modify_right"
							>
								<Form.Label >{contents.labels.project_modify_right}</Form.Label >
								<div >
									<Switch
										id={'project_modify_right'}
										onChange={handleSwitchChange}
										checked={newUser.project_modify_right}
									/>
								</div >
							</Form.Group >
							<Form.Group
								className="mb-3 switchs project_extract_right"
								controlId="project_extract_right"
							>
								<Form.Label >{contents.labels.project_extract_right}</Form.Label >
								<div >
									<Switch
										id={'project_extract_right'}
										onChange={handleSwitchChange}
										checked={newUser.project_extract_right}
									/>
								</div >
							</Form.Group >
							<Form.Group
								className="mb-3 switchs all_document_read_right"
								controlId="all_document_read_right"
							>
								<Form.Label >
									{contents.labels.all_document_read_right}
								</Form.Label >
								<div >
									<Switch
										id={'all_document_read_right'}
										onChange={handleSwitchChange}
										checked={newUser.all_document_read_right}
									/>
								</div >
							</Form.Group >
						</fieldset >

						<fieldset
							className={'new-user-buttons'}
						>
							<Buttons
								submitTitle={contents.buttons.save}
								handleCancel={handleClickCancel}
							/>
						</fieldset >
					</Form >
				) : tabKey === 'associations' ? (
					<Form
						key={'a8fa9f7a-14a0-40fc-a657-e069e05a8f6d'}
						onSubmit={handleSubmit}
					>
						<fieldset
							className={'new-assos'}
						>
							<Form.Group
								className="mb-3 new-assos-name"
								controlId="name"
							>
								<Form.Label >{contents.labels.lastname}</Form.Label >
								<Form.Control
									type="text"
									placeholder={contents.placeholders.lastname}
									onChange={handleNewAssociationInputChange}
									value={newAssociation.name}
								/>
							</Form.Group >

							<Form.Group
								className="mb-3 new-assos-address"
								controlId="address"
							>
								<Form.Label >{contents.labels.address}</Form.Label >
								<Form.Control
									type="text"
									placeholder={contents.placeholders.address}
									onChange={handleNewAssociationInputChange}
									value={newAssociation.address}
								/>
							</Form.Group >

							<Form.Group
								className="mb-3 new-assos-zipcode"
								controlId="zipcode"
							>
								<Form.Label >{contents.labels.zipcode}</Form.Label >
								<Form.Control
									type="text"
									placeholder={contents.placeholders.zipcode}
									onChange={handleNewAssociationInputChange}
									value={newAssociation.zipcode}
								/>
							</Form.Group >

							<Form.Group
								className="mb-3 new-assos-city"
								controlId="city"
							>
								<Form.Label >{contents.labels.city}</Form.Label >
								<Form.Control
									type="text"
									placeholder={contents.placeholders.city}
									onChange={handleNewAssociationInputChange}
									value={newAssociation.city}
								/>
							</Form.Group >
						</fieldset >

						<fieldset
							className={'new-association-buttons'}
						>
							<Buttons
								submitTitle={contents.buttons.save}
								handleCancel={handleClickCancel}
							/>
						</fieldset >
					</Form >
				) : tabKey === 'beneficiaires' ? (
					<Form
						key={'66a9dc27-4709-48f8-bc34-466b5104bf0b'}
						onSubmit={handleSubmit}
						className={'reciever-form'}
					>
						<fieldset
							className={'new-reciever-infos'}
						>
							<Form.Group
								className="mb-3 form-lastName"
								controlId="name"
							>
								<Form.Label >{contents.labels.lastname}</Form.Label >
								<Form.Control
									type="text"
									placeholder={contents.placeholders.lastname}
									onChange={handleNewRecieverInputChange}
									value={newReciever.name}
								/>
								<FaAsterisk />
							</Form.Group >
						</fieldset >

						<fieldset
							className={'new-reciever-status'}
						>
							<Form.Group
								className="mb-3 form-association"
								controlId="association_id"
							>
								<Form.Label >{contents.labels.association}</Form.Label >

								<Form.Select
									type="text"
									placeholder={contents.placeholders.association}
									onChange={handleNewRecieverInputChange}
									value={newReciever.association_id}
								>
									<option
										key={'4926a6d9-0617-444b-89ae-9edb3691dcee'}
									>
										{contents.placeholders.association}
									</option >
									{assosOptionsHTML}
								</Form.Select >
								<FaAsterisk />
							</Form.Group >

							<Form.Group
								className="mb-3 status"
								controlId="status"
							>
								<Form.Label >{contents.labels.status}</Form.Label >
								<Form.Select
									type="text"
									placeholder={contents.placeholders.status}
									onChange={handleNewRecieverInputChange}
									value={newReciever.status}
								>
									{statusOptionsHTML}
								</Form.Select >
								<FaAsterisk />
							</Form.Group >
						</fieldset >

						<fieldset
							className={'new-receiver-buttons'}
						>
							<Buttons
								submitTitle={contents.buttons.save}
								handleCancel={handleClickCancel}
							/>
						</fieldset >
					</Form >
				) : (
					<></>
				)}
			</Modal.Body >
		</Modal >
	);
};

export default BackOfficeModal;
