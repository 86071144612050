/**
 * Import modules
 */

/**
 * Import locals
 */
import utility from "../utility";

export const getAllSubventionListByAssociation = async ({
	assosId,
	roleId,
}) => {
	let query = `associationID=${assosId}&roleID=${roleId}`;

	if (!roleId) {
		query = `associationID=${assosId}`;
	}

	try {
		const token = utility.getToken();

		const res = await utility.axiosReq(
			"GET",
			`${utility.apiURL}subvention/all?${query}`,
			token
		);
		const resultFormatedDate = res.data.data.map((sub) => {
			return {
				...sub,
				start_date: utility.dateFormat(sub.start_date),
				end_date: utility.dateFormat(sub.end_date),
			};
		});
		return { data: resultFormatedDate, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const getOneSubventionListById = async (subId) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			"GET",
			`${utility.apiURL}subvention/${subId}`,
			token
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const addSubvention = async (newSubvention) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			"POST",
			`${utility.apiURL}subvention/add`,
			token,
			newSubvention
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const editSubvention = async (editedSubvention) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			"PUT",
			`${utility.apiURL}subvention/edit`,
			token,
			editedSubvention
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const deleteSubvention = async (subventionId) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			"DELETE",
			`${utility.apiURL}subvention/delete/${subventionId}`,
			token
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const uploadFiles = async (formData) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			"POST",
			`${utility.apiURL}file/`,
			token,
			formData
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const deleteFiles = async (fileList, subId) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			"DELETE",
			`${utility.apiURL}file/`,
			token,
			{
				filesToDelete: fileList,
				subventionId: subId,
			}
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const downloadFiles = async (obj) => {
	const { sub, assos } = obj;

	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			"GET",
			`${utility.apiURL}file/${assos}/${sub}/files`,
			token,
			{ subventionsId: obj.subventionsId }
		);
		return { ...res, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};
