// Import modules
import React from 'react';

// Import components

// Import contexts

// Import Hooks

// Import locals

export const ContractTypeOption = ({ contractType }) => {
	return (
		<option key={contractType.id} value={contractType.id}>
			{contractType.name}
		</option>
	);
};
