import React, { createContext, useContext, useState } from 'react';

// context creation
export const formContext = createContext('');

// context hook
export const useManagerAdminContext = () => {
	const context = useContext(formContext);
	if (!context) {
		console.error(
			'ManagerAdminContext should not be undefined. Did you forget to wrap your component with ManagerAdminContextProvider ?'
		);
	}
	return context;
};

// provider creation
export const ManagerAdminContextProvider = ({ children }) => {
	const [adminKey, setAdminKey] = useState('productAccount');
	const [selectedItem, setSelectedItem] = useState({
		code: '',
		account_name: '',
	});

	const [showAddModal, setShowAddModal] = useState({
		productAccount: false,
		tiersAccount: false,
	});

	const [editMode, setEditMode] = useState(false);

	const [userKey, setUserKey] = useState('users');
	const [userList, setUserList] = useState([]);

	const switchShowModal = (key = adminKey) => {
		setShowAddModal({ ...showAddModal, [key]: !showAddModal[key] });
	};

	const switchEditMode = () => {
		setEditMode(!editMode);
	};

	let value = {
		adminKey,
		setAdminKey,
		selectedItem,
		setSelectedItem,
		showAddModal,
		editMode,
		switchEditMode,
		switchShowModal,
		userKey,
		setUserKey,
		userList,
		setUserList,
	};

	return <formContext.Provider value={value}>{children}</formContext.Provider>;
};
