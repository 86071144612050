/**
 * Import modules
 */
import React, { useEffect, useState } from 'react';
import { FaSyncAlt } from 'react-icons/fa';

/**
 * Import components
 */

/**
 * Import locals
 */
import './SearchBarBack.scss';
import contents from '../../contents/contents.json';

const SearchBarBack = ({ keyType, itemList, setter }) => {
	const [inputValue, setInputValue] = useState('');

	const handleInputChange = async (evt) => {
		await setInputValue(evt.target.value);
	};

	const handleResetFilter = async (evt) => {
		evt.preventDefault();
		setInputValue('');
	};

	/**
	 * Filter by string
	 * Trigger: inputValue
	 */
	useEffect(() => {
		(async () => {
			if (itemList?.length > 0) {
				let filteredItem = null;

				if (inputValue === '') {
					filteredItem = itemList;
				} else if (keyType === 'utilisateurs') {
					const mappedArray = itemList.map((item) => {
						item.assosName = '';
						item.recieverName = '';
						if (item.assos[0]) {
							item.assosName = item.assos[0].name;
						}
						if (item.recievers[0]) {
							item.assosName = '';
							item.recieverName = item.recievers[0].name;
						}
						item.roleName =
							item.role.title === 'beneficiary'
								? 'centre beneficiaire'
								: item.role.title === 'manager'
								? 'association'
								: 'administrateur';
						item.statusName =
							item.status === 'active'
								? contents.options.active
								: contents.options.inactive;
						item.modifyRight = item.subvention_modify_right ? 'oui' : 'non';
						item.extractRight = item.subvention_extract_right ? 'oui' : 'non';
						return item;
					});

					filteredItem = mappedArray.filter((item) => {
						return (
							item.first_name
								.toString()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
								.includes(inputValue) ||
							item.last_name
								.toString()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
								.includes(inputValue) ||
							item.email
								.toString()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
								.includes(inputValue) ||
							item.roleName
								.toString()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
								.includes(inputValue) ||
							item.statusName
								.toString()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
								.includes(inputValue) ||
							item.modifyRight
								.toString()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
								.includes(inputValue) ||
							item.extractRight
								.toString()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
								.includes(inputValue) ||
							item.assosName
								.toString()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
								.includes(inputValue) ||
							item.recieverName
								.toString()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
								.includes(inputValue)
						);
					});
				} else if (keyType === 'associations') {
					filteredItem = itemList.filter((item) => {
						return (
							item.name
								.toString()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
								.includes(inputValue) ^
							item.address
								.toString()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
								.includes(inputValue) ^
							item.zipcode
								.toString()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
								.includes(inputValue) ^
							item.city
								.toString()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
								.includes(inputValue)
						);
					});
				} else if (keyType === 'beneficiaires') {
					filteredItem = itemList.filter((item) => {
						return (
							item.name
								.toString()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
								.includes(inputValue) ^
							item.recieverManager.first_name
								.toString()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
								.includes(inputValue) ^
							item.recieverManager.last_name
								.toString()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
								.includes(inputValue) ^
							item.association_recievers.name
								.toString()
								.normalize('NFD')
								.replace(/[\u0300-\u036f]/g, '')
								.toLowerCase()
								.includes(inputValue)
						);
					});
				}

				await setter(filteredItem);
			}
		})();
	}, [inputValue]);

	/**
	 * Emptying input
	 * Trigger: keyType
	 */
	useEffect(() => {
		setInputValue('');
	}, [keyType]);

	return (
		<div
			className={'SearchBarBack'}
		>
			<input
				id={'word-search'}
				type="search"
				className="search-input"
				value={inputValue}
				onChange={handleInputChange}
				placeholder={contents.placeholders.searchBarInput}
			/>

			<FaSyncAlt
				onClick={handleResetFilter}
				size={25}
				color={'#47C8F4'}
				data-tip={contents.toolTipDatas.SearchBarReset}
			/>
		</div >
	);
};

export default SearchBarBack;
