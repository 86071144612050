/**
 * Import modules
 */
import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FaAsterisk } from 'react-icons/fa';
import { NumericFormat } from 'react-number-format';

/**
 * Import components
 */
import Buttons from '../../Buttons/Buttons';
import Message from '../../Message/Message';

/**
 * Import Contexts
 */
import { useSubventionContext } from '../../../contexts/subventionContext';

/**
 * Import locals
 */
import './AddPaymentModal.scss';
import utility from '../../../utility';
import contents from '../../../contents/contents.json';

const AddPaymentModal = ({ showModal, closeModal }) => {
	const {
		editedSubvention,
		setEditedSubvention,
		newPayment,
		setNewPayment,
		cancelNewPayment,
		editedSection,
		setEditedSection,
		message,
		actions: { setMessage },
	} = useSubventionContext();

	const handleAddPaymentSubmit = async (evt) => {
		evt.preventDefault();

		const newVersementList = editedSubvention.payments
			? [...editedSubvention.payments]
			: [];
		newVersementList.push(newPayment);

		const ttlRecieved = utility.recalcTotalRecieved(newVersementList);

		let remains =
			parseFloat(editedSubvention.amount_contracted) - parseFloat(ttlRecieved);
		const give_back = remains < 0 ? remains * -1 : 0;
		remains = remains < 0 ? 0 : remains;

		await setEditedSubvention({
			...editedSubvention,
			total_recieved: ttlRecieved,
			payments: newVersementList,
			remains,
			give_back,
		});
		await setEditedSection({
			...editedSection,
			subFinancialInfos: true,
		});
		cancelNewPayment();
	};

	const handleFormInputChange = async (evt) => {
		evt.preventDefault();
		let { id, value } = evt.target;

		value = id === 'date' ? value : parseFloat(value);

		setMessage('');

		setNewPayment({
			...newPayment,
			date: value,
		});
	};

	const handleFormInputNumberChange = async (values) => {
		const { floatValue } = values;

		setMessage('');

		setNewPayment({
			...newPayment,
			amount: floatValue,
		});
	};

	return (
		<Modal
			key={'53efe02b-74aa-4b84-ba23-cde915a1c497'}
			show={showModal}
			onHide={closeModal}
			//size={'xl'}
			aria-labelledby={'AddPaymentModal'}
			centered
			restoreFocus
			dialogClassName={'AddPaymentModal'}
		>
			<Modal.Header closeButton>
				<Modal.Title>{contents.titles.AddPaymentModal}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleAddPaymentSubmit}>
					<Message
						status={'error'}
						message={
							editedSubvention.amount_contracted === undefined ||
							editedSubvention.amount_contracted === null
								? contents.messages.noAmountContracted
								: ''
						}
					/>
					<Form.Group
						className={'form-group form-start-date'}
						controlId={'subventionStartDate'}
					>
						<Form.Label>{contents.labels.date}</Form.Label>

						<input
							id={'date'}
							type="date"
							onChange={handleFormInputChange}
							value={newPayment.endDate}
							required
						/>
						<FaAsterisk size={'7px'} color={'red'} />
					</Form.Group>

					<Form.Group className={'form-group form-amount'} controlId={'amount'}>
						<Form.Label>{contents.labels.amount}</Form.Label>

						<NumericFormat
							key={'3317b820-35c1-4bad-9f39-7a6270fdb0f3'}
							value={newPayment.amount}
							id={'amount'}
							displayType={'input'}
							thousandSeparator={' '}
							suffix={' €'}
							className="totaux-read"
							allowNegative={false}
							decimalScale={2}
							decimalSeparator={','}
							allowedDecimalSeparators={['.', ',']}
							onValueChange={handleFormInputNumberChange}
						/>
						<FaAsterisk size={'7px'} color={'red'} />
					</Form.Group>

					<div
						key={'2c3e3be6-44d0-44f6-bafa-f7721e75db32'}
						className="advertising"
					>
						{contents.messages.advertIrreversible}
					</div>

					<Message status={'error'} message={message} />

					<Buttons
						key={'ccba4ded-2108-4df1-a637-4b9478ed02af'}
						handleCancel={cancelNewPayment}
						customCSS={'editButtons'}
					/>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default AddPaymentModal;
