/**
 * Import modules
 */
import React from 'react';
import { PDFReader } from 'reactjs-pdf-reader';
import { Modal } from 'react-bootstrap';

/**
 * Import components
 */

/**
 * Import Contexts
 */
import { useSubventionContext } from '../../../contexts/subventionContext';

/**
 * Import locals
 */
import './DocumentViewerModal.scss';
import contents from '../../../contents/contents.json';

const DocumentViewerModal = () => {
	const { showFile, setShowFile } = useSubventionContext();

	const handleCloseModal = () => {
		setShowFile({
			...showFile,
			show: false,
			url: '',
			name: '',
			builtUrl: '',
			loading: false,
			type: '',
		});
	};

	return (
		<Modal
			key={'b847f4a2-f41d-4238-b4a1-82d0a7c17c58'}
			show={showFile.show}
			onHide={handleCloseModal}
			aria-labelledby={'modals-content-closeSub'}
			centered
			restoreFocus
			dialogClassName={'DocumentViewer'}
		>
			<Modal.Header closeButton>
				<Modal.Title>{`${contents.titles.DocumentViewerModal} ${showFile.name}`}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{showFile.loading ? (
					<div>Loading</div>
				) : showFile.type === 'pdf' ? (
					<PDFReader
						url={showFile.builtUrl}
						showAllPage={true}
						scale={1.6}
						key={'74319ed3-455e-4c6b-ab94-9041b5c7b934'}
					/>
				) : (
					<img
						key={'96e91261-06c6-49f6-838f-88396cea57ea'}
						className={'image-viewer'}
						src={showFile.builtUrl}
						alt={showFile.name}
					/>
				)}
			</Modal.Body>
		</Modal>
	);
};

export default DocumentViewerModal;
