/**
 * Import modules
 */
import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

/**
 * Import components
 */
import ProductAccountPage from './ProductAccountPage/ProductAccountPage';
import TiersAccountPage from './TiersAccountPage/TiersAccountPage';
import ImportPage from './Imports/ImportPage';
import { WebsitePage } from './WebsitePage/WebsitePage';

/**
 * Import Contexts
 */
import { ImportContextProvider } from '../../../../contexts/importContext';
import { useManagerAdminContext } from '../../../../contexts/ManagerAdminContext';
import { BackOfficeContextProvider } from '../../../../contexts/backOfficeContext';
import { WebSiteContextProvider } from '../../../../contexts/webSiteContext';

/**
 * Import locals
 */
import './AdminTab.scss';
import contents from '../../../../contents/contents.json';
import UsersTab from './Users/UsersTab';
import { FilesTab } from './Files/FilesTab';

const AdminTab = () => {
	const { adminKey, setAdminKey } = useManagerAdminContext();

	let history = useHistory();

	const tabsOnSelect = async (k) => {
		await setAdminKey(k);

		history.push(`/dashboard/admin/${k}`);
	};
	return (
		<div className={'AdminTab'}>
			<Tabs
				key={'b6e4a891-5cc1-41e0-87c6-97540d01ef0f'}
				defaultActiveKey="productAccount"
				id="Admin"
				activeKey={adminKey}
				onSelect={tabsOnSelect}
			>
				<Tab
					key={'7dfb2cc4-66d4-4d4a-b6bf-eed5e0aa30bd'}
					eventKey="productAccount"
					title={contents.tabs.productAccount}
				>
					<ProductAccountPage />
				</Tab>
				<Tab
					key={'18d66700-0ac2-4057-ae33-c7ab39bd6aa5'}
					eventKey="tiersAccount"
					title={contents.tabs.tiersAccount}
				>
					<TiersAccountPage />
				</Tab>
				<Tab
					key={'edf77375-3690-4ff4-8d92-de19ddc4b993'}
					eventKey="website"
					title={contents.tabs.website}
				>
					<WebSiteContextProvider>
						<WebsitePage />
					</WebSiteContextProvider>
				</Tab>
				<Tab
					key={'6442f6d3-73b3-46a9-9684-5bcde964feef'}
					eventKey="imports"
					title={contents.tabs.imports}
				>
					<BackOfficeContextProvider>
						<ImportContextProvider>
							<ImportPage />
						</ImportContextProvider>
					</BackOfficeContextProvider>
				</Tab>
				<Tab
					key={'c32d1c1f-0f79-41be-b190-09f59470c612'}
					eventKey="users"
					title={contents.tabs.users}
				>
					<UsersTab />
				</Tab>
				<Tab
					key={'36f1c109-f295-419f-860f-4f28200d1f19'}
					eventKey="files"
					title={contents.tabs.files}
				>
					<FilesTab />
				</Tab>
			</Tabs>
		</div>
	);
};

export default AdminTab;
