// Import modules
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

// Import components
import Buttons from '../../Buttons/Buttons';
import Message from '../../Message/Message';

// Import contexts
import { useLoadingContext } from '../../../contexts/loadingContext';
import { useProjectContext } from '../../../contexts/projectContext';
import { useDashboardContext } from '../../../contexts/DashboarContext';

// Import Hooks

// Import locals
import './DuplicateProjectModal.scss';
import contents from '../../../contents/contents.json';
import { addProject } from '../../../requests/projectsRequests';

export const DuplicateProjectModal = () => {
	let history = useHistory();

	const {
		actions: { setter },
	} = useLoadingContext();
	const {
		duplicateProject,
		isNewDuplicateValid,
		editCancel,
		actions: { setDuplicateProject },
	} = useProjectContext();

	const {
		actions: { switchShowDuplicateModal },
	} = useDashboardContext();

	const [message, setMessage] = useState('');

	const handleInputChange = (evt) => {
		setDuplicateProject({
			...duplicateProject,
			[evt.target.id]: evt.target.value,
		});
	};

	const handleManagerChange = (evt) => {
		setDuplicateProject({
			...duplicateProject,
			manager: {
				...duplicateProject.manager,
				[evt.target.id]: evt.target.value,
			},
		});
	};

	const cancelEdit = () => {
		editCancel();
		switchShowDuplicateModal();
		setMessage('');
	};

	const handleSubmit = async (evt) => {
		evt.preventDefault();

		const duplicatedProject = { ...duplicateProject };

		duplicatedProject.start_date = new Date(duplicatedProject.start_date);
		duplicatedProject.end_date = new Date(duplicatedProject.end_date);
		duplicatedProject.unique_key = `${duplicatedProject.associationId}-${duplicatedProject.analytic_code}`;

		delete duplicatedProject.id;

		setter(true, true);
		const result = await addProject(duplicatedProject);

		switch (result.status) {
			case 200:
				switchShowDuplicateModal();

				history.push(
					`/dashboard/projects/${result.data.name}?id=${result.data.id}`
				);
				break;

			case 500:
				switch (result.message) {
					case 'RangeError: Invalid time value':
						setMessage(contents.messages.invalidTime);
						break;

					default:
						setMessage(result.message);
						break;
				}

				break;

			default:
				setMessage(result.message);
				break;
		}
		setter(false, true);
	};
	return (
		<div className={'DuplicateProjectModal'}>
			<Form
				key={'e9187c2f-f2b0-4986-9193-11c56b060cbf'}
				onSubmit={handleSubmit}
				noValidate
			>
				<Form.Group className={'form-group form-name'} controlId={'name'}>
					<Form.Label>{'Name :'}</Form.Label>
					<Form.Control
						type={'text'}
						value={duplicateProject?.name}
						onChange={handleInputChange}
					/>
				</Form.Group>
				<Form.Group
					className={'form-group form-analytic_code'}
					controlId={'analytic_code'}
				>
					<Form.Label>{'Analytic code :'}</Form.Label>
					<Form.Control
						type={'text'}
						value={duplicateProject?.analytic_code}
						onChange={handleInputChange}
					/>
				</Form.Group>
				<Form.Group className={'form-group form-etat'} controlId={'etat'}>
					<Form.Label>{'Etat :'}</Form.Label>
					<Form.Select
						value={duplicateProject?.etat}
						onChange={handleInputChange}
					>
						<option value={'Non commencé'}>{'Non commencé'}</option>
						<option value={'En cours'}>{'En cours'}</option>
						<option value={'Cloturé'}>{'Cloturé'}</option>
					</Form.Select>
				</Form.Group>

				<div className={'manager form-group form-manager'}>
					<label className={'form-manager-label'} htmlFor="">
						Manager
					</label>
					<Form.Group
						className={'form-manager-last_name'}
						controlId={'last_name'}
					>
						<Form.Label>{'Nom :'}</Form.Label>
						<Form.Control
							type={'text'}
							value={duplicateProject?.manager.last_name}
							onChange={handleManagerChange}
						/>
					</Form.Group>
					<Form.Group
						className={'form-manager-first_name'}
						controlId={'first_name'}
					>
						<Form.Label>{'Prénom :'}</Form.Label>
						<Form.Control
							type={'text'}
							value={duplicateProject?.manager.first_name}
							onChange={handleManagerChange}
						/>
					</Form.Group>
					<Form.Group className={'form-manager-email'} controlId={'email'}>
						<Form.Label>{'Email :'}</Form.Label>
						<Form.Control
							type={'email'}
							value={duplicateProject?.manager.email}
							onChange={handleManagerChange}
						/>
					</Form.Group>
					<Form.Group className={'form-manager-phone'} controlId={'phone'}>
						<Form.Label>{'Phone :'}</Form.Label>
						<Form.Control
							type={'text'}
							value={duplicateProject?.manager.phone}
							onChange={handleManagerChange}
						/>
					</Form.Group>
				</div>

				<Form.Group
					className={'form-group form-start_date'}
					controlId={'start_date'}
				>
					<Form.Label>{'Start date :'}</Form.Label>
					<Form.Control
						type={'date'}
						value={duplicateProject?.start_date}
						onChange={handleInputChange}
					/>
				</Form.Group>
				<Form.Group
					className={'form-group form-end_date'}
					controlId={'end_date'}
				>
					<Form.Label>{'End date :'}</Form.Label>
					<Form.Control
						type={'date'}
						value={duplicateProject?.end_date}
						onChange={handleInputChange}
					/>
				</Form.Group>
				<Form.Group
					className={'form-group form-description'}
					controlId={'description'}
				>
					<Form.Label>{'Description :'}</Form.Label>
					<Form.Control
						as={'textarea'}
						rows={3}
						value={duplicateProject?.description}
						onChange={handleInputChange}
					/>
				</Form.Group>

				<Message status={'error'} message={message} />

				<div className="buttons">
					<Buttons
						submitTitle={contents.buttons.duplicate}
						handleCancel={cancelEdit}
						customCSS={'submit'}
						isDisabled={!isNewDuplicateValid}
					/>
				</div>
			</Form>
		</div>
	);
};

// analytic_code: '',
// 	beneficiaries: [],
// 	documents: [],
// 	description: '',
// 	start_date: '',
// 	end_date: '',
// 	etat: 'En cours',
// 	name: '',
// 	manager: '',
// 	self_financings: [],
// 	subventions: [],
// 	unique_key: '',
