/**
 * Import modules
 */

/**
 * Import locals
 */
import utility from '../utility';

export const getAllProjectsListByAssociation = async ({ assosId, roleId }) => {
	let query = `associationID=${assosId}&roleID=${roleId}`;

	if (!roleId) {
		query = `associationID=${assosId}`;
	}

	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'GET',
			`${utility.apiURL}project/all?${query}`,
			token
		);
		const resultFormatedDate = res.data.data.map((sub) => {
			return {
				...sub,
				start_date: utility.dateFormat(sub.start_date),
				end_date: utility.dateFormat(sub.end_date),
			};
		});
		return { data: resultFormatedDate, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const getOneProjectListById = async (projId) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'GET',
			`${utility.apiURL}project/${projId}`,
			token
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const getEmployeeByProjectId = async (projId) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'GET',
			`${utility.apiURL}project/getEmployeesByProject/${projId}`,
			token
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const addProject = async (newProject) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'POST',
			`${utility.apiURL}project/add`,
			token,
			newProject
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const editProject = async (editedProject) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'PUT',
			`${utility.apiURL}project/edit`,
			token,
			editedProject
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const deleteProject = async (subventionId) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'DELETE',
			`${utility.apiURL}project/delete/${subventionId}`,
			token
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const addEmployeeToProject = async (relation) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'POST',
			`${utility.apiURL}project/employee`,
			token,
			relation
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		if (error.message === 'Network Error') {
			return { ...error };
		}
		return { ...error.response.data, status: error.response.status };
	}
};

export const removeEmployeeFromProject = async (projectId, employeeIde) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'DELETE',
			`${utility.apiURL}project/employee/${projectId}/${employeeIde}`,
			token
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		if (error.message === 'Network Error') {
			return { ...error };
		}
		return { ...error.response.data, status: error.response.status };
	}
};

export const updateEmployeeOnProject = async (m2mId, duration, projectId) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'PATCH',
			`${utility.apiURL}project/user/edit`,
			token,
			{ id: m2mId, duration, projectId }
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		if (error.message === 'Network Error') {
			return { ...error };
		}
		return { ...error.response.data, status: error.response.status };
	}
};
