/**
 * Import modules
 */
import { Tabs, Tab } from 'react-bootstrap';
import { ImPlus } from 'react-icons/im';
import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { Tooltip as ReactTooltip } from 'react-tooltip';

/**
 * Import components
 */
import BackOfficeModal from '../BackOfficeModal/BackOfficeModal';
import SearchBarBack from '../SearchBarBack/SearchBarBack';
import UsersList from '../UsersList/UsersList';
import AssociationList from '../AssociationList/AssosciationList';
import RecieverList from '../RecieverList/RecieverList';
import ImportPage from '../ImportTab/ImportPage';

/**
 * Import Contexts
 */
import { useLoadingContext } from '../../contexts/loadingContext';
import { useKeyContext } from '../../contexts/keyContext';
import { useUserContext } from '../../contexts/userContext';
import { useBackOfficeContext } from '../../contexts/backOfficeContext';
import { ImportContextProvider } from '../../contexts/importContext';

/**
 * Import locals
 */
import './BackOffice.scss';
import { useHistory } from 'react-router-dom';
import {
	adminInit,
	getAllUsers,
	getAllAssociations,
	getAllRecievers,
} from '../../requests/AdminRequest';
import utility from '../../utility';
import contents from '../../contents/contents.json';

const BackOffice = () => {
	const { loading, actions } = useLoadingContext();
	const { key, keySetter } = useKeyContext();
	const { user, users, setUsers, init, adminInitialisation, initAdminSetter } =
		useUserContext();

	const {
		edited,
		recievers,
		filteredRecievers,
		filteredUserList,
		showAddModal,
		associations,
		filteredAssociationList,
		actions: {
			setEdited,
			setRecievers,
			setFilteredRecievers,
			setFilteredUserList,
			setShowAddModal,
			setAssociations,
			setFilteredAssociationList,
		},
	} = useBackOfficeContext();

	const history = useHistory();

	const tabsOnSelect = async (k) => {
		await keySetter(k);
		history.push(`/backoffice/${k}`);
	};

	const handleShowAddModal = async (evt) => {
		evt.preventDefault();
		setShowAddModal(true);
	};

	const handleCloseAddModal = () => {
		setShowAddModal(false);
	};

	const handleFilterChange = (array) => {
		switch (key) {
			case 'utilisateurs':
				setFilteredUserList(array);
				break;
			case 'associations':
				setFilteredAssociationList(array);
				break;
			case 'beneficiaires':
				setFilteredRecievers(array);
				break;

			default:
				break;
		}
	};

	/**
	 * Get admin data init
	 * Trigger: init
	 */
	useEffect(() => {
		(async () => {
			if (user.roleId === 3) {
				actions.setter(true);
				const data = await adminInit();

				const newList = [];

				if (data.data.roleList) {
					for (const item of data.data.roleList) {
						const newItem = { ...item };
						switch (item.title) {
							case 'collaborer':
								newItem.title2 = 'Collaborateur';
								break;
							case 'manager':
								newItem.title2 = 'Manager';
								break;
							case 'admin':
								newItem.title2 = 'Administrateur';
								break;
							case 'superAdmin':
								newItem.title2 = 'Super Administrateur';
								break;
						}
						newList.push(newItem);
					}

					data.data.roleList = newList;
				}

				await initAdminSetter(data.data);
				await setRecievers(data.data.recieverList);
				await setAssociations(data.data.associationList);
				actions.setter(false);

			}
		})();
	}, [init, users]);

	/**
	 * Change background color
	 * Trigger: user
	 */
	useEffect(() => {
		const root = document.getElementById('root');
		root.style.backgroundColor = '#78777D';

		const svgUser = document.getElementById('iconUser');

		if (svgUser) {
			svgUser.style.color = '#C3FFFB';
		} else {
			root.style.backgroundColor = '#D0D0D7';
		}

		const userFirstName = document.getElementById('fName');
		if (userFirstName) {
			userFirstName.style.color = '#C3FFFB';
		}

		const userLastName = document.getElementById('lName');
		if (userLastName) {
			userLastName.style.color = '#C3FFFB';
		}
	}, [user]);

	/**
	 * Set filter list
	 * Trigger: users, associations, recievers
	 */
	useEffect(() => {
		setFilteredUserList(users);
		setFilteredAssociationList(associations);
		setFilteredRecievers(recievers);
	}, [users, associations, recievers]);

	/**
	 * Get data list by tab's key
	 * Trigger: key, showAddModal, edited
	 */
	useEffect(() => {
		(async () => {
			actions.setter(true);
			let result;
			switch (key) {
				case 'utilisateurs':
					result = await getAllUsers();
					if (result.status === 404) {
						history.push('/404');
					}
					if (result.status === 403) {
						history.push('/login');
					} else {
						await setUsers(result.data.sort(utility.compare));
					}
					break;
				case 'associations':
					result = await getAllAssociations();
					if (result.status === 404) {
						history.push('/404');
					}
					if (result.status === 403) {
						history.push('/login');
					} else {
						await setAssociations(result.data.sort(utility.compare));
					}
					break;

				case 'beneficiaires':
					result = await getAllRecievers();
					if (result.status === 404) {
						history.push('/404');
					}
					if (result.status === 403) {
						history.push('/login');
					}
					await setRecievers(result.data.sort(utility.compare));
					break;
			}
			setEdited(false);
			actions.setter(false);
		})();
	}, [key, showAddModal, edited]);

	return (
		<div
			className={'BackOffice'}
		>
			<div
				className="searchBar"
			>
				<div
					className="loading"
				>
					{loading && (
						<span
							className="loading"
						>
							<Spinner
								as="span"
								animation="border"
								role="status"
								aria-hidden="true"
							/>
							<span
								className="visually-hidden"
							>Loading...</span >
						</span >
					)}
				</div >
				<SearchBarBack
					keyType={key}
					itemList={
						key === 'utilisateurs'
							? users
							: key === 'associations'
								? associations
								: key === 'beneficiaires'
									? recievers
									: null
					}
					setter={handleFilterChange}
				/>
			</div >
			<div
				className={'buttons'}
			>
				{key !== 'imports' && (
					<AddButton
						tabKey={key}
						onClickHandler={handleShowAddModal}
					/>
				)}
			</div >
			<Tabs
				key={'7607992a-012d-43d0-9690-aea63c139a98'}
				defaultActiveKey="utilisateurs"
				id="utilisateurs"
				activeKey={key}
				onSelect={tabsOnSelect}
			>
				<Tab
					key={'5b3d10b9-fcf4-43fc-a893-a9a1efb128bb'}
					eventKey="utilisateurs"
					title={contents.tabs.users}
				>
					<UsersList
						users={filteredUserList}
						setEdited={setEdited}
					/>
				</Tab >

				<Tab
					key={'e69bbf80-c1fa-4f87-ace6-abc9c7be9883'}
					eventKey="associations"
					title={contents.tabs.associations}
				>
					<AssociationList
						associations={filteredAssociationList}
						setEdited={setEdited}
					/>
				</Tab >

				<Tab
					key={'ac3b358e-5598-4d82-bdab-3d7f7b5cc1e4'}
					eventKey="beneficiaires"
					title={contents.tabs.beneficiaires}
				>
					<RecieverList
						recievers={filteredRecievers}
						users={users}
						setEdited={setEdited}
					/>
				</Tab >

				<Tab
					key={'c971824c-b0c4-42f0-b288-920059fc8972'}
					eventKey="imports"
					title={contents.tabs.import}
				>
					<ImportContextProvider >
						<ImportPage />
					</ImportContextProvider >
				</Tab >
			</Tabs >

			<BackOfficeModal
				show={showAddModal}
				onHide={handleCloseAddModal}
				tabKey={key}
				init={init}
				adminInitialisation={adminInitialisation}
				setShowAddModal={setShowAddModal}
				users={users}
				associations={associations}
			/>
		</div >
	);
};

export default BackOffice;

const AddButton = ({ tabKey, onClickHandler }) => {
	return (
		<>
			<div
				data-tip={contents.toolTipDatas.BackOffice[tabKey]}
			>
				<ImPlus
					key={'b7641e6c-5daf-4ddb-90ce-f2078c82481d'}
					onClick={onClickHandler}
					size={25}
					color={'#47C8F4'}
				/>
			</div >
			<ReactTooltip
				place={'top'}
				effect={'solid'}
				className={'tooltipCustom dashboardTooltip test'}
				multiline={true}
			/>
		</>
	);
};
