/**
 * Import modules
 */
import React, { useState } from 'react';

/**
 * Import components
 */
import ButtonLoading from '../ButtonLoading/ButtonLoading';

/**
 * Import contexts
 */
import { useLoadingContext } from '../../contexts/loadingContext';
import { useKeyContext } from '../../contexts/keyContext';

/**
 * Import locals
 */
import './Contact.scss';
import { sendMail } from '../../requests/contactRequest';
import contents from '../../contents/contents.json';

const Contact = () => {
	const { handleClickHome } = useKeyContext();
	const {
		actions: { setter },
	} = useLoadingContext();

	const [inputFields, setInputFields] = useState(() => {
		let initialState = {};
		contents.Contact.form_inputs.forEach((input) => {
			Object.assign(initialState, { [input.name]: '' });
		});
		return initialState;
	});

	const [errors, setErrors] = useState([]);
	const [success, setSuccess] = useState(false);
	/*
	const addErrors = (error) => {
		const newErrors = errors.push(error);
		setErrors(newErrors);
	};
*/
	const onChangeHandler = (event) => {
		const newValues = {
			...inputFields,
			[event.target.name]: event.target.value,
		};
		setInputFields(newValues);
	};

	const submitHandler = async (evt) => {
		evt.preventDefault();
		await setErrors([]);
		await setSuccess(false);

		await setter(true, true);

		const result = await sendMail(inputFields);

		if (result.status !== 200) {
			setErrors([
				'Une erreur est survenue, vous trouverez nos coordonnées plus bas.',
			]);
			setSuccess(false);
		} else {
			setSuccess(true);
			let initialState = {};
			contents.Contact.form_inputs.forEach((input) => {
				Object.assign(initialState, { [input.name]: '' });
			});
			setInputFields(initialState);
		}

		// api call goes here + error management
		// eg: addErrors("Erreur serveur")
		// setSuccess(true) to display success message

		await setter(false, true);
	};

	return (
		<div
			className={'Contact'}
		>
			<div
				className={'logo'}
			>
				<img
					src="/LogoSofiaFonce-sm.webp"
					alt="Sofia's logo"
					onClick={handleClickHome}
				/>
			</div >

			<div
				className="contact__content"
			>
				<h1
					className="contact__title"
				>{contents.titles.Contact.title}</h1 >
				<form
					className="contact__content__form"
					onSubmit={submitHandler}
				>
					{errors && (
						<ul
							className="contact__content__form__errors"
						>
							{errors.map((error, i) => (
								<li
									key={i}
								>{error}</li >
							))}
						</ul >
					)}
					{success && (
						<p
							className="contact__content__form__success"
						>
							{contents.messages.messageSent}
						</p >
					)}
					<h2
						className="contact__content__form__title"
					>
						{contents.titles.Contact.formTitle}
					</h2 >
					<div
						className="contact__content__form__body"
					>
						<div
							className="contact__content__form__inputs"
						>
							{contents.Contact.form_inputs.map((input) => {
								let pattern = {};
								if (input.pattern)
									pattern = {
										regex: input.pattern.regex,
										title: input.pattern.title,
									};
								if (input.textarea) {
									return (
										<>
											<label
												className="contact__content__form__label"
											>
												{input.label}
											</label >
											<textarea
												onChange={(event) => onChangeHandler(event)}
												className="contact__content__form__textarea"
												name={input.name}
												value={inputFields[input.name]}
												maxLength={input.max_length}
												required={input.required}
												rows="5"
											/>
										</>
									);
								}
								return (
									<>
										<label
											className="contact__content__form__label"
										>
											{input.label}
										</label >
										<input
											onChange={(event) => onChangeHandler(event)}
											className="contact__content__form__input"
											name={input.name}
											value={inputFields[input.name]}
											pattern={pattern.regex}
											title={pattern?.title}
											required={input.required}
										/>
									</>
								);
							})}
						</div >
						<ButtonLoading
							title={contents.buttons.send}
							classes={'contact__content__form__button'}
							loadingText={'Envois en cours ...'}
						/>
					</div >
				</form >
				<div
					className="contact__content__coordinates"
				>
					<h2
						className="contact__content__coordinates__title"
					>
						{contents.titles.Contact.contentTitle}
					</h2 >
					{contents.Contact.coordinates.map((coordinate, i) => {
						return (
							<div
								key={i}
								className="contact__content__coordinates__item"
							>
								<p
									className="contact__content__coordinates__item__title"
								>
									{coordinate.title}
								</p >
								<ul >
									{coordinate.content.map((item, index) => {
										return <li
											key={index}
										       >{item}</li >;
									})}
								</ul >
							</div >
						);
					})}
				</div >
			</div >
			<div
				className="footer"
			>
				By
				<a
					href="https://www.so-app.fr/"
					target="_blank"
					rel="noreferrer"
				>
					{contents.links.soAppLink}
				</a >
			</div >
		</div >
	);
};

export default Contact;
