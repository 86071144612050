/**
 * Import modules
 */
import React from 'react';
import { Spinner } from 'react-bootstrap';

/**
 * Import components
 */

/**
 * Import Contexts
 */
import { useLoadingContext } from '../../contexts/loadingContext';

/**
 * Import locals
 */
import './ButtonLoading.scss';
import contents from '../../contents/contents.json';

const ButtonLoading = ({
	title,
	type = 'submit',
	onClick = () => {},
	classes,
	disabling,
	loadingText = contents.messages.loadingText,
}) => {
	const { buttonLoading } = useLoadingContext();

	return (
		<button
			className={`ButtonLoading submit-input ${classes}`}
			type={type}
			onClick={onClick}
			disabled={buttonLoading || disabling}
		>
			{buttonLoading ? (
				<>
					<Spinner
						as="span"
						animation="border"
						size="sm"
						role="status"
						aria-hidden="true"
					/>
					<span >{` ${loadingText}`}</span >
				</>
			) : (
				title
			)}
		</button >
	);
};

export default ButtonLoading;
