/**
 * Import modules
 */
import React from 'react';

/**
 * Import components
 */
import Buttons from '../Buttons/Buttons';

/**
 * Import contexts
 */
import { useUserContext } from '../../contexts/userContext';
import { useProjectContext } from '../../contexts/projectContext';

/**
 * Import locals
 */
import './ProjectGeneralInfos.scss';
import contents from '../../contents/contents.json';

const ProjectGeneralInfos = ({ handleEditSubmit }) => {
	const { user } = useUserContext();
	const {
		editedSection,
		setEditedSection,
		selectedProject,
		editedProject,
		setEditedProject,
		editCancel,
	} = useProjectContext();

	const handleInputChange = (evt) => {
		const { id, value } = evt.target;
		setEditedProject({ ...editedProject, [id]: value });
		setEditedSection({ ...editedSection, projectGeneralInfos: true });
	};

	const handleManagerInputChange = (evt) => {
		const editProject = { ...editedProject };
		editProject.manager[evt.target.name] = evt.target.value;

		setEditedProject(editProject);
		setEditedSection({ ...editedSection, projectGeneralInfos: true });
	};

	let etatOptionsHTML = <></>;

	if (editedProject.id) {
		etatOptionsHTML = contents.etatOptions.map((item, i) => (
			<option
				key={`${i}-e3a2c75c-610b-41e7-b815-0621e4e1a90b`}
				value={item}
			>
				{item}
			</option >
		));
	}

	return (
		<div
			className={'ProjectGeneralInfos'}
		>
			<section
				key={'f5987ab4-c1a3-45c8-9578-20eec1640259'}
				className={'project-general-infos'}
			>
				<div
					className="project-elem project-code"
				>
					<label
						htmlFor="analytic-code"
					>
						{contents.labels.projectAnalyticCode}
					</label >
					{!user.project_modify_right ? (
						<span
							id={'analytic-code'}
						>{selectedProject.analytic_code}</span >
					) : (
						<input
							type="text"
							name="analytic-code"
							id={'analytic_code'}
							value={editedProject.analytic_code}
							onChange={handleInputChange}
						/>
					)}
				</div >
				<div
					className="project-elem project-name"
				>
					<label
						htmlFor="name"
					>{contents.labels.projectName}</label >
					{!user.project_modify_right ? (
						<span
							id={'name'}
						>{selectedProject.name}</span >
					) : (
						<input
							type="text"
							name="name"
							id={'name'}
							value={editedProject.name}
							onChange={handleInputChange}
						/>
					)}
				</div >
				<div
					className="project-elem project-etat"
				>
					<label
						htmlFor="name"
					>{contents.labels.projectEtat}</label >
					{!user.project_modify_right ? (
						<span
							id={'name'}
						>{selectedProject.etat}</span >
					) : (
						<select
							name="etat"
							id={'etat'}
							value={editedProject.etat}
							onChange={handleInputChange}
						>
							{etatOptionsHTML}
						</select >
					)}
				</div >
				<div
					className="project-elem project-start-date"
				>
					<label
						htmlFor="startDate"
					>{contents.labels.from}</label >
					{!user.project_modify_right ? (
						<span
							id={'startDate'}
						>{selectedProject.start_date}</span >
					) : (
						<input
							id={'start_date'}
							type="date"
							onChange={handleInputChange}
							value={editedProject.start_date}
							required
						/>
					)}
				</div >
				<div
					className="project-elem project-end-date"
				>
					<label
						htmlFor="startDate"
					>{contents.labels.to}</label >
					{!user.project_modify_right ? (
						<span
							id={'startDate'}
						>{selectedProject.start_date}</span >
					) : (
						<input
							id={'end_date'}
							type="date"
							onChange={handleInputChange}
							value={editedProject.end_date}
							required
						/>
					)}
				</div >
				<div
					className="project-elem project-manager"
				>
					<h3 >{contents.labels.manager}</h3 >
					<div
						className="manager"
					>
						<div
							className="manager-infos manager-last_name"
						>
							<label
								htmlFor="ast_name"
							>{contents.labels.lastname}</label >
							{!user.project_modify_right ? (
								<span
									id={'manager_last_name'}
								>
									{selectedProject?.manager?.last_name}
								</span >
							) : (
								<input
									type="text"
									name="last_name"
									id="manager_last_name"
									value={editedProject?.manager?.last_name}
									onChange={handleManagerInputChange}
								/>
							)}
						</div >
						<div
							className="manager-infos manager-first_name"
						>
							<label
								htmlFor="first_name"
							>{contents.labels.firstname}</label >
							{!user.project_modify_right ? (
								<span
									id={'manager_first_name'}
								>
									{selectedProject?.manager?.first_name}
								</span >
							) : (
								<input
									type="text"
									name="first_name"
									id="manager_first_name"
									value={editedProject?.manager?.first_name}
									onChange={handleManagerInputChange}
								/>
							)}
						</div >
						<div
							className="manager-infos manager-phone"
						>
							<label
								htmlFor="phone"
							>{contents.labels.phone}</label >
							{!user.project_modify_right ? (
								<span
									id={'manager_phone'}
								>
									{selectedProject?.manager?.phone}
								</span >
							) : (
								<input
									type="tel"
									name="phone"
									id="manager_phone"
									value={editedProject?.manager?.phone}
									onChange={handleManagerInputChange}
								/>
							)}
						</div >
						<div
							className="manager-infos manager-email"
						>
							<label
								htmlFor="email"
							>{contents.labels.mail}</label >
							{!user.project_modify_right ? (
								<span
									id={'manager_email'}
								>
									{selectedProject?.manager?.email}
								</span >
							) : (
								<input
									type="email"
									name="email"
									id="manager_email"
									value={editedProject?.manager?.email}
									onChange={handleManagerInputChange}
								/>
							)}
						</div >
					</div >
				</div >
				<div
					className="project-elem project-description"
				>
					<label
						htmlFor="description"
					>
						{contents.labels.projectDescription}
					</label >
					{!user.project_modify_right ? (
						<span
							id={'description'}
						>{selectedProject?.description}</span >
					) : (
						<textarea
							name="description"
							id="description"
							rows={4}
							value={editedProject.description}
							onChange={handleInputChange}
						/>
					)}
				</div >
			</section >
			{editedSection.projectGeneralInfos && (
				<Buttons
					key={'d86923e2-f915-4f3b-a011-1693a5a6b285'}
					customCSS={'editButtons'}
					handleSubmit={handleEditSubmit}
					handleCancel={editCancel}
				/>
			)}
		</div >
	);
};

export default ProjectGeneralInfos;
