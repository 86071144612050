/**
 * Import modules
 */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Import components
 */

/**
 * Import locals
 */
import './NotFound.scss';
import contents from '../../contents/contents.json';

const NotFound = () => {
	const history = useHistory();

	useEffect(() => {
		setTimeout(() => {
			history.push('/');
		}, 5000);
	}, []);

	return (
		<div
			className={'NotFound'}
		>
			{contents.messages.NotFound1}
			<br />
			{contents.messages.NotFound2}
		</div >
	);
};

export default NotFound;
