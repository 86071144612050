/**
 * Import modules
 */
import React from 'react';

/**
 * Import components
 */
import AssociationDetail from '../AssociationDetail/AssociationDetail';

/**
 * Import context
 */
import { useUserContext } from '../../contexts/userContext';

/**
 * Import locals
 */
import './AssosciationList.scss';
import contents from '../../contents/contents.json';

const tableTitle = {
	name: contents.AssosciationList.tableTitles.name,
	address: contents.AssosciationList.tableTitles.address,
	zipcode: contents.AssosciationList.tableTitles.zipcode,
	city: contents.AssosciationList.tableTitles.city,
};
const titleCss = {
	line: 'associationsList-title',
	name: 'Nom',
	address: 'Adresse',
	zipcode: 'Code postal',
	city: 'Ville',
};

const AssosciationList = ({ associations, setEdited }) => {
	const { adminInitialisation: adminInit } = useUserContext();

	let tableHTML =
		associations.length > 0 ? (
			associations.map((item, i) => {
				return (
					<AssociationDetail
						key={`${i}_${item.id}`}
						item={item}
						index={i}
						isActive={item.status === 'active'}
						setEdited={setEdited}
					/>
				);
			})
		) : (
			<></>
		);

	return (
		<div
			className={'AssosciationList'}
		>
			<table >
				<AssociationDetail
					customCss={titleCss}
					item={tableTitle}
					title={true}
					index={'title'}
					associationList={adminInit.associationList}
					isActive={true}
				/>
				{tableHTML}
			</table >
		</div >
	);
};

export default AssosciationList;
