/**
 * Import modules
 */
import React from 'react';

/**
 * Import components
 */
import UserDetail from '../UserDetail/UserDetail';

/**
 * Import context
 */
import { useUserContext } from '../../contexts/userContext';

/**
 * Import locals
 */
import './UsersList.scss';
import contents from '../../contents/contents.json';

const titleCss = {
	line: 'usersList-title',
	role: '',
	assos: '',
	firstname: '',
	lastname: '',
	email: '',
	status: '',
	readRight: '',
	editRight: '',
	dlRight: '',
	extra: '',
};

const UsersList = ({ users, setEdited }) => {
	const { user, init, adminInitialisation: adminInit } = useUserContext();

	const roleList = init.roleList?.filter((item) =>
		user.role.title !== 'superAdmin' ? item.title !== 'superAdmin' : item
	);

	const receiverList =
		user.role.title !== 'superAdmin' ? init.recievers : adminInit.recieverList;

	return (
		<div className={'UsersList'}>
			<table>
				<UserDetail
					key={'36d3ea89-30e8-4afb-9d83-f1e6c094b557'}
					item={contents.UsersList.tableTitle}
					customCss={titleCss}
					title={true}
					isActive={true}
				/>
				{users?.length > 0 ? (
					users.map((item, i) => {
						return (
							<UserDetail
								key={`${item.id}-user-${i}`}
								init={init}
								associationList={adminInit.associationList}
								recieverList={receiverList}
								roleList={roleList}
								thKey={i + item.email}
								index={i}
								item={item}
								isActive={item.status === 'active'}
								setEdited={setEdited}
							/>
						);
					})
				) : (
					<></>
				)}
			</table>
		</div>
	);
};

export default UsersList;
