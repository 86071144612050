// Import modules
import React, { useEffect, useState } from 'react';
import { ImPlus } from 'react-icons/im';
import { GoCheck, GoPencil } from 'react-icons/go';
import { IoClose } from 'react-icons/io5';
import { Spinner } from 'react-bootstrap';

// Import components
import { AddProjectUserRelation } from './modals/AddProjectUserRelation';

// Import contexts
import { useProjectContext } from '../../contexts/projectContext';

// Import Hooks

// Import locals
import './ProjectUser.scss';
import {
	getEmployeeByProjectId,
	removeEmployeeFromProject,
	updateEmployeeOnProject,
} from '../../requests/projectsRequests';
import contents from '../../contents/contents.json';

export const ProjectUser = () => {
	const { selectedProject, projectEmployee, setProjectEmployee } =
		useProjectContext();

	const [edited, setEdited] = useState(true);
	const [editMode, setEditMode] = useState(false);
	const [showAddModal, setShowAddModal] = useState(false);

	const handleSwitchEditeMode = async () => {
		setEditMode(!editMode);
	};

	const handleSwitchShowAddModal = async () => {
		setShowAddModal(!showAddModal);
	};

	useEffect(() => {
		if (edited) {
			(async () => {
				const result = await getEmployeeByProjectId(selectedProject.id);

				if (result.status !== 200) {
					console.log('[ProjectUser.jsx]{L:36} result: ', result);
				}
				setProjectEmployee(result.data);
			})();
			setEdited(false);
		}
	}, [edited]);

	return (
		<div className={'ProjectUser'}>
			<section className={'ProjectUser__section'}>
				<div className="project-elem project__label">
					<label htmlFor="analytic-code">{contents.labels.employees}</label>
					<div>
						<ImPlus
							key={'ad7d6248-161f-4e59-93eb-c46c4f87be7a'}
							size={22}
							onClick={handleSwitchShowAddModal}
						/>

						<GoPencil
							className={'edit-pencil'}
							key={'e043b011-e486-49f0-b897-077587a18419'}
							size={22}
							onClick={handleSwitchEditeMode}
						/>
					</div>
				</div>
				<table>
					<thead>
						<UserCard
							employee={{
								last_name: 'Nom',
								first_name: 'Prénom',
								role: { title: 'Rôle' },
								projects: [{ duration: 'Durée (heures)' }],
							}}
							isTitle={true}
						/>
					</thead>
					{projectEmployee?.map((employee, i) => (
						<UserCard
							employee={employee}
							editMode={editMode}
							setEdited={setEdited}
							setProjectEmployee={setProjectEmployee}
							key={`${i}-${employee.id}}`}
						/>
					))}
				</table>
			</section>
			<AddProjectUserRelation
				show={showAddModal}
				onHide={handleSwitchShowAddModal}
				setShowAddModal={setShowAddModal}
				setEdited={setEdited}
			/>
		</div>
	);
};

const UserCard = ({
	employee,
	editMode,
	setEdited,
	setProjectEmployee,
	isTitle = false,
}) => {
	const { selectedProject } = useProjectContext();
	const [editduration, setEditduration] = useState(
		employee?.projects[0]?.duration
	);
	const [loading, setLoading] = useState(false);

	let role;
	switch (employee.role.title) {
		case 'employee':
			role = contents.roles.employee;
			break;
		case 'manager':
			role = contents.roles.manager;
			break;
		case 'admin':
			role = contents.roles.admin;
			break;
		default:
			role = employee.role.title;
	}

	const handleClickDelete = async () => {
		const test = await removeEmployeeFromProject(
			selectedProject.id,
			employee.id
		);

		if (test.status !== 200) {
			return console.log('[ProjectUser.jsx]{L:36} result: ', test);
		}
		setEdited(true);
	};

	const handleUpdateDuration = async (evt) => {
		evt.preventDefault();
		const { value } = evt.target;
		console.log('[ProjectUser.jsx]{L:36} value: ', value);
		setEditduration(value);
	};

	const handleClickUpdate = async (evt) => {
		evt.preventDefault();
		setLoading(true);
		console.log('[ProjectUser.jsx]{L:150} send update: ');
		const updateDuration = await updateEmployeeOnProject(
			employee.projects[0].id,
			editduration,
			employee.projects[0].projectId
		);
		console.log('[ProjectUser.jsx]{L:154} test: ', updateDuration);
		setProjectEmployee(updateDuration.data);
		setLoading(false);
	};

	return (
		<tr className={`UserCard ${!isTitle && 'row_gap'}`}>
			<th className={`user-card__last_name ${isTitle && 'user_title'}`}>
				{employee.last_name}
			</th>
			<th className={`user-card__first_name ${isTitle && 'user_title'}`}>
				{employee.first_name}
			</th>
			<th className={`user-card__role ${isTitle && 'user_title'}`}>{role}</th>
			<th className={`user-card__hours ${isTitle && 'user_title'}`}>
				{editMode ? (
					<>
						<input
							type="number"
							value={editduration}
							onChange={handleUpdateDuration}
						/>
						{loading ? (
							<Spinner />
						) : (
							<GoCheck className={``} onClick={handleClickUpdate} />
						)}
					</>
				) : (
					editduration.toString().replace('.', ',')
				)}
			</th>
			{editMode && (
				<th className={`user-card__delete ${isTitle && 'user_title'}`}>
					<IoClose
						size={22}
						data-section={'project__user__delete'}
						onClick={handleClickDelete}
					/>
				</th>
			)}
		</tr>
	);
};
