/**
 * Import modules
 */
import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import { GoCloudUpload } from 'react-icons/go';
import { IoClose } from 'react-icons/io5';

/**
 * Import components
 */
import Buttons from '../../Buttons/Buttons';
import Message from '../../Message/Message';

/**
 * Import Contexts
 */
import { useSubventionContext } from '../../../contexts/subventionContext';

/**
 * Import locals
 */
import './AddDocumentModal.scss';
import { uploadFiles } from '../../../requests/filesRequest';
import contents from '../../../contents/contents.json';

const AddDocumentModal = () => {
	const {
		selectedSubvention,
		setSelectedSubvention,
		setEditedSubvention,
		inputFile,
		showAddDocumentModal,
		switchShowAddDocumentModal,
		editCancel,
		newDocumentsList,
		setNewDocumentsList,
		fileUploadError,
		actions: { setFileUploadError },
	} = useSubventionContext();

	//const [fileUploadError, setFileUploadError] = useState(['']);

	const selectFileToUpload = async (evt) => {
		const files = Array.from(evt.target.files);

		const filteredFiles = files.filter(
			(file) =>
				file?.type === 'application/pdf' ||
				file?.type === 'image/jpeg' ||
				file?.type === 'image/png' ||
				file?.type ===
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
				file?.type === 'application/vnd.ms-excel'
		);
		const errorFiles = files.filter(
			(file) =>
				file?.type !== 'application/pdf' &&
				file?.type !== 'image/jpeg' &&
				file?.type !== 'image/png' &&
				file?.type !==
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' &&
				file?.type !== 'application/vnd.ms-excel'
		);

		const errorMessages = errorFiles.map(
			(file) => `${file?.name} ${contents.messages.wrongFileType}`
		);

		await setFileUploadError(errorMessages);

		setNewDocumentsList([...newDocumentsList, ...filteredFiles]);
	};

	const removeNewDoc = (event) => {
		let { index } = event.target.dataset;

		if (event.target.localName === 'path') {
			index = event.target.parentNode.dataset.index;
		}

		const editedNewDoc = [...newDocumentsList];
		editedNewDoc.splice(index, 1);

		setNewDocumentsList(editedNewDoc);
	};

	const handleUploadFiles = async (event) => {
		event.preventDefault();

		// send to API
		// join user id to request

		// Create an object of formData
		const formData = new FormData();

		newDocumentsList.forEach((file, i) => {
			file.date = file?.lastModifiedDate;
			formData.append(i, file);
			formData.append(i, file?.lastModifiedDate);
		});

		formData.append('subventionId', selectedSubvention.id);
		formData.append('associationId', selectedSubvention.association.id);

		const result = await uploadFiles(formData);

		if (result.status !== 200) {
			setFileUploadError(result.data.message);
		} else {
			setSelectedSubvention({ ...selectedSubvention, ...result.data });
			setEditedSubvention({ ...selectedSubvention, ...result.data });
			setNewDocumentsList([]);
			setFileUploadError(['']);
			switchShowAddDocumentModal();
		}
	};

	const newDocList = newDocumentsList.map((item, i) => {
		if (item.name) {
			return (
				<div
					key={item.name + i + '75199693-021f-4de4-aa63-9d435983e1a2'}
					className={'file-item'}
				>
					<div
						key={item.name + i + 'b751749f-16aa-424c-9aba-69498c25cb33'}
						className="file"
					>
						{item.name}
					</div>
					<IoClose
						key={item.name + i + '481b996c-18fc-4dae-9f7e-f95e4e039bd4'}
						size={35}
						color={'#47C8F4'}
						data-index={i}
						onClick={removeNewDoc}
					/>
				</div>
			);
		} else {
			return <></>;
		}
	});

	return (
		<Modal
			key={'53efe02b-74aa-4b84-ba23-cde915a1c497'}
			show={showAddDocumentModal}
			onHide={switchShowAddDocumentModal}
			size={'xl'}
			aria-labelledby={'AddDocumentModal'}
			centered
			restoreFocus
			dialogClassName={'AddSubventionDocumentModal'}
		>
			<Modal.Header closeButton>
				<Modal.Title>{contents.titles.AddDocumentModal}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form onSubmit={handleUploadFiles}>
					<input
						key={'a76706b0-a218-486f-862b-c8f45a6f0df0'}
						type="file"
						id="file"
						ref={inputFile}
						style={{ display: 'none' }}
						onChange={selectFileToUpload}
						multiple
					/>
					<Message
						status={'upload-infos'}
						message={contents.messages.advertFileType}
					/>
					<Message
						status={'upload-infos'}
						message={contents.messages.advertSubvention}
					/>

					<ul
						key={'ae673471-2fef-4cfc-8444-f011a1286931'}
						className={'files-list'}
					>
						{newDocList}
					</ul>

					<div
						key={'10629639-8b5d-499f-8f05-afbd12e7cec2'}
						className="error-message"
					>
						{fileUploadError.map((message, i) => (
							<Message key={i + message} status={'error'} message={message} />
						))}
					</div>

					<div key={'571166bb-ab6b-40fb-9b78-a4d193512bb6'} className="button">
						<GoCloudUpload
							key={'e4fc06ee-1be6-47a5-a42e-b29c32db2c69'}
							size={40}
							color={'#47C8F4'}
							id={'cloudIcon'}
							onClick={() => {
								// `current` points to the mounted file input element
								inputFile.current.click();
							}}
						/>
						<label
							key={'361684a4-5766-4cb5-b3bc-7775a5987ce2'}
							htmlFor="cloudIcon"
						>
							{contents.labels.AddDocumentModal}
						</label>
					</div>

					<Buttons
						key={'d859886d-d3cd-43e3-8bcb-efd4c32d8264'}
						handleCancel={editCancel}
						customCSS={'editButtons'}
					/>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default AddDocumentModal;
