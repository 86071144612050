// Import modules
import React from 'react';
import { NavLink } from 'react-router-dom';
import slugify from 'react-slugify';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { FaSort } from 'react-icons/fa';

// Import components

// Import contexts

// Import Hooks

// Import locals

export const ProjectCard = ({
	project,
	user,
	handleOnClick = null,
	titles = null,
}) => {
	let manager = titles ? titles?.manager : '';

	if (project && project.manager) {
		manager =
			Object.prototype.hasOwnProperty.call(project.manager, 'first_name') &&
			`${project.manager.first_name} ${project.manager.last_name}`;
	}

	return (
		<tr
			className={`ProjectCard project-item proj-list ${
				user?.role?.title === 'employee' && 'no-sub'
			} `}
		>
			<th
				className={`project-item-content project-analytic ${
					titles ? 'title' : 'text-wrapper'
				}`}
			>
				{titles ? titles.analytic_code : project.analytic_code}
				{titles && (
					<FaSort data-column={'analytic_code'} onClick={handleOnClick} />
				)}
			</th>
			<th
				className={`project-item-content project-name ${
					titles ? 'title' : 'text-wrapper'
				}`}
			>
				{titles ? (
					<>
						{titles.name}
						{titles && <FaSort data-column={'name'} onClick={handleOnClick} />}
					</>
				) : (
					<NavLink
						to={`/dashboard/projects/${slugify(project.name)}?id=${project.id}`}
						className={`proj-detail-link`}
					>
						{project.name}
					</NavLink>
				)}
			</th>
			<th
				className={`project-item-content manager ${
					titles ? 'title' : 'text-wrapper'
				}`}
			>
				{manager}
			</th>
			<th
				className={`project-item-content description ${
					titles ? 'title' : 'text-wrapper'
				}`}
				data-tip={project && project?.description}
			>
				{titles
					? titles.description
					: project?.description
					? `${project?.description.substring(0, 20)} ...`
					: ''}
			</th>
			<th
				className={`project-item-content project-start_date ${
					titles ? 'title' : 'text-wrapper'
				}`}
			>
				{titles ? titles.startDate : project.start_date}
				{titles && (
					<FaSort data-column={'start_date'} onClick={handleOnClick} />
				)}
			</th>
			<th
				className={`project-item-content project-end_date ${
					titles ? 'title' : 'text-wrapper'
				}`}
			>
				{titles ? titles.endDate : project.end_date}
				{titles && <FaSort data-column={'end_date'} onClick={handleOnClick} />}
			</th>
			<th
				className={`project-item-content project-etat ${
					titles ? 'title' : 'text-wrapper'
				}`}
			>
				{titles ? titles.status : project.etat}
				{titles && <FaSort data-column={'etat'} onClick={handleOnClick} />}
			</th>

			{user && user.role?.title !== 'employee' && (
				<th className={`project-item-content project-sub ${titles && 'title'}`}>
					{titles
						? titles.subventions
						: project.subventions?.map((sub, i) => (
								<SubventionCard key={i} subvention={sub} />
						  ))}
				</th>
			)}
			<ReactTooltip
				place={'top'}
				effect={'solid'}
				className={'tooltipCustom dashboardTooltip test'}
				multiline={true}
			/>
		</tr>
	);
};

const SubventionCard = ({ subvention }) => {
	return (
		<span className={'sub-item'}>
			<NavLink
				className={`sub-link text-wrapper`}
				to={`/dashboard/subventions/${slugify(subvention.name)}?id=${
					subvention.id
				}`}
			>
				{subvention.name}
			</NavLink>
		</span>
	);
};
