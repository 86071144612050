/**
 * Import modules
 */
import React from 'react';

/**
 * Import components
 */

/**
 * Import Contexts
 */
import { useManagerAdminContext } from '../../../../contexts/ManagerAdminContext';

/**
 * Import locals
 */
import './AccountList.scss';
import contents from '../../../../contents/contents.json';

const AccountList = ({ list }) => {
	const { setSelectedItem, switchEditMode } = useManagerAdminContext();

	const onAccountSelect = (evt) => {
		let { id } = evt.target;
		if (evt.target.localName === 'span') {
			id = evt.target.parentNode.id;
		}
		const element = list.filter((account) => account.id === parseInt(id))[0];
		setSelectedItem(element);
		switchEditMode();
	};

	const accountListHTML = list.map((item, i) => (
		<div
			key={`${item.id}-${i}-div-item`}
			id={item.id}
			className={'product-item'}
			onClick={onAccountSelect}
		>
			<span
				key={`${item.id}-${i}-span-code`}
				className={'product-item-code'}
			>
				{item.code}
			</span >
			<span
				key={`${item.id}-${i}-span-item_name`}
				className={'product-item-name'}
			>
				{item.name}
			</span >
			<span
				key={'573c12c3-1be3-4a2d-83bd-af92dbd6bf4e'}
				className={'product-item-status'}
			>
				{item.isShown ? contents.labels.shown : contents.labels.hidden}
			</span >
		</div >
	));

	return <div
		className={'AccountList'}
	       >{accountListHTML}</div >;
};

export default AccountList;
