/**
 * Import modules
 */

/**
 * Import locals
 */
import utility from '../utility';

export const massImportData = async (data) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'POST',
			`${utility.apiURL}import/data`,
			token,
			data
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};
