import React, { useState } from 'react';
import './WebSiteCard.scss';
import {
	deleteWebSite,
	updateWebSite,
} from '../../../../../requests/webSiteRequests';
import Buttons from '../../../../Buttons/Buttons';
import { BiTrash } from 'react-icons/bi';
import { useWebSiteContext } from '../../../../../contexts/webSiteContext';
export const WebSiteCard = ({ website, editMode }) => {
	const {
		webSiteList,
		actions: { setWebSiteList },
	} = useWebSiteContext();

	const [editedWebSite, setEditedWebSite] = useState(website);
	const [loading, setLoading] = useState(false);

	const handleSubmit = async () => {
		setLoading(true);

		const result = await updateWebSite(editedWebSite);

		const newWebSiteList = webSiteList.filter(
			(webSite) => webSite.id !== editedWebSite.id
		);
		setWebSiteList([...newWebSiteList, editedWebSite]);

		setLoading(false);
	};

	const handelInputChange = (evt) => {
		const { name, value } = evt.target;
		setEditedWebSite({ ...editedWebSite, [name]: value });
	};

	const handleCancel = () => setEditedWebSite(website);

	const handleDelete = async () => {
		setLoading(true);
		const result = await deleteWebSite(editedWebSite.id);

		const newWebSiteList = webSiteList.filter(
			(webSite) => webSite.id !== editedWebSite.id
		);
		setWebSiteList(newWebSiteList);

		setLoading(false);
	};

	return (
		<li className={`WebSiteCard`}>
			{editMode ? (
				<form onSubmit={handleSubmit}>
					<fieldset disabled={loading}>
						<input
							className={`WebSiteCard__name`}
							type="text"
							name="name"
							value={editedWebSite.name}
							onChange={handelInputChange}
						/>
						<input
							className={`WebSiteCard__url`}
							type="text"
							name="url"
							value={editedWebSite.url}
							onChange={handelInputChange}
						/>
						<Buttons
							handleSubmit={handleSubmit}
							handleCancel={handleCancel}
							customCSS={`small_button`}
						/>
					</fieldset>
					<BiTrash
						onClick={handleCancel}
						size={25}
						onClickCapture={handleDelete}
					/>
				</form>
			) : (
				<>
					<span className={`WebSiteCard__name`}>{website.name}</span>
					<span className={`WebSiteCard__url`}>{website.url}</span>
				</>
			)}
		</li>
	);
};
