/**
 * Import modules
 */
import React, { useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';

/**
 * Import components
 */
import RecieverDetailModal from './RecieverDetailModal';

/**
 * Import locals
 */
import './RecieverDetail.scss';

const baseCss = {
	line: '',
	role: { title: '' },
	asso: '',
	firstname: '',
	lastname: '',
	email: '',
	status: '',
	readRight: '',
	editRight: '',
	dlRight: '',
	extra: '',
};

const RecieverDetail = ({
	item,
	index,
	title = false,
	customCss = baseCss,
	isActive,
}) => {
	const [editMode, setEditMode] = useState(false);

	const [reciever, setReciever] = useState(item);

	const handleThreeDotClick = () => {
		setEditMode(true);
	};

	customCss.line2 = isActive ? `` : 'inactive';

	return (
		<th
			key={item.id + '_' + index}
			className={`RecieverDetail ${customCss.line} ${customCss.line2}`}
		>
			<tr
				className={'recieverList-name'}
			>{item.name}</tr >
			<tr
				className={'recieverList-manager'}
			>{`${
				item.recieverManager?.first_name || ''
			} ${item.recieverManager?.last_name || ''}`}</tr >
			<tr
				className={'recieverList-association'}
			>
				{item.association_recievers?.name}
			</tr >

			<tr
				className={'usersList-extra'}
			>
				{title ? (
					<></>
				) : (
					<BsThreeDotsVertical
						id={`dot-${index}`}
						data-index={index}
						onClick={handleThreeDotClick}
					/>
				)}
			</tr >
			<RecieverDetailModal
				editMode={editMode}
				setEditMode={setEditMode}
				reciever={reciever}
				setReciever={setReciever}
				customCss={customCss}
				isActive={isActive}
			/>
		</th >
	);
};

export default RecieverDetail;
