/**
 * Import modules
 */
import React, { useEffect, useState } from 'react';
import Buttons from '../Buttons/Buttons';
import { GoPencil } from 'react-icons/go';

/**
 * Import components
 */

/**
 * Import contexts
 */
import { useSubventionContext } from '../../contexts/subventionContext';

/**
 * Import locals
 */
import './WebSiteCard.scss';
import contents from '../../contents/contents.json';

const compare = (a, b) => {
	if (a.name < b.name) {
		return -1;
	}
	if (a.name > b.name) {
		return 1;
	}
	return 0;
};

const WebSiteCard = ({ editable, handleEditSubmit }) => {
	const {
		editedSubvention,
		setEditedSubvention,
		editedSection,
		setEditedSection,
		editCancel,
		editSelectSite,
		actions: { setEditSelectSite },
	} = useSubventionContext();

	const [siteSelection, setSiteSelection] = useState({});

	const handleSwitchSelectMode = () => {
		setEditSelectSite(!editSelectSite);
	};

	const handleCheckboxChange = async (evt) => {
		const { id, value } = evt.target;
		let { section } = evt.target.dataset;

		const editedSiteSelection = {
			...siteSelection,
			[id]: { name: value, selected: !siteSelection[id].selected },
		};

		const webSites = editedSubvention.funder.websites.filter(
			(site) => editedSiteSelection[site.id].selected
		);

		setEditedSubvention({ ...editedSubvention, webSites });

		setSiteSelection(editedSiteSelection);
		setEditedSection({ ...editedSection, [section]: true });
	};

	const setWebSiteHTML = () => {
		return editedSubvention?.sites?.sort(compare).map((site, i) => (
			<li key={`site-${site.id}-${i}-${site.name}`}>
				<a
					key={`href-${site.id}-${i}-${site.name}`}
					href={site.url}
					target={'_blank'}
					rel="noreferrer"
				>
					{site.name}
				</a>
			</li>
		));
	};

	let webSiteHTML = setWebSiteHTML();

	useEffect(() => {
		const sites = {};

		if (editedSubvention.funder.websites) {
			const test = editedSubvention.sites?.map((site) => site.id);

			for (const site of editedSubvention.funder.websites.sort(compare)) {
				sites[site.id] = { name: site.name, selected: test?.includes(site.id) };
			}
			setSiteSelection(sites);
		}
	}, []);

	useEffect(() => {
		webSiteHTML = setWebSiteHTML();
	}, [editedSubvention]);

	return (
		<div className={'WebSiteCard'}>
			<section className={'web_sites'}>
				<label htmlFor="siteList">{contents.titles.WebSiteCard}</label>
				{editable && (
					<GoPencil
						className={'edit-pencil'}
						key={'2df718b8-69ad-4240-8a70-0535e08f27db'}
						size={22}
						onClick={handleSwitchSelectMode}
						data-tip={contents.toolTipDatas.selectWebSites}
					/>
				)}
				{!editSelectSite ? (
					<ul id={'siteList'}>{webSiteHTML}</ul>
				) : editedSubvention.funder?.websites?.length ? (
					<ul id={'siteList'}>
						{editedSubvention.funder.websites?.map((site, i) => (
							<li key={`siteInput-${site.id}-${i}-${site.name}`}>
								<input
									key={`siteCeckbox-${site.id}-${i}-${site.name}`}
									type="checkbox"
									id={site.id}
									value={siteSelection[site.id]?.name}
									onChange={handleCheckboxChange}
									checked={siteSelection[site.id]?.selected}
									data-section={'web_sites'}
								/>
								<label
									key={`siteLabel-${site.id}-${i}-${site.name}`}
									htmlFor={site.name}
								>
									{`${site.name} (${site.url})`}
								</label>
							</li>
						))}
					</ul>
				) : (
					<span>{`${contents.messages.noWebSiteOnFunder} ${editedSubvention.funder.name}.`}</span>
				)}
			</section>
			{editedSection.web_sites && (
				<Buttons
					key={
						'93bd9634-f544-46d1-b0b1-87f7b3ede49c3ac5f35e-cc2a-4118-b814-6a916f4f76c7'
					}
					customCSS={'editButtons'}
					handleSubmit={handleEditSubmit}
					handleCancel={editCancel}
				/>
			)}
		</div>
	);
};

export default WebSiteCard;
