/**
 * Import modules
 */
import { FaEye } from 'react-icons/fa';
import { GoAlert } from 'react-icons/go';
import moment from 'moment';
import React from 'react';
import slugify from 'react-slugify';

/**
 * Import components
 */

/**
 * Import locals
 */
import './Notification.scss';
import contents from '../../contents/contents.json';

const Notification = ({ item, index, handler, title }) => {
	return (
		<tr
			key={item.ref + '-' + index}
			className={`Notification ${item.new ? 'new' : ''}`}
		>
			<th
				className={'notification-ref'}
			>{item.ref}</th >
			<th
				className={'notification-name'}
			>{item.name}</th >
			<th
				className={'notification-type'}
			>{item.type}</th >
			{title ? (
				<th
					className={'notification-date'}
				>{item.date}</th >
			) : (
				<th
					className={'notification-date'}
				>
					{moment(item.date).format('DD/MM/YYYY')}
				</th >
			)}
			{title ? (
				<></>
			) : (
				<>
					<button
						className={'notification-button'}
						data-url={`/dashboard/subventions/${slugify(item.name)}?id=${
							item.id
						}`}
						onClick={handler}
					>
						{contents.buttons.notification}
					</button >
					<FaEye
						className={'notification-eye'}
						data-url={`/dashboard/subventions/${slugify(item.name)}?id=${
							item.id
						}`}
						onClick={handler}
					/>
				</>
			)}
			{item.lastDay ? (
				<th
					className={'notification-alert'}
				>
					<GoAlert
						className={'notification-svg'}
					/>
				</th >
			) : (
				<></>
			)}
		</tr >
	);
};

export default Notification;
