import React, { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

/**
 * Import contexts
 */
import { useUserContext } from './userContext';

// context creation
export const formContext = createContext('');

// context hook
export const useKeyContext = () => {
	const context = useContext(formContext);
	if (!context) {
		console.error(
			'keyContext should not be undefined. Did you forget to wrap your component with KeyContextProvider ?'
		);
	}
	return context;
};

// provider creation
export const KeyContextProvider = ({ children }) => {
	const { user } = useUserContext();
	let history = useHistory();
	const [key, setKey] = useState('');

	const setter = (value) => {
		setKey(value);
	};

	const handleClickHome = async () => {
		const from = history.location.pathname;
		if (!from.startsWith('/dashboard') || !from.startsWith('/backoffice')) {
			switch (user.role.title) {
				case 'admin':
					setKey('subventions');
					history.push('/dashboard');
					break;
				case 'manager':
					setKey('projects');
					history.push('/dashboard');
					break;
				case 'employee':
					setKey('projects');
					history.push('/dashboard');
					break;
				case 'superAdmin':
					setKey('utilisateurs');
					history.push('/backoffice/utilisateurs');
					break;

				default:
					history.push('/');
					break;
			}
		}
	};

	let value = {
		key,
		keySetter: setter,
		handleClickHome,
	};

	return <formContext.Provider value={value}>{children}</formContext.Provider>;
};
