/**
 * Import modules
 */
import React from 'react';
import { NumericFormat } from 'react-number-format';

/**
 * Import components
 */

/**
 * Import contexts
 */
import { useProjectContext } from '../../contexts/projectContext';

/**
 * Import locals
 */
import './ProjectTotals.scss';
import contents from '../../contents/contents.json';
import Buttons from '../Buttons/Buttons';

const ProjectTotals = ({ handleEditSubmit }) => {
	const {
		editedSection,
		setEditedSection,
		editedProject,
		setEditedProject,
		editCancel,
		totals,
	} = useProjectContext();

	const handleInputChange = (evt) => {
		const { id, value } = evt.target;
		setEditedProject({ ...editedProject, [id]: value });
		setEditedSection({ ...editedSection, projectBudget: true });
	};

	return (
		<div>
			<section
				key={'6fe6aa31-c202-49be-be7d-a4d07b477d70'}
				className={'ProjectTotals project-financial-infos'}
			>
				<div className={'total_container'}>
					<table className="financial_totals">
						<tbody>
							<tr className="financial_totals_rows">
								<th>{contents.ProjectTotals.ttlSubvention}</th>
								<td>
									<NumericFormat
										key={`b4a536d4-f2c1-4fb4-826f-883278aa04b9`}
										value={totals.subventions}
										displayType={'text'}
										thousandSeparator={' '}
										suffix={' €'}
										decimalScale={2}
										className="payment-item-3"
									/>
								</td>
							</tr>
							<tr className="financial_totals_rows">
								<th>{contents.ProjectTotals.ttlSelfFinancing}</th>
								<td>
									<NumericFormat
										key={`b4a536d4-f2c1-4fb4-826f-883278aa04b9`}
										value={totals.self_financing}
										displayType={'text'}
										thousandSeparator={' '}
										suffix={' €'}
										decimalScale={2}
										className="payment-item-3"
									/>
								</td>
							</tr>
							<tr className="financial_totals_rows">
								<th className="great_total">{contents.ProjectTotals.ttl}</th>
								<td className="great_total">
									<NumericFormat
										key={`b4a536d4-f2c1-4fb4-826f-883278aa04b9`}
										value={totals.total}
										displayType={'text'}
										thousandSeparator={' '}
										suffix={' €'}
										decimalScale={2}
										className="payment-item-3"
									/>
								</td>
							</tr>
						</tbody>
					</table>

					<table className="budget_table">
						<thead>
							<tr>
								<th>{}</th>
								<th>{contents.ProjectTotals.ttl}</th>
								<th>{contents.ProjectTotals.consumed}</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td className={'row_name'}>{contents.ProjectTotals.budget}</td>
								<td>
									<input
										type="number"
										name="total_budget"
										id="total_budget"
										inputMode={'numeric'}
										value={editedProject.total_budget}
										onChange={handleInputChange}
									/>
								</td>
								<td>
									<input
										type="number"
										name="total_consumed"
										id="total_consumed"
										inputMode={'numeric'}
										value={editedProject.total_consumed}
										onChange={handleInputChange}
									/>
								</td>
							</tr>
							<tr>
								<td className={'row_name'}>
									{contents.ProjectTotals.activity}
								</td>
								<td>
									<input
										type="number"
										name="activity_budget"
										id="activity_budget"
										inputMode={'numeric'}
										value={editedProject.activity_budget}
										onChange={handleInputChange}
									/>
								</td>
								<td>
									<input
										type="number"
										name="activity_consumed"
										id="activity_consumed"
										inputMode={'numeric'}
										value={editedProject.activity_consumed}
										onChange={handleInputChange}
									/>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</section>
			{editedSection.projectBudget && (
				<Buttons
					key={'d86923e2-f915-4f3b-a011-1693a5a6b285'}
					customCSS={'editButtons'}
					handleSubmit={handleEditSubmit}
					handleCancel={editCancel}
				/>
			)}
		</div>
	);
};

export default ProjectTotals;
