/**
 * Import modules
 */
import { Tab, Tabs } from 'react-bootstrap';
import {
	Redirect,
	Route,
	Switch,
	useHistory,
	useLocation,
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { GoDesktopDownload } from 'react-icons/go';
import { BiDuplicate } from 'react-icons/bi';
import { ImPlus } from 'react-icons/im';
import { Tooltip as ReactTooltip } from 'react-tooltip';

/**
 * Import components
 */
import SearchBar from '../SearchBar/SearchBar';
import PageSubventions from '../PageSubventions/PageSubventions';
import SubventionDetail from '../SubventionDetail/SubventionDetail';
import ProjectsList from '../ProjectsList/ProjectsList';
import ProjectDetail from '../ProjectDetail/ProjectDetail';
import Reminder from '../Reminder/Reminder';
import CreateSubventionModal from './Modals/CreateSubventionModal';
import CreateProjectModal from './Modals/CreateProjectModal';
import AdminTab from './Tabs/AdminTab/AdminTab';
import { DuplicateModalContainer } from './Modals/DuplicateModalContainer';

/**
 * Import Contexts
 */
import { useLoadingContext } from '../../contexts/loadingContext';
import { useSubventionContext } from '../../contexts/subventionContext';
import { useUserContext } from '../../contexts/userContext';
import { useDashboardContext } from '../../contexts/DashboarContext';
import { useKeyContext } from '../../contexts/keyContext';

/**
 * Import locals
 */
import './Dashboard.scss';
import { getAllSubventionListByAssociation } from '../../requests/SubventionRequests';
import { getAllProjectsListByAssociation } from '../../requests/projectsRequests';
import { downloadFiles } from '../../requests/filesRequest';
import contents from '../../contents/contents.json';
import { useProjectContext } from '../../contexts/projectContext';
import { getAllUsers } from '../../requests/userRequests';
import utility from '../../utility';

/**
 * Variables
 */
const toolTipDatas = {
	reminder: contents.toolTipDatas.reminder,
	download: contents.toolTipDatas.downloadAllSubventions,
	plus: contents.toolTipDatas.plusSubvention,
	duplicate: contents.toolTipDatas.duplicateSubvention,
};

const Dashboard = ({ setEdited }) => {
	const { loading, actions } = useLoadingContext();
	const {
		subventionList,
		subventionListSetter,
		selectedSubvention,
		reload,
		setReload,
	} = useSubventionContext();

	const { selectedProject, setEmployees } = useProjectContext();
	const { key, keySetter } = useKeyContext();

	const { user, init } = useUserContext();

	const {
		actions: { switchShowDuplicateModal, switchShowCreateModal },
	} = useDashboardContext();

	const getSubAndProject = async () => {
		let assosId = null;

		if (user.id) {
			const roleId = user.roleId;
			switch (user.role.title) {
				case 'admin':
					assosId = user.assos[0].id;
					break;
				case 'manager':
					assosId = user.recievers[0]?.associationId;
					break;
				case 'employee':
					assosId = user.assos[0].id;
					break;
			}

			setUtils({ assosId, roleId });

			const subResult = await getAllSubventionListByAssociation({
				assosId,
				roleId,
			});

			if (subResult.status === 404) {
				history.push('/404');
			}
			if (subResult.status === 403) {
				history.push('/login');
			}

			if (subResult.data) {
				setFirstLoad(false);
				await subventionListSetter(subResult.data);
				await setReload(false);
				await subventionListSetter(subResult.data);
				//await setSubventionList(subResult.data);
			}

			setUtils({ assosId, roleId });

			const projectResult = await getAllProjectsListByAssociation({
				assosId,
				roleId,
			});

			if (projectResult.status === 404) {
				history.push('/404');
			}
			if (projectResult.status === 403) {
				history.push('/login');
			}

			if (projectResult.data) {
				await setProjectList(projectResult.data);
			}
		}
	};

	let location = useLocation();
	let history = useHistory();

	const [utils, setUtils] = useState({});
	const [itemEdited, setItemEdited] = useState(true);
	const [filteredList, setFilteredList] = useState([...subventionList]);
	const [projectList, setProjectList] = useState([]);
	const [filteredProject, setFilteredProject] = useState([...projectList]);

	const [firstLoad, setFirstLoad] = useState(true);

	const handleShowAddModal = () => {
		switchShowCreateModal(key);
	};

	const handleFilterChange = (array) => {
		if (key === 'subventions') {
			setFilteredList(array);
		} else if (key === 'projects') {
			setFilteredProject(array);
		}
	};

	const handleDownloadClick = async (evt) => {
		if (!loading) {
			actions.setter(true);
			evt.preventDefault();

			if (key === 'subventions') {
				let res = { data: { data: { dataUrl: '' } } };
				const link = document.createElement('a');

				if (location.pathname === '/dashboard/subventions') {
					const xlsx = await utility.createXlsxFile(
						'subventions',
						filteredList,
						init.Subvention_typeList
					);

					res.data.data.dataUrl = xlsx.base64;
				} else {
					let assosId = null;
					if (user.id) {
						switch (user.roleId) {
							case 1:
								assosId = user.assos[0].id;
								break;
							case 2:
								assosId = user.recievers[0].associationId;
								break;
						}
					}
					res = await downloadFiles({
						type: 'subvention',
						assos: assosId,
						sub: selectedSubvention.id,
						detail: true,
					});

					console.log('[Dashboard.jsx]{L:201} res: ', res);
				}

				location.pathname === '/dashboard/subventions'
					? link.setAttribute('download', `Liste_des_subventions`)
					: link.setAttribute(
							'download',
							`${res.data.data.zipName.split('/').pop()}`
					  );

				link.href =
					location.pathname === '/dashboard/subventions'
						? `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data.data.dataUrl}`
						: `${res.data.data.dataUrl}`;
				link.id = 'dl';
				document.body.appendChild(link);
				link.click();

				const dlLink = document.getElementById('dl');
				dlLink.parentElement.removeChild(dlLink);
			} else if (key === 'projects') {
				let res = { data: { data: { dataUrl: '' } } };
				let projectId = selectedProject.id;
				const link = document.createElement('a');

				if (location.pathname === '/dashboard/projects') {
					const xlsx = await utility.createXlsxFile(
						'projects',
						filteredProject,
						init.Project_typeList
					);

					res.data.data.dataUrl = xlsx.base64;
				} else {
					let assosId = null;
					if (user.id) {
						switch (user.roleId) {
							case 1:
								assosId = user.assos[0].id;
								break;
							case 2:
								assosId = user.recievers[0].associationId;
								break;
						}
					}
					res = await downloadFiles({
						type: 'project',
						assos: assosId,
						project: selectedProject.id,
						detail: true,
					});
				}

				location.pathname === '/dashboard/projects'
					? link.setAttribute('download', `Liste_des_projets`)
					: link.setAttribute(
							'download',
							`${res.data.data.zipName.split('/').pop()}`
					  );

				link.href =
					location.pathname === '/dashboard/projects'
						? `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${res.data.data.dataUrl}`
						: `${res.data.data.dataUrl}`;
				link.id = 'dl';
				document.body.appendChild(link);
				link.click();

				const dlLink = document.getElementById('dl');
				dlLink.parentElement.removeChild(dlLink);
			}
			actions.setter(false);
		}
	};

	const tabsOnSelect = async (k) => {
		await keySetter(k);
		setReload(true);

		history.push(`/dashboard/${k}`);
	};

	/**
	 * Force reload on tab change
	 * Trigger: key
	 */
	useEffect(() => {
		setReload(true);
	}, [key]);

	/**
	 * Load subvention and project and set tooltip text
	 * Trigger: location.pathname, key, itemEdited
	 */
	useEffect(() => {
		(async () => {
			if (location.pathname === '/dashboard') {
				try {
					let result;
					await actions.setter(true);

					if (location.pathname === '/dashboard') {
						result = await getSubAndProject();
					} else {
						if (itemEdited || firstLoad) {
							result = getSubAndProject();
						}
					}
					if (!result) {
						// error
					}
					await actions.setter(false);
				} catch (error) {
					console.error('[Dashboard.jsx]{L:298} error: ', error);
				}

				if (key === 'subventions') {
					toolTipDatas.download = contents.toolTipDatas.downloadAllSubventions;
					toolTipDatas.plus = contents.toolTipDatas.plusSubvention;
				} else if (key === 'projects') {
					toolTipDatas.download = contents.toolTipDatas.downloadAllProjects;
					toolTipDatas.plus = contents.toolTipDatas.plusProject;
				}
			} else {
				if (key === 'subventions') {
					toolTipDatas.download = contents.toolTipDatas.downloadOneSubvention;
					toolTipDatas.plus = contents.toolTipDatas.plusSubvention;
				} else if (key === 'projects') {
					toolTipDatas.download = contents.toolTipDatas.downloadOneProject;
					toolTipDatas.plus = contents.toolTipDatas.plusProject;
				}
			}
		})();
	}, [location.pathname, key, itemEdited]);

	/**
	 * Force Load subventions and projects
	 * Trigger: reload, key, user
	 */
	useEffect(() => {
		(async () => {
			if (reload && user.id) {
				await getSubAndProject();
			}
		})();
	}, [reload, key, user]);

	/**
	 * Set filtered list
	 * Trigger: subventionList, projectList, reload, itemEdited, key
	 */
	useEffect(() => {
		(async () => {
			if (subventionList.length > 0) {
				await setFilteredList(subventionList);
				await setFilteredProject(projectList);
			}
			setItemEdited(false);

			const employees = await getAllUsers();
			setEmployees(employees.data);
		})();
	}, [subventionList, projectList, reload, itemEdited, key]);

	/**
	 * Set background color
	 * Trigger: once
	 */
	useEffect(() => {
		const root = document.getElementById('root');
		root.style.backgroundColor = '#D0D0D7';

		const svgUser = document.getElementById('iconUser');

		if (svgUser) {
			svgUser.style.color = '#037172';
		} else {
			root.style.backgroundColor = '#D0D0D7';
		}

		const userFirstName = document.getElementById('fName');
		if (userFirstName) {
			userFirstName.style.color = '#037172';
		}

		const userLastName = document.getElementById('lName');
		if (userLastName) {
			userLastName.style.color = '#037172';
		}
	}, []);

	const redirectUrl =
		user.roleId === 1 ? '/dashboard/subventions' : '/dashboard/projects';

	return (
		<>
			<div className={'Dashboard'}>
				<div className="buttons">
					<>
						<Reminder subventionList={filteredList} />
						<DownloadButton
							user={user}
							tabKey={key}
							onClickHandler={handleDownloadClick}
						/>
						<AddButton
							user={user}
							tabKey={key}
							onClickHandler={handleShowAddModal}
						/>
						<DuplicateButton
							user={user}
							location={location}
							onClickHandler={switchShowDuplicateModal}
						/>
					</>
				</div>

				<Tabs
					key={'2cfdf261-b057-4340-89ac-3d6245a18a80'}
					defaultActiveKey="subventions"
					id="Subventions"
					activeKey={key}
					onSelect={tabsOnSelect}
				>
					{user?.role?.title !== 'employee' && (
						<Tab
							key={'dbfc0565-b12d-4309-b222-8bc02e6ae496'}
							eventKey="subventions"
							title={contents.tabs.subventions}
						>
							<Switch>
								<Route exact path="/dashboard">
									<Redirect to={redirectUrl} />
								</Route>
								<Route exact path="/dashboard/subventions">
									<>
										<div className="sub-header show">
											<SearchBar
												keyType={key}
												itemList={
													key === 'subventions' ? subventionList : projectList
												}
												setter={handleFilterChange}
											/>
										</div>
										<PageSubventions
											utils={utils}
											setFilteredList={setFilteredList}
											filteredList={filteredList}
										/>
									</>
								</Route>
								<Route path="/dashboard/subventions/:slug">
									<SubventionDetail setItemEdited={setItemEdited} />
								</Route>
							</Switch>
						</Tab>
					)}
					<Tab
						key={'b640e198-301b-11ec-8d3d-0242ac130003'}
						eventKey="projects"
						title={contents.tabs.projects}
					>
						<Switch>
							<Route exact path="/dashboard/projects">
								<>
									<div className="sub-header show">
										<SearchBar
											keyType={key}
											itemList={
												key === 'subventions' ? subventionList : projectList
											}
											setter={handleFilterChange}
										/>
									</div>
									<ProjectsList
										utils={utils}
										setFilteredProject={setFilteredProject}
										filteredProjects={filteredProject}
									/>
								</>
							</Route>
							<Route path="/dashboard/projects/:slug">
								<ProjectDetail
									init={init}
									user={user}
									setEdited={setEdited}
									setItemEdited={setItemEdited}
								/>
							</Route>
						</Switch>
					</Tab>
					{user.admin_right && (
						<Tab
							key={'3a460d1b-73b2-4761-956f-539b80f5efed'}
							eventKey="admin"
							title={contents.tabs.admin}
						>
							<AdminTab />
						</Tab>
					)}
				</Tabs>
			</div>

			<CreateSubventionModal setEdited={setEdited} />
			<CreateProjectModal setEdited={setEdited} />
			<DuplicateModalContainer />
		</>
	);
};

export default Dashboard;

const DownloadButton = ({ user, tabKey, onClickHandler }) => {
	let renderHTML = <></>;

	if (
		(tabKey === 'subventions' && user.subvention_extract_right) ||
		(tabKey === 'projects' && user.project_extract_right)
	) {
		renderHTML = (
			<>
				<div data-tip={toolTipDatas.download}>
					<GoDesktopDownload
						key={'0ced3826-cbc7-40ca-8c1e-f564eb3c3f6e'}
						onClick={onClickHandler}
						size={35}
						color={'#47C8F4'}
					/>
				</div>
				<ReactTooltip
					place={'top'}
					effect={'solid'}
					className={'tooltipCustom dashboardTooltip test'}
					multiline={true}
				/>
			</>
		);
	}
	return renderHTML;
};

const AddButton = ({ user, tabKey, onClickHandler }) => {
	let renderHTML = <></>;

	if (
		(tabKey === 'subventions' && user.subvention_create_right) ||
		(tabKey === 'projects' && user.project_create_right)
	) {
		renderHTML = (
			<>
				<div data-tip={toolTipDatas.plus}>
					<ImPlus
						key={'b7641e6c-5daf-4ddb-90ce-f2078c82481d'}
						onClick={onClickHandler}
						size={25}
						color={'#47C8F4'}
					/>
				</div>
				<ReactTooltip
					place={'top'}
					effect={'solid'}
					className={'tooltipCustom dashboardTooltip test'}
					multiline={true}
				/>
			</>
		);
	}
	return renderHTML;
};

const DuplicateButton = ({ user, location, onClickHandler }) => {
	let renderHTML = <></>;

	if (
		(location.pathname.startsWith('/dashboard/subventions/') ||
			location.pathname.startsWith('/dashboard/projects/')) &&
		user.subvention_create_right
	) {
		renderHTML = (
			<>
				<div data-tip={toolTipDatas.duplicate}>
					<BiDuplicate
						key={'f3f52514-418b-41d2-af55-583de5d56423'}
						onClick={onClickHandler}
						size={25}
						color={'#47C8F4'}
					/>
				</div>
				<ReactTooltip
					place={'top'}
					effect={'solid'}
					className={'tooltipCustom dashboardTooltip test'}
					multiline={true}
				/>
			</>
		);
	}
	return renderHTML;
};
