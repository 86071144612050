/**
 * Import modules
 */
import React from 'react';
import Buttons from '../Buttons/Buttons';
import { ImPlus } from 'react-icons/im';

/**
 * Import components
 */
/**
 * Import Context
 */
import { useUserContext } from '../../contexts/userContext';
import { useSubventionContext } from '../../contexts/subventionContext';
import { useManagerAdminContext } from '../../contexts/ManagerAdminContext';

/**
 * Import locals
 */
import './SubventionGeneralInfos.scss';
import utility from '../../utility';
import contents from '../../contents/contents.json';

const SubventionGeneralInfos = ({ editable, handleEditSubmit }) => {
	const { init, user } = useUserContext();
	const {
		selectedSubvention,
		editedSubvention,
		setEditedSubvention,
		editedSection,
		setEditedSection,
		editCancel,
	} = useSubventionContext();
	const { switchShowModal, setAdminKey } = useManagerAdminContext();

	const handleSubStatusChange = async (evt) => {
		const { section } = evt.target.dataset;
		let etat;
		switch (evt.target.value) {
			case 'Demandée':
				etat = contents.etat.etatPending;
				break;
			case 'Acceptée':
				etat = contents.etat.etatInProgress;
				break;
			case 'Refusée':
				etat = contents.etat.etatClosed;
				break;
			default:
				break;
		}
		setEditedSubvention({
			...editedSubvention,
			etat,
			status: evt.target.value,
		});

		setEditedSection({ ...editedSection, [section]: true });
	};

	const handleSubVersementChange = async (evt) => {
		const { section } = evt.target.dataset;
		setEditedSubvention({
			...editedSubvention,
			versement: evt.target.value,
		});
		setEditedSection({ ...editedSection, [section]: true });
	};

	const handleInternalMangerChange = async (evt) => {
		const user = {
			id: evt.target.value,
			...evt.target.selectedOptions[0].dataset,
		};

		await setEditedSection({ ...editedSection, subGeneralInfos: true });
		await setEditedSubvention({
			...editedSubvention,
			manager: user,
		});
	};

	const handleSelectTypeChange = async (evt) => {
		const obj = {
			id: parseInt(evt.target.value),
			name: evt.target.selectedOptions[0].innerText,
		};

		await setEditedSection({ ...editedSection, subGeneralInfos: true });
		await setEditedSubvention({
			...editedSubvention,
			subventionType: obj,
		});
	};

	const handleInputChange = async (evt) => {
		evt.preventDefault();
		let { id, name, value } = evt.target;

		const { index, attribute } = evt.target.dataset;

		let code = null;
		if (evt.target.localName === 'select') {
			code = isNaN(evt.target.selectedOptions[0].innerText)
				? evt.target.selectedOptions[0].innerText
				: parseInt(evt.target.selectedOptions[0].innerText);
		}

		switch (evt.target.value) {
			case 'false':
				value = false;
				break;
			case 'true':
				value = true;
				break;
			default:
				break;
		}

		const edit = { ...editedSubvention };

		if (index) {
			edit[id][parseInt(index)].id = parseInt(value);
			if (code) {
				edit[id][parseInt(index)][attribute] = code;
			}
			edit[name] = parseInt(value);
		} else {
			if (isNaN(value) || typeof value === 'boolean') {
				edit[id].id = !value;
				edit[name] = value;
				if (code) {
					edit[id][attribute] = code;
				}
			} else if (id === 'accounting_tier') {
				edit[id] = init.Accounting_tiersList.filter(
					(tiers) => tiers.id === parseInt(value)
				)[0];
			} else if (id === 'sub-funder') {
				const newFunder = init.FunderList.filter(
					(funder) => funder.id === parseInt(value)
				);
				edit.funder = newFunder[0];
			} else if (id === 'accounting_product') {
				edit[id] = init.Accounting_productList.filter(
					(product) => product.id === parseInt(value)
				)[0];
			} else {
				edit[id].id = parseInt(value);
				edit[name] = parseInt(value);
				if (code) {
					edit[id][attribute] = code;
				}
			}
		}

		await setEditedSection({ ...editedSection, subGeneralInfos: true });

		await setEditedSubvention({
			...edit,
			edited: true,
		});
	};

	const handleInternalRefChange = async (evt) => {
		evt.preventDefault();
		let { id, value } = evt.target;

		await setEditedSection({ ...editedSection, subGeneralInfos: true });

		const edit = { ...editedSubvention };

		await setEditedSubvention({
			...edit,
			[id]: value,
			edited: true,
		});
	};

	const handleFunderRefChange = async (evt) => {
		const { value } = evt.target;
		await setEditedSection({ ...editedSection, subGeneralInfos: true });
		const editeSub = { ...editedSubvention, funder_ref: value, tutu: value };
		await setEditedSubvention(editeSub);
	};

	const handleStartDate = async (evt) => {
		const { duration, nDuration } = utility.getDurations(
			evt.target.value,
			editedSubvention.end_date
		);
		setEditedSubvention({
			...editedSubvention,
			start_date: evt.target.value,
			duration,
			N_duration: nDuration,
		});
		await setEditedSection({ ...editedSection, subGeneralInfos: true });
	};

	const handleEndDate = async (evt) => {
		const { duration, nDuration } = utility.getDurations(
			editedSubvention.start_date,
			evt.target.value
		);

		await setEditedSubvention({
			...editedSubvention,
			end_date: evt.target.value,
			duration,
			N_duration: nDuration,
		});
		await setEditedSection({ ...editedSection, subGeneralInfos: true });
	};

	const handleClickAddAccount = async (evt) => {
		let { id } = evt.target;
		if (evt.target.localName === 'path') {
			id = evt.target.parentNode.id;
		}
		await setAdminKey(id);
		await switchShowModal(id);
	};

	let statusHTML,
		versementHTML,
		managerHTML,
		accountingTiersSelectOptions,
		fundersSelectOption = <></>;

	if (editedSubvention.id) {
		statusHTML = contents.statusOptions.map((item, i) => (
			<option key={item + i} value={item}>
				{item}
			</option>
		));
		versementHTML = contents.versementOptions.map((item, i) => (
			<option key={item + i} value={item}>
				{item}
			</option>
		));
		managerHTML = init.managers.map((item, i) => (
			<option
				key={item.first_name + item.last_name + i}
				data-first_name={item.first_name}
				data-last_name={item.last_name}
				value={item.id}
			>{`${item.first_name} ${item.last_name}`}</option>
		));

		if (init.Accounting_tiersList?.length > 0) {
			accountingTiersSelectOptions = (
				<>
					{init.Accounting_tiersList.filter((item) => item.isShown).map(
						(item, i) => (
							<option
								key={item.id + '-' + i + '-' + item.code + '-' + item.name}
								value={item.id}
							>
								{`${item.code} - ${item.name}`}
							</option>
						)
					)}
				</>
			);
		}

		fundersSelectOption = (
			<>
				{init.FunderList.map((item, i) => (
					<option key={item.id + '-' + i + '-' + item.name} value={item.id}>
						{item.name}
					</option>
				))}
			</>
		);
	}

	return (
		<div className={'SubventionGeneralInfos'}>
			<section
				key={'9c52ad43-0947-4ebb-a6b4-4dd4019d7975'}
				className={'sub-general-infos'}
			>
				<div
					key={'d507a3ba-5c24-445b-aaf8-61e8f0c33478'}
					className={'sub-general-item sub-internal_ref'}
				>
					<label
						key={'96bf149c-587a-4129-9f9c-28e51dff07a0'}
						htmlFor="internalRef"
					>
						Référence interne :
					</label>
					{!editable ? (
						<span key={'da3b3ba4-cf0b-41d3-b860-c79e10ee06a5'} id={'manager'}>
							{selectedSubvention.internal_ref}
						</span>
					) : (
						<input
							key={'input 118c928b-953c-4a7b-a2b0-17ddef2d8359'}
							type={'text'}
							name="internalRef"
							id={'internalRef'}
							data-section={'subGeneralInfos'}
							value={editedSubvention.internalRef}
							onChange={handleInternalRefChange}
						/>
					)}
				</div>

				<div
					key={'b2def54c-8b8f-47b4-8ea3-e6321a69609d'}
					className={'sub-general-item sub-ref'}
				>
					<label key={'f23a7488-be78-4ecf-871f-fa98bb4416c4'} htmlFor="sub-ref">
						{contents.labels.ref}
					</label>
					<div key={'586659ab-d141-43a8-81e1-9acdb388565a'} id="sub-ref">
						{selectedSubvention.ref}
					</div>
				</div>
				<div
					key={'ff8944f6-a15f-4266-863b-1bc01a9ee101'}
					className={'sub-general-item sub-status'}
				>
					<label
						key={'153a8bbb-7b71-4bac-8bb8-bca859dbb80a'}
						htmlFor="sub-status"
					>
						{contents.labels.status}
					</label>
					{!editable ? (
						<span key={'b0ac934b-b16f-4545-a64d-d82dfd977332'} id={'status'}>
							{selectedSubvention.status}
						</span>
					) : (
						<select
							key={'f3cf86cb-bc47-4392-bf7d-b1baed73cad9'}
							name="status"
							id={'status'}
							data-section={'subGeneralInfos'}
							value={editedSubvention.status}
							onChange={handleSubStatusChange}
						>
							{statusHTML}
						</select>
					)}
				</div>
				<div
					key={'aafc35db-40cc-426c-803d-bb0d30cce9cc'}
					className={'sub-general-item sub-versement'}
				>
					<label
						key={'d974a76a-0a43-402c-8fa7-dfecaeec81df'}
						htmlFor="sub-versement"
					>
						{contents.labels.versement}
					</label>
					{!editable ? (
						<span key={'ebea4d86-c66c-46e9-8d83-0184fa6b4dcc'} id={'versement'}>
							{selectedSubvention.versement}
						</span>
					) : (
						<select
							key={'29558ff4-555b-46bd-b0e3-94aa78e59771'}
							name="versement"
							id={'versement'}
							data-section={'subGeneralInfos'}
							value={editedSubvention.versement}
							onChange={handleSubVersementChange}
						>
							{versementHTML}
						</select>
					)}
				</div>
				<div className={'sub-general-item sub-etat'}>
					<label
						key={'e1908c1d-c793-452c-8355-552eae75aeb5'}
						htmlFor="sub-etat"
					>
						{contents.labels.etat}
					</label>
					<span key={'1024d36d-ebd8-43df-bc59-0fc6488a3a75'} id={'etat'}>
						{selectedSubvention.etat}
					</span>
				</div>
				<div className={'sub-general-item sub-manager'}>
					<label
						key={'1041ce59-0b6e-4db4-b5f4-a230460c7df5'}
						htmlFor="sub-manager"
					>
						{contents.labels.subventionManager}
					</label>
					{!editable ? (
						<span
							key={'f22132a4-52fc-425e-a576-de7fc175911d'}
							id={'manager'}
						>{`${selectedSubvention?.manager?.first_name} ${selectedSubvention?.manager?.last_name}`}</span>
					) : (
						<select
							key={'ca5857f0-2e97-4f3d-a0ad-a152d144e2af'}
							name="manager"
							id={'manager'}
							data-section={'subGeneralInfos'}
							value={
								editedSubvention?.manager?.id
									? editedSubvention?.manager?.id
									: ''
							}
							onChange={handleInternalMangerChange}
							autoComplete="true"
						>
							{managerHTML}
						</select>
					)}
				</div>
				<div
					key={'ec6428cd-acfa-4e19-93c4-b2d4000ead13'}
					className={'sub-general-item sub-name'}
				>
					<label
						key={'1a98ff9b-371a-4ae4-baaa-c1645f07f598'}
						htmlFor="sub-name"
					>
						{contents.labels.subventionName}
					</label>
					<div key={'e1f2ddc8-983d-4a0c-afcf-a4c736bf1e25'} id={'sub-name'}>
						{selectedSubvention.name}
					</div>
				</div>
				<div
					key={'c5f06e22-18f5-4d92-b6d0-9f28bfdeb8bf'}
					className={'sub-general-item sub-type'}
				>
					<label
						key={'117b1045-3b06-4d33-99ce-4b823e1ce632'}
						htmlFor="sub-type"
					>
						{contents.labels.subventionType}
					</label>
					{!editable ? (
						<span key={'0b9ecaba-3814-4fc7-a76b-e766dcf645fc'} id={'sub-type'}>
							{selectedSubvention?.subventionType?.name}
						</span>
					) : (
						<select
							key={'bdeedf27-9f0e-461d-a710-2a094f0a8d93'}
							name="sub-type"
							id={'subventionType'}
							data-attribute={'name'}
							data-section={'subGeneralInfos'}
							value={
								editedSubvention.subvention_type_id
									? editedSubvention.subvention_type_id
									: 1
							}
							onChange={handleSelectTypeChange}
						>
							{init.Subvention_typeList.map((item, i) => (
								<option key={item.id + i + item.name} value={item.id}>
									{item.name}
								</option>
							))}
						</select>
					)}
				</div>
				<div
					key={'a8de6d6f-ebd2-4fc0-bb9d-ecc4001cde71'}
					className={'sub-general-item sub-funder'}
				>
					<label
						key={'b4139647-759a-42e1-b85a-c47519e2f471'}
						htmlFor="sub-funder"
					>
						{contents.labels.subventionFunder}
					</label>
					<div
						key={'8cffab1f-ddaf-415e-a298-eae90dd0e086'}
						id={'sub-general-item sub-funder'}
					>
						{!editable ? (
							<span key={'f5339c15-bd32-4b96-a5e2-2b002ed2fa18'}>
								{editedSubvention.funder.name}
							</span>
						) : (
							<select
								key={'26d2324b-d39a-4f48-aa1c-f4f0338ccc19'}
								name="sub-funder"
								id="sub-funder"
								data-attribute={'funder'}
								data-section={'subGeneralInfos'}
								value={editedSubvention.funder.id}
								onChange={handleInputChange}
								autoComplete="true"
							>
								{fundersSelectOption}
							</select>
						)}
					</div>
				</div>
				<div
					key={'9f914fac-5f5c-4b54-8851-d5cd10368c8a'}
					className={'sub-general-item sub-ref2'}
				>
					<label
						key={'dc67c234-d4af-4987-8fef-db0e7196741d'}
						htmlFor="sub-ref2"
					>
						{contents.labels.subventionFunderRef}
					</label>
					{!editable ? (
						<span key={'da3b3ba4-cf0b-41d3-b860-c79e10ee06a5'} id={'manager'}>
							{selectedSubvention.funder_ref}
						</span>
					) : (
						<input
							key={'input 118c928b-953c-4a7b-a2b0-17ddef2d8359'}
							type={'text'}
							name="funder_ref"
							id={'funder_ref'}
							data-section={'subGeneralInfos'}
							value={editedSubvention.funder_ref}
							onChange={handleFunderRefChange}
						/>
					)}
				</div>
				<div
					key={'57208e25-0f08-4115-8983-435286b50615'}
					className="sub-general-item accounting-item-accounting_tiers"
				>
					<label
						key={'bb4a91ab-fae5-4c0e-bd89-893ef430cb03'}
						htmlFor="accounting_tiers"
					>
						{contents.labels.tiersAccount}
					</label>
					{!editable ? (
						<span key={'f5339c15-bd32-4b96-a5e2-2b002ed2fa18'}>
							{`${editedSubvention.accounting_tier.code} - ${editedSubvention.accounting_tier.name}`}
						</span>
					) : (
						<>
							<select
								key={'26d2324b-d39a-4f48-aa1c-f4f0338ccc19'}
								className={'accounting-item-accounting_tiers_select'}
								name="accounting_tiers"
								id="accounting_tier"
								data-attribute={'accountingTier'}
								data-section={'subGeneralInfos'}
								value={editedSubvention?.accounting_tier?.id}
								onChange={handleInputChange}
								autoComplete="true"
							>
								{accountingTiersSelectOptions}
							</select>
							{user.admin_right && (
								<ImPlus
									key={'1422c4d2-e893-4c99-921d-c22951a439ef'}
									id={'tiersAccount'}
									onClick={handleClickAddAccount}
									color={'#47C8F4'}
									data-tip={'Ajouter un compte tiers'}
								/>
							)}
						</>
					)}
				</div>
				<div
					key={'a20e5289-2b42-4fb0-b459-08a757953bc6'}
					className="sub-general-item accounting-item-accounting_product"
				>
					<label
						key={'943ac7fc-949e-4a94-aba9-86d6449215d6'}
						htmlFor="accounting_product"
					>
						{contents.labels.productAccount}
					</label>
					{!editable ? (
						<span key={'7dde0eac-3419-4eb1-86c8-9461508f2718'}>
							{`${selectedSubvention?.accounting_product?.code} - ${selectedSubvention?.accounting_product?.name}`}
						</span>
					) : (
						<>
							<select
								key={'c2ae3a9d-99c8-4836-bcf0-ba6deb37d797'}
								name="accounting_product"
								id="accounting_product"
								data-attribute={'code'}
								data-section={'subGeneralInfos'}
								disabled={!user.subvention_modify_right}
								value={editedSubvention?.accounting_product?.id}
								onChange={handleInputChange}
								autoComplete="true"
							>
								{init.Accounting_productList.filter((item) => item.isShown).map(
									(item, i) => (
										<option key={item.id + i + item.code} value={item.id}>
											{`${item.code} - ${item.name}`}
										</option>
									)
								)}
							</select>
							{user.admin_right && (
								<ImPlus
									key={'2976555e-482e-4c5f-8fd7-05d2cbeec8b6'}
									id={'productAccount'}
									onClick={handleClickAddAccount}
									color={'#47C8F4'}
									data-tip={'Ajouter un compte produit'}
								/>
							)}
						</>
					)}
				</div>
				<div
					key={'72c86323-36f6-4ffe-a8ee-bc193ea4e60c'}
					className={'sub-general-item sub-start_date'}
				>
					<label
						key={'0a2d1aa0-408b-4ce7-a42f-5228c7e91d77'}
						htmlFor="sub-start_date"
					>
						{contents.labels.from}
					</label>
					{!editable ? (
						<span
							key={'aeaba807-077f-4fb3-b5a0-4048f1cc15cc'}
							id={'start_date'}
						>
							{selectedSubvention.start_date}
						</span>
					) : (
						<input
							id={'startDate'}
							type="date"
							onChange={handleStartDate}
							value={editedSubvention.start_date}
						/>
					)}
				</div>
				<div
					key={'0fd701ce-1162-4750-8579-0ce2aaffe979'}
					className={'sub-general-item sub-end_date'}
				>
					<label
						key={'02f3443a-df60-4975-a2aa-5e01a7d83c2e'}
						htmlFor="sub-end_date"
					>
						{contents.labels.to}
					</label>
					{!editable ? (
						<span key={'5d691041-9689-4e47-8f26-45cb7d6dc4c7'} id={'end_date'}>
							{selectedSubvention.end_date}
						</span>
					) : (
						<input
							id={'endDate'}
							type="date"
							onChange={handleEndDate}
							min={editedSubvention.start_date}
							value={editedSubvention.end_date}
						/>
					)}
				</div>
				<div
					key={'a7795e19-318c-4a40-a940-952de1b75094'}
					className={'sub-general-item sub-duration'}
				>
					<label
						key={'ba77787b-1a96-4a75-8084-be94acfb4173'}
						htmlFor="sub-duration"
					>
						{contents.labels.duration2}
					</label>
					<div
						key={'1126088f-d22b-47e2-a501-571f2a4c07ad'}
						className={'duration-content'}
					>
						<div
							key={'1dcddfb9-807c-47e6-abc2-88e4e5ae5bcc'}
							id={'sub-duration'}
							className={'duration-number'}
						>
							{editedSubvention?.duration}
						</div>
						<div
							key={'4c4852a9-c9b2-4f93-a975-6d75d1b9727d'}
							id={'sub-duration'}
							className={'duration-type'}
						>
							{contents.labels.durationType}
						</div>
					</div>
				</div>
				<div
					key={'60bbfb10-36a6-4c32-a2b0-f308114b7937'}
					className={'sub-general-item sub-N_duration'}
				>
					<label
						key={'32bca520-a825-430a-8e91-26d4b3de0959'}
						htmlFor="sub-N_duration"
					>
						{contents.labels.nDuration}
					</label>
					<div
						key={'1113ac1c-1c4f-4f3b-884f-755177d35002'}
						className={'duration-content'}
					>
						<div
							key={'3500ec65-a45c-4378-a899-c14cea13077c'}
							id={'sub-N_duration duration-number'}
							className={'duration-number'}
						>
							{editedSubvention.n_duration}
						</div>
						<div
							key={'b61022ef-062f-46ed-b081-6a67a712119d'}
							id={'sub-duration duration-typer'}
							className={'duration-type'}
						>
							{contents.labels.durationType}
						</div>
					</div>
				</div>

				<div
					key={'efe030c4-f9f7-49ec-942d-bf4b8e4d3364'}
					className={'sub-general-item sub-description'}
				>
					<label
						key={'02f3443a-df60-4975-a2aa-5e01a7d83c2e'}
						htmlFor="sub-description"
					>
						Description :
					</label>
					{!editable ? (
						<span
							key={'5d691041-9689-4e47-8f26-45cb7d6dc4c7'}
							id={'description'}
						>
							{selectedSubvention.description}
						</span>
					) : (
						<textarea
							id={'description'}
							onChange={handleInternalRefChange}
							rows={4}
							value={editedSubvention.description}
						/>
					)}
				</div>
			</section>
			{editedSection.subGeneralInfos && (
				<Buttons
					key={'adde7a0c-9a95-47d2-8fbd-93e525586a11'}
					customCSS={'editButtons'}
					handleSubmit={handleEditSubmit}
					handleCancel={editCancel}
				/>
			)}
		</div>
	);
};

export default SubventionGeneralInfos;
