/**
 * Import modules
 */
import React from 'react';
import ButtonLoading from '../ButtonLoading/ButtonLoading';

/**
 * Import components
 */

/**
 * Import locals
 */
import './Buttons.scss';
import contents from '../../contents/contents.json';

const Buttons = ({
	customCSS = '',
	handleCancel,
	handleSubmit = null,
	submitTitle = contents.buttons.save,
	cancelTitle = contents.buttons.cancel,
	isDisabled = false,
}) => {
	return (
		<div
			className={`Buttons ${customCSS}`}
		>
			<button
				className={'cancel'}
				type={'button'}
				onClick={handleCancel}
			>
				{cancelTitle}
			</button >

			{handleSubmit ? (
				<ButtonLoading
					title={submitTitle}
					type={'button'}
					onClick={handleSubmit}
					disabling={isDisabled}
				/>
			) : (
				<ButtonLoading
					title={submitTitle}
					classes="submit"
					disabling={isDisabled}
				/>
			)}
		</div >
	);
};

export default Buttons;
