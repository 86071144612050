/**
 * Import modules
 */
import { Route, Switch, useHistory } from 'react-router-dom';
import { IoPersonSharp } from 'react-icons/io5';
import React from 'react';

/**
 * Import components
 */
import Totaux from '../Totaux/Totaux';

/**
 * Import context
 */
import { useKeyContext } from '../../contexts/keyContext';
import { useUserContext } from '../../contexts/userContext';

/**
 * Import locals
 */
import './Header.scss';
import { logout } from '../../requests/userRequests';
import contents from '../../contents/contents.json';

const Header = () => {
	const { handleClickHome } = useKeyContext();
	const { user, setUser, initSetter, initAdminSetter, setUsers } =
		useUserContext();

	const history = useHistory();

	const handleLoggout = async () => {
		const result = await logout();

		if (result.status !== 200) {
			console.log('[Header.jsx]{L:37} result: ', result);
		} else {
			setUser({});
			history.push('/login');
			initSetter({
				Subvention_typeList: [],
				managers: [],
				FunderList: [],
				Accounting_productList: [],
				Accounting_tiersList: [],
				recievers: [],
				projects: [],
			});
			initAdminSetter({});
			setUsers([]);
		}
	};

	return (
		<div
			className={'Header'}
		>
			<div
				className={'logo'}
			>
				<img
					src='/LogoSofiaFonce-sm.webp'
					alt="Sofia's logo"
					onClick={handleClickHome}
				/>
			</div >
			<div
				className='container'
			>
				<Switch >
					<Route
						path='/subventions/'
					>
						<Totaux />
					</Route >
					<Route
						path='/dashboard'
					>
						<Totaux />
					</Route >
				</Switch >
			</div >

			<div
				className='user-infos'
			>
				{user.id && (
					<>
						<div
							id={'fName'}
							className={'user-firstname'}
						>
							{user ? user.first_name : ''}
						</div >
						<div
							id={'lName'}
							className={'user-lastname'}
						>
							{user ? user.last_name : ''}
						</div >
						<IoPersonSharp
							id={'iconUser'}
							size={64}
							color={'#037172'}
						/>
						<a
							className='loggout'
							onClick={handleLoggout}
						>
							{contents.links.logout}
						</a >
					</>
				)}
			</div >
		</div >
	);
};

export default Header;
