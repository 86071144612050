/**
 * Import modules
 */
import React from 'react';

/**
 * Import components
 */
import Message from '../Message/Message';

/**
 * Import contexts
 */
import { useUserContext } from '../../contexts/userContext';
import { useProjectContext } from '../../contexts/projectContext';
import DeleteProjectModal from './modals/DeleteProjectModal';

/**
 * Import locals
 */
import './ProjectDangerZone.scss';
import contents from '../../contents/contents.json';

const ProjectDangerZone = () => {
	const { user } = useUserContext();
	const {
		selectedProject,
		showDeleteProjectModal,
		switchShowDeleteProjectModal,
	} = useProjectContext();
	return (
		<div
			className={'ProjectDangerZone'}
		>
			<section
				className={'project-elem project-danger-zone'}
			>
				{selectedProject?.subventions?.length === 0 ? (
					<div
						className={'del-project'}
					>
						<label
							className={'delete-sub'}
							htmlFor="delete-sub"
						>
							{contents.labels.deleteProject}
						</label >
						<input
							key={'84eacdb1-434a-45aa-b59c-94075a8d8af7'}
							type={'checkbox'}
							id={'delete-sub'}
							checked={showDeleteProjectModal}
							disabled={user.roleId === 2}
							onChange={switchShowDeleteProjectModal}
						/>
					</div >
				) : (
					<Message
						status={'advert'}
						message={contents.messages.deleteProject}
					/>
				)}
			</section >
			<DeleteProjectModal />
		</div >
	);
};

export default ProjectDangerZone;
