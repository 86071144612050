/**
 * Import modules
 */
import { BsThreeDotsVertical } from 'react-icons/bs';
import React, { useState } from 'react';

/**
 * Import components
 */
import AssociationDetailModal from './Modals/AssociationDetailModal';

/**
 * Import locals
 */
import './AssociationDetail.scss';

const baseCss = {
	line: '',
	role: { title: '' },
	asso: '',
	firstname: '',
	lastname: '',
	email: '',
	status: '',
	readRight: '',
	editRight: '',
	dlRight: '',
	extra: '',
};

const AssociationDetail = ({
	item,
	index,
	title = false,
	customCss = baseCss,
	isActive,
	setEdited,
}) => {
	const [editMode, setEditMode] = useState(false);

	const handleThreeDotClick = () => {
		setEditMode(true);
	};

	customCss.line2 = isActive ? `` : 'inactive';

	return (
		<th
			key={item.id + '_' + index}
			className={`AssociationDetail ${customCss.line} ${customCss.line2}`}
		>
			<tr
				className={'associationList-lastname'}
			>{item.name}</tr >
			<tr
				className={'associationList-firstname'}
			>{item.address}</tr >
			<tr
				className={'associationList-zipcode'}
			>{item.zipcode}</tr >
			<tr
				className={'associationList-city'}
			>{item.city}</tr >
			<tr
				className={'usersList-extra'}
			>
				{title ? (
					<></>
				) : (
					<BsThreeDotsVertical
						id={`dot-${index}`}
						data-index={index}
						onClick={handleThreeDotClick}
					/>
				)}
			</tr >
			<AssociationDetailModal
				item={item}
				setEdited={setEdited}
				editMode={editMode}
				setEditMode={setEditMode}
			/>
		</th >
	);
};

export default AssociationDetail;
