/**
 * Import modules
 */
import React, { useEffect, useState } from 'react';
import { GoDesktopDownload, GoPencil } from 'react-icons/go';
import { ImPlus } from 'react-icons/im';
import { IoClose } from 'react-icons/io5';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import Buttons from '../Buttons/Buttons';
import moment from 'moment';

/**
 * Import components
 */
import DocumentViewerModal from '../SubventionDetail/modals/DocumentViewerModal';
import AddDocumentModal from './modals/AddDocumentModal';

/**
 * Import Contexts
 */
import { useUserContext } from '../../contexts/userContext';
import { useSubventionContext } from '../../contexts/subventionContext';
import { useProjectContext } from '../../contexts/projectContext';

/**
 * Import locals
 */
import './ProjectDocuments.scss';
import config from '../../config.json';
import contents from '../../contents/contents.json';
import { downloadFiles, previewFile } from '../../requests/filesRequest';

const { blobAddress } = config;

export const ProjectDocuments = ({ handleEditSubmit }) => {
	const { user } = useUserContext();
	const { editedSection, editedProject, editCancel } = useProjectContext();

	const [showRemoveDoc, setShowRemoveDoc] = useState(false);

	const handleShowRemovedoc = async () => {
		setShowRemoveDoc(!showRemoveDoc);
	};

	return (
		<div className="ProjectDocuments">
			<section className={'sub-expense-infos'}>
				<h4 key={'7240b173-8f8c-4409-b108-c1957b980921'}>
					{contents.titles.SubventionsDocuments}
				</h4>

				<DocumentsButtons
					project={editedProject}
					user={user}
					handleRemoveDoc={handleShowRemovedoc}
				/>

				<ul className={'document-list'}>
					{editedProject.documents?.length > 0 &&
						editedProject.documents.map((file, i) => (
							<DocumentCard
								key={i}
								file={file}
								index={i}
								showRemoveDoc={showRemoveDoc}
							/>
						))}
				</ul>
			</section>
			{editedSection.subDocuments && (
				<Buttons
					key={
						'93bd9634-f544-46d1-b0b1-87f7b3ede49c3ac5f35e-cc2a-4118-b814-6a916f4f76c7'
					}
					customCSS={'editButtons'}
					handleSubmit={handleEditSubmit}
					handleCancel={editCancel}
				/>
			)}
		</div>
	);
};

const DocumentsButtons = ({ user, project, handleRemoveDoc }) => {
	const { switchShowAddDocumentModal } = useSubventionContext();

	const handleDownloadClick = async (evt) => {
		evt.preventDefault();

		const res = await downloadFiles({
			type: 'project',
			project: project.id,
			assos: project.association_id,
			detail: false,
		});

		const url = `${res.data.data.dataUrl}`;
		const link = document.createElement('a');
		link.href = url;
		link.id = 'dl';
		link.setAttribute('download', `Documents_de_${res.data.data.zipName}`);
		document.body.appendChild(link);
		link.click();

		const dlLink = document.getElementById('dl');
		dlLink.parentElement.removeChild(dlLink);
	};

	return (
		<div className={'document_button'}>
			{user.add_documents_right &&
				!(project.etat === 'Cloturée' || project.counted) && (
					<>
						<ImPlus
							className={'add-cross'}
							key={'12cc97b5-9f56-4783-98f1-afc0874f45f5'}
							onClick={switchShowAddDocumentModal}
							id={'documents'}
							data-tip={contents.toolTipDatas.addDocuments}
						/>
						<ReactTooltip
							place={'top'}
							effect={'solid'}
							className={'tooltipCustom subventionDocumentTooltip test'}
							multiline={true}
						/>
					</>
				)}
			{user.subvention_modify_right &&
				!(project.etat === 'Cloturée' || project.counted) &&
				project.documents?.length > 0 && (
					<>
						<GoPencil
							className={'edit-pencil'}
							key={'d8057f5c-96cc-4e85-9bf8-41bb4eafd8d2'}
							size={22}
							onClick={handleRemoveDoc}
							data-tip={contents.toolTipDatas.editDocuments}
						/>
						<ReactTooltip
							place={'top'}
							effect={'solid'}
							className={'tooltipCustom subventionDocumentTooltip test'}
							multiline={true}
						/>
					</>
				)}

			{user.subvention_extract_right && project.documents?.length > 0 && (
				<>
					<GoDesktopDownload
						className={'download-files'}
						key={'2e2556cf-5853-42a6-9521-ce52d56b0156'}
						onClick={handleDownloadClick}
						size={22}
						color={'#47C8F4'}
						data-tip={contents.toolTipDatas.downloadDocuments}
					/>
					<ReactTooltip
						place={'top'}
						effect={'solid'}
						className={'tooltipCustom subventionDocumentTooltip test'}
						multiline={true}
					/>
				</>
			)}
			<AddDocumentModal />
		</div>
	);
};

const DocumentCard = ({ file, index, showRemoveDoc }) => {
	const { showFile, setShowFile, fileToDelete, setFileToDelete } =
		useSubventionContext();
	const { editedProject, editedSection, setEditedSection } =
		useProjectContext();
	const handleShowFile = async (evt) => {
		const { name, url, type } = evt.target.dataset;

		if (type === 'xlsx' || type === 'xls') {
			const url2 = url.replaceAll(' ', '%20');
			const builtUrl = `${blobAddress}/${url2}`;

			const link = document.createElement('a');
			link.href = builtUrl;
			link.id = 'dl';
			document.body.appendChild(link);
			link.click();

			const dlLink = document.getElementById('dl');
			dlLink.parentElement.removeChild(dlLink);
		} else {
			await setShowFile({ show: true, url, name });
		}
	};

	const handleClickRemoveDoc = async (evt) => {
		let { index, section } = evt.target.dataset;
		if (evt.target.localName === 'path') {
			index = evt.target.parentNode.dataset.index;
			section = evt.target.parentNode.dataset.section;
		}
		const newList = [...editedProject.documents];

		const docToDel = editedProject.documents[index];
		docToDel.del = true;

		newList[index] = docToDel;
		setFileToDelete([...fileToDelete, docToDel.id]);
		setEditedSection({ ...editedSection, [section]: true });
	};

	useEffect(() => {
		(async () => {
			if (showFile.show) {
				setShowFile({ ...showFile, loading: true });
				const extTemp = showFile.url.split('.');
				const type = extTemp[extTemp.length - 1];

				if (type !== 'pdf') {
					const res = await previewFile({
						fileName: showFile.url,
					});
					setShowFile({
						...showFile,
						builtUrl: res.data,
						loading: false,
						type,
					});
				} else {
					setShowFile({
						...showFile,
						builtUrl: `${blobAddress}/${showFile.url}`,
						loading: false,
						type,
					});
				}
			}
		})();
	}, [showFile.show]);

	return (
		<li className={'document-card'}>
			<div className={`DocumentCard ${file.del ? 'toDel' : ''}`}>
				{moment(file.date).format('DD/MM/YYYY')}
			</div>
			<span className={`uploader`}>
				{file.uploader
					? `${file.uploader.first_name} ${file.uploader.last_name}`
					: ''}
			</span>

			<button
				className={file.del ? 'toDel' : ''}
				data-url={`${file.url}`}
				data-name={file.url.split('/').pop().split('.')[0]}
				data-type={file.url.split('/').pop().split('.')[1]}
				onClick={handleShowFile}
			>
				{file.url.split('/').pop().split('.')[0]}
			</button>
			{showRemoveDoc && (
				<>
					<IoClose
						key={
							file.creation_date +
							index +
							'8ccd1bb1-5cde-4c1b-8890-688a20429c5d'
						}
						data-id={file.id}
						data-index={index}
						size={30}
						data-section={'subDocuments'}
						onClick={handleClickRemoveDoc}
						data-tip={contents.toolTipDatas.removeFile}
					/>
					<ReactTooltip
						place={'top'}
						effect={'solid'}
						className={'tooltipCustom subventionDocumentTooltip test'}
						multiline={true}
					/>
				</>
			)}
			<DocumentViewerModal />
		</li>
	);
};
