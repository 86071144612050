/**
 * Import modules
 */
import React from 'react';
import { useWebSiteContext } from '../../../../../contexts/webSiteContext';

import './FunderCard.scss';

export const FunderCard = ({ funder }) => {
	const {
		selectedItem,
		actions: { setSelectedItem },
	} = useWebSiteContext();

	const handleClick = async () => {
		setSelectedItem(funder);
	};
	return (
		<li
			className={`FunderCard ${
				selectedItem.id === funder.id && 'FunderCard__selected'
			}`}
			onClick={handleClick}
		>
			<span className={` ${selectedItem.id === funder.id && 'selected'}`}>
				{funder.name}
			</span>
		</li>
	);
};
