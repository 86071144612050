import React, {
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';

// context creation
export const formContext = createContext(false);

// context hook
export const useSubventionContext = () => {
	const context = useContext(formContext);
	if (!context) {
		console.error(
			'SubventionContext should not be undefined. Did you forget to wrap your component with SubventionContextProvider ?'
		);
	}
	return context;
};

const initialSelectedSubvention = {
	id: null,
	name: '',
	ref: '',
	internal_ref: '',
	description: '',
	funder_ref: null,
	start_date: '',
	end_date: '',
	status: '',
	versement: '',
	etat: '',
	duration: null,
	N_duration: null,
	amount_asked: null,
	amount_contracted: null,
	total_recieved: null,
	remains: null,
	give_back: null,
	payments: [],
	documents: [],
	counted: false,
	expense_receipts: {
		date: '',
		time_limit: 1,
		time_type: 'mois',
	},
	balance_submission: {
		date: '',
		time_limit: 1,
		time_type: 'mois',
	},
	funder: { association_id: null, id: null, name: '' },
	accounting_product: {
		association_id: null,
		code: null,
		id: null,
		isShown: true,
	},
	accounting_tiers: { code: null, id: null, name: null, isShown: true },
	subventionType: { id: 2, name: 'Investissement' },
	association: { address: '', id: null, name: '' },
	projects: [],
	manager: { first_name: '', id: 64, last_name: '' },
	recievers: [],
};

const initialEditedSection = {
	subGeneralInfos: false,
	subFinancialInfos: false,
	subAccountingInfos: false,
	subDocuments: false,
	subReminder: false,
	subDangerZone: false,
	web_sites: false,
};

const initialNewPayment = {
	amount: null,
	date: null,
};

const initialShowFile = {
	show: false,
	url: null,
	loading: false,
};

const initialDuplicate = {
	ref: 'Choisir une date de début',
	versement: 'Non versée',
	documents: [],
	payments: [],
	funder_ref: '',
	counted: false,
	give_back: 0,
	total_recieved: 0,
	start_date: '',
	end_date: '',
	duration: '',
	n_duration: '',
	expense_reciepts_date: '',
	balance_submission_date: '',
	etat: 'En attente',
};

// provider creation
export const SubventionContextProvider = ({ children }) => {
	const [subventionList, setSubvention] = useState([]);
	const [reload, setReload] = useState(false);
	const [selectedSubvention, setSelectedSubvention] = useState(
		initialSelectedSubvention
	);
	const [editedSubvention, setEditedSubvention] = useState(
		initialSelectedSubvention
	);
	const [editedSection, setEditedSection] = useState(initialEditedSection);

	const [newPayment, setNewPayment] = useState(initialNewPayment);

	const subventionListSetter = (value) => {
		setSubvention(value);
	};

	const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
	const cancelNewPayment = () => {
		setNewPayment(initialNewPayment);
		setShowAddPaymentModal(false);
	};

	const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);
	const [fileDeleteErrorMessage, setFileDeleteErrorMessage] = useState(null);
	const switchShowAddDocumentModal = () =>
		setShowAddDocumentModal(!showAddDocumentModal);
	const [showRemoveDoc, setShowRemoveDoc] = useState(false);
	const [showFile, setShowFile] = useState(initialShowFile);
	const [fileToDelete, setFileToDelete] = useState([]);
	const [newDocumentsList, setNewDocumentsList] = useState([]);
	const inputFile = useRef(null);

	const [showCloseSubventionModal, setShowCloseSubventionModal] =
		useState(false);
	const switchShowCloseSubventionModal = () =>
		setShowCloseSubventionModal(!showCloseSubventionModal);

	const [showDeleteSubventionModal, setShowDeleteSubventionModal] =
		useState(false);
	const switchShowDeleteSubventionModal = () =>
		setShowDeleteSubventionModal(!showDeleteSubventionModal);

	const [duplicate, setDuplicate] = useState(false);
	const [duplicateSubvention, setDuplicateSubvention] = useState({});

	const [duplicatedSubvention, setDuplicatedSubvention] = useState({});

	const [isNewDuplicateValid, setIsNewDuplicateValid] = useState(false);

	const [editSelectSite, setEditSelectSite] = useState(false);

	const [fileUploadError, setFileUploadError] = useState(['']);

	const [message, setMessage] = useState('');

	const editCancel = () => {
		const selectedSubventionUpdated = {
			...selectedSubvention,
			documents: selectedSubvention.documents
				? selectedSubvention.documents.map((doc) => {
						return { ...doc, del: false };
				  })
				: [],
		};

		setEditedSection(initialEditedSection);
		setEditedSubvention(selectedSubventionUpdated);
		setSelectedSubvention(selectedSubventionUpdated);
		setNewPayment(initialNewPayment);
		setShowAddPaymentModal(false);
		setShowAddDocumentModal(false);
		setFileDeleteErrorMessage(null);
		setEditSelectSite(false);
		setFileUploadError(['']);
		setNewDocumentsList([]);
		setMessage('');

		setDuplicatedSubvention({
			...selectedSubvention,
			...initialDuplicate,
			remains: selectedSubvention.amount_contracted,
		});
	};

	const resetSection = () => {
		setEditedSection(initialEditedSection);
	};

	useEffect(() => {
		if (selectedSubvention.id) {
			setDuplicatedSubvention({
				...selectedSubvention,
				...initialDuplicate,
				remains: selectedSubvention.amount_contracted,
			});
		}
	}, [selectedSubvention]);

	useEffect(() => {
		setIsNewDuplicateValid(
			duplicatedSubvention.start_date !== '' &&
				duplicatedSubvention.end_date !== '' &&
				duplicatedSubvention.name !== '' &&
				duplicatedSubvention.amount_asked !== '' &&
				duplicatedSubvention.amount_contracted !== ''
		);
	}, [duplicatedSubvention]);

	let value = {
		subventionList,
		reload,
		subventionListSetter,
		setReload,
		selectedSubvention,
		setSelectedSubvention,
		editedSubvention,
		setEditedSubvention,
		editedSection,
		setEditedSection,
		editCancel,
		showAddPaymentModal,
		setShowAddPaymentModal,
		newPayment,
		setNewPayment,
		cancelNewPayment,
		showAddDocumentModal,
		setShowAddDocumentModal,
		switchShowAddDocumentModal,
		fileDeleteErrorMessage,
		setFileDeleteErrorMessage,
		inputFile,
		resetSection,
		showRemoveDoc,
		setShowRemoveDoc,
		showFile,
		setShowFile,
		fileToDelete,
		setFileToDelete,
		newDocumentsList,
		setNewDocumentsList,
		showCloseSubventionModal,
		switchShowCloseSubventionModal,
		showDeleteSubventionModal,
		switchShowDeleteSubventionModal,
		duplicate,
		setDuplicate,
		duplicateSubvention,
		setDuplicateSubvention,
		duplicatedSubvention,
		isNewDuplicateValid,
		editSelectSite,
		fileUploadError,
		message,
		actions: {
			setDuplicatedSubvention,
			editCancel,
			setFileUploadError,
			setMessage,
			setEditSelectSite,
		},
	};

	return <formContext.Provider value={value}>{children}</formContext.Provider>;
};
