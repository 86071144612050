/**
 * Import modules
 */
import React, { useEffect, useState } from 'react';
import { ImPlus } from 'react-icons/im';
import { Tooltip as ReactTooltip } from 'react-tooltip';

/**
 * Import components
 */
import AccountList from '../AccountList';
import AccountForm from '../AccountForm';
import AccountAddProductModal from './modals/AccountAddProductModal';

/**
 * Import Contexts
 */
import { useUserContext } from '../../../../../contexts/userContext';
import { useManagerAdminContext } from '../../../../../contexts/ManagerAdminContext';

/**
 * Import locals
 */
import './ProductAccountPage.scss';
import { editProductAccount } from '../../../../../requests/accountsRequests';
import contents from '../../../../../contents/contents.json';

const ProductAccountPage = () => {
	const { init, initSetter } = useUserContext();
	const { selectedItem, switchShowModal } = useManagerAdminContext();

	const [message, setMessage] = useState('');

	const ShowModal = async () => {
		switchShowModal('productAccount');
	};

	const handleSubmitChanges = async (item) => {
		if (!item.id) {
			setMessage(contents.messages.noAccountSelected);
			return false;
		}
		const result = await editProductAccount(item);

		switch (result.status) {
			case 400:
				setMessage(result.message);
				break;
			case 500:
				setMessage(result.message);
				break;

			default:
				initSetter({ ...init, Accounting_productList: result.data });
				return true;
		}
	};

	useEffect(() => {
		setMessage('');
	}, [selectedItem]);

	return (
		<div className={'ProductAccountPage'}>
			<AccountList list={init.Accounting_productList} />
			<AccountForm
				title={contents.titles.productAccount}
				handleSubmitChanges={handleSubmitChanges}
				message={message}
				setMessage={setMessage}
			/>
			<ImPlus
				key={'9a863d9a-53d1-42a8-991b-a38dc2330f6c'}
				onClick={ShowModal}
				size={25}
				color={'#47C8F4'}
				data-tip={contents.toolTipDatas.productAccount}
			/>
			<ReactTooltip
				place={'top'}
				effect={'solid'}
				className={'tooltipCustom productAccountTooltip test'}
				multiline={true}
			/>
			<AccountAddProductModal />
		</div>
	);
};

export default ProductAccountPage;
