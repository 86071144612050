// Import modules
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FaAsterisk } from 'react-icons/fa';
import Switch from 'react-switch';

// Import components
import Buttons from '../Buttons/Buttons';
import Message from '../Message/Message';
import { CollapsibleComponent } from '../CollapsibleComponent/CollapsibleComponent';
import { ContractTypeOption } from './ContractTypeOption';

// Import contexts
import { useUserContext } from '../../contexts/userContext';

// Import Hooks

// Import locals
import './AddUserModal.scss';
import contents from '../../contents/contents.json';
import { createUser } from '../../requests/AdminRequest';
import { createUserContract } from '../../requests/contracsRequests';
import { useLoadingContext } from '../../contexts/loadingContext';

const initialNewUserState = {
	last_name: '',
	first_name: '',
	email: '',
	phone: '',
	roleId: '',
	role_id: '',
	status: 'active',
	subvention_create_right: false,
	subvention_modify_right: false,
	subvention_extract_right: false,
	add_documents_right: true,
	project_create_right: false,
	project_modify_right: false,
	project_extract_right: false,
	all_document_read_right: false,
	admin_right: false,
	association_id: '',
	reciever_id: '',
};

const initialValidUserState = {
	last_name: false,
	first_name: false,
	email: false,
	phone: false,
	roleId: false,
	role_id: false,
	contract_type_id: true,
	start_date: false,
	hourly_rate: false,
	monthly_hours: false,
};

const initUserContractState = {
	user_id: '',
	contract_type_id: 1,
	start_date: '',
	end_date: '',
	hourly_rate: '',
	monthly_hours: '',
};

export const AddUserModal = ({ show, onHide, setShowAddModal, setEdited }) => {
	const {
		loading,
		actions: { setter },
	} = useLoadingContext();
	const { user, init } = useUserContext();
	const [newUser, setNewUser] = useState(initialNewUserState);
	const [userContract, setUserContract] = useState(initUserContractState);

	const [isNewUserValid, setIsNewUserValid] = useState(initialValidUserState);
	const [errorMessage, setErrorMessage] = useState(null);

	const handleInputChange = async (evt) => {
		const editUser = { ...newUser };
		const { id, value } = evt.target;
		setErrorMessage(null);

		switch (id) {
			case 'roleId':
				editUser[id] = parseInt(value);
				editUser['role_id'] = parseInt(value);
				if (value === '3') {
					editUser.association_id = '';
				}
				if (isNaN(value)) {
					editUser[id] = '';
					editUser['role_id'] = '';
				}
				break;
			case 'phone':
				editUser[id] = value.replace(
					/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
					`$1 $2 $3 $4 $5`
				);
				break;

			default:
				editUser[id] = value;
		}

		if (editUser[id] !== '') {
			const validity = { ...isNewUserValid, [id]: true };
			if (id === 'roleId') {
				validity.role_id = true;
			}
			setIsNewUserValid(validity);
		} else {
			const validity = { ...isNewUserValid, [id]: false };
			if (id === 'roleId') {
				validity.role_id = false;
			}
			setIsNewUserValid(validity);
		}

		setNewUser({ ...editUser, association_id: user.assos[0].id });
	};

	const handleSubmit = async (evt) => {
		evt.preventDefault();

		setter(true, true);

		const testValidity = Object.values(isNewUserValid).indexOf(false);

		if (testValidity >= 0) {
			let property;
			switch (Object.entries(isNewUserValid)[testValidity][0]) {
				case 'last_name':
					property = 'Le nom';
					break;
				case 'first_name':
					property = 'Le prénom';
					break;
				case 'email':
					property = "L'email";
					break;
				case 'phone':
					property = 'Le téléphone';
					break;
				case 'roleId':
					property = 'Le role';
					break;
				case 'association_id':
					property = "L'association";
					break;

				default:
					property = Object.entries(isNewUserValid)[testValidity][0];
			}

			setErrorMessage(`${property} ${contents.messages.propertyError}`);
		}

		if (testValidity === -1) {
			const res = await createUser(newUser);
			let result;
			if (res?.status === 200) {
				const contract = {
					...userContract,
					hourly_rate: userContract.hourly_rate.replace(',', '.'),
					monthly_hours: userContract.monthly_hours.replace(',', '.'),
				};
				result = await createUserContract({
					...contract,
					user_id: res.data.id,
				});
			}

			switch (res.status) {
				case 409:
					setErrorMessage(contents.messages.duplicateMailError);
					break;

				case 404:
					history.push('/404');
					break;

				default:
					setErrorMessage(contents.messages.duplicateMailError);
					break;
			}

			switch (result.status) {
				case 409:
					setErrorMessage(contents.messages.duplicateMailError);
					break;

				case 404:
					history.push('/404');
					break;

				case 200:
					await setNewUser(initialNewUserState);
					await setIsNewUserValid(initialValidUserState);
					await setUserContract(initUserContractState);
					await setErrorMessage(null);
					setEdited(true);
					await setShowAddModal(false);
					break;

				default:
					setErrorMessage(contents.messages.duplicateMailError);
					break;
			}
		}
		setter(false, true);
	};

	const handleClickCancel = async (evt) => {
		evt.preventDefault();
		setter(false, true);
		await setShowAddModal(!show);
		await setNewUser(initialNewUserState);

		await setIsNewUserValid(initialValidUserState);

		await setErrorMessage(null);
	};

	const handleSwitchChange = async (value, evt, id) => {
		const editUser = { ...newUser };
		editUser[id] = value;
		setNewUser(editUser);
	};

	const handleContractInputChange = async (evt) => {
		const editContract = { ...userContract };
		const { id, value } = evt.target;
		setErrorMessage(null);

		switch (id) {
			case 'contract_type_id':
				editContract[id] = parseInt(value);
				break;
			case 'start_date':
				editContract[id] = value;
				break;
			case 'end_date':
				editContract[id] = value;
				break;
			case 'hourly_rate':
				if (/^[\d]*[,.]?\d*?$/.test(value)) {
					editContract[id] = value.replace('.', ',');
				}
				break;
			case 'monthly_hours':
				if (/^[\d]*[,.]?\d*?$/.test(value)) {
					editContract[id] = value.replace('.', ',');
				}
				break;

			default:
				editContract[id] = value;
		}
		if (editContract[id] !== '') {
			const validity = { ...isNewUserValid, [id]: true };
			setIsNewUserValid(validity);
		} else {
			const validity = { ...isNewUserValid, [id]: false };
			setIsNewUserValid(validity);
		}
		setUserContract(editContract);
	};

	return (
		<Modal
			key={'b640e24c-301b-11ec-8d3d-0242ac130003'}
			show={show}
			onHide={onHide}
			size={'xl'}
			aria-labelledby={'modals-content-option'}
			centered
			restoreFocus
			dialogClassName={'add-modals AddUserModal'}
		>
			<Modal.Header closeButton>
				<Modal.Title>
					{contents.titles.BackOfficeModal.user.modalTitle}
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				<Message status={'error'} message={errorMessage} />
				<Form
					key={'bcb3ce41-dd62-4299-8e22-9314a0011e55'}
					onSubmit={handleSubmit}
				>
					<CollapsibleComponent title={`Informations Générale`}>
						<fieldset className={'new-user-infos'}>
							<Form.Group className="mb-3 form-lastName" controlId="last_name">
								<Form.Label>{contents.labels.lastname}</Form.Label>
								<Form.Control
									type="text"
									placeholder={contents.placeholders.lastname}
									onChange={handleInputChange}
									value={newUser.last_name}
								/>
								<FaAsterisk />
							</Form.Group>

							<Form.Group
								className="mb-3 form-firstName"
								controlId="first_name"
							>
								<Form.Label>{contents.labels.firstname}</Form.Label>
								<Form.Control
									type="text"
									placeholder={contents.placeholders.firstname}
									onChange={handleInputChange}
									value={newUser.first_name}
								/>
								<FaAsterisk />
							</Form.Group>

							<Form.Group className="mb-3 form-eMail" controlId="email">
								<Form.Label>{contents.labels.mail}</Form.Label>
								<Form.Control
									type="text"
									placeholder={contents.placeholders.mail}
									onChange={handleInputChange}
									value={newUser.email}
								/>
								<FaAsterisk />
							</Form.Group>

							<Form.Group className="mb-3 form-phone" controlId="phone">
								<Form.Label>{contents.labels.phone}</Form.Label>
								<Form.Control
									type="tel"
									placeholder={contents.placeholders.phone}
									onChange={handleInputChange}
									value={newUser.phone}
								/>
								<FaAsterisk />
							</Form.Group>
						</fieldset>
						<fieldset className={'new-user-group'}>
							<Form.Group className="mb-3 form-role" controlId="roleId">
								<Form.Label>{contents.labels.role}</Form.Label>
								<Form.Select
									type="text"
									placeholder={contents.placeholders.role}
									onChange={handleInputChange}
									value={newUser.roleId}
								>
									<option key={'05af2618-af21-4b7d-a2c8-0961b4df7add'}>
										{contents.placeholders.role}
									</option>
									{init.roleList?.map((role, i) => (
										<RoleOption key={i + role.id} role={role} />
									))}
								</Form.Select>
							</Form.Group>

							{newUser.roleId === 2 ? (
								<Form.Group
									className="mb-3 form-reciever"
									controlId="reciever_id"
								>
									<Form.Label>{contents.labels.receiver}</Form.Label>

									<Form.Select
										type="text"
										placeholder={contents.placeholders.receiver}
										onChange={handleInputChange}
										value={newUser.assosId}
									>
										<option key={'4926a6d9-0617-444b-89ae-9edb3691dcee'}>
											{contents.placeholders.receiver}
										</option>
										{init.receivers?.map((receiver) => (
											<ReceiverOption key={receiver.id} receiver={receiver} />
										))}
									</Form.Select>
								</Form.Group>
							) : (
								<div className="mb-3 form-association" />
							)}
						</fieldset>
					</CollapsibleComponent>

					<fieldset className={'lined'} />
					<CollapsibleComponent title={`Droits`}>
						<fieldset className={'new-user-rights'}>
							<h3>{contents.titles.BackOfficeModal.user.subventions}</h3>
							<Form.Group
								className="mb-3 switchs subvention_create_right"
								controlId="subvention_create_right"
							>
								<Form.Label>
									{contents.labels.subvention_create_right}
								</Form.Label>
								<div>
									<Switch
										id={'subvention_create_right'}
										onChange={handleSwitchChange}
										checked={newUser.subvention_create_right}
									/>
								</div>
							</Form.Group>
							<Form.Group
								className="mb-3 switchs subvention_modify_right"
								controlId="subvention_modify_right"
							>
								<Form.Label>
									{contents.labels.subvention_modify_right}
								</Form.Label>
								<div>
									<Switch
										id={'subvention_modify_right'}
										onChange={handleSwitchChange}
										checked={newUser.subvention_modify_right}
									/>
								</div>
							</Form.Group>

							<Form.Group
								className="mb-3 switchs subvention_extract_right"
								controlId="subvention_extract_right"
							>
								<Form.Label>
									{contents.labels.subvention_extract_right}
								</Form.Label>
								<div>
									<Switch
										id={'subvention_extract_right'}
										onChange={handleSwitchChange}
										checked={newUser.subvention_extract_right}
									/>
								</div>
							</Form.Group>
							<Form.Group
								className="mb-3 switchs add_documents_right"
								controlId="add_documents_right"
							>
								<Form.Label>{contents.labels.add_documents_right}</Form.Label>
								<div>
									<Switch
										id={'add_documents_right'}
										onChange={handleSwitchChange}
										checked={newUser.add_documents_right}
									/>
								</div>
							</Form.Group>
							<Form.Group
								className="mb-3 switchs admin_right"
								controlId="admin_right"
							>
								<Form.Label>{contents.labels.admin_right}</Form.Label>
								<div>
									<Switch
										id={'admin_right'}
										onChange={handleSwitchChange}
										checked={newUser.admin_right}
									/>
								</div>
							</Form.Group>
						</fieldset>

						<fieldset className={'new-user-projects'}>
							<h3>{contents.titles.BackOfficeModal.user.projects}</h3>
							<Form.Group
								className="mb-3 switchs project_create_right"
								controlId="project_create_right"
							>
								<Form.Label>{contents.labels.project_create_right}</Form.Label>
								<div>
									<Switch
										id={'project_create_right'}
										onChange={handleSwitchChange}
										checked={newUser.project_create_right}
									/>
								</div>
							</Form.Group>
							<Form.Group
								className="mb-3 switchs project_modify_right"
								controlId="project_modify_right"
							>
								<Form.Label>{contents.labels.project_modify_right}</Form.Label>
								<div>
									<Switch
										id={'project_modify_right'}
										onChange={handleSwitchChange}
										checked={newUser.project_modify_right}
									/>
								</div>
							</Form.Group>
							<Form.Group
								className="mb-3 switchs project_extract_right"
								controlId="project_extract_right"
							>
								<Form.Label>{contents.labels.project_extract_right}</Form.Label>
								<div>
									<Switch
										id={'project_extract_right'}
										onChange={handleSwitchChange}
										checked={newUser.project_extract_right}
									/>
								</div>
							</Form.Group>
							<Form.Group
								className="mb-3 switchs all_document_read_right"
								controlId="all_document_read_right"
							>
								<Form.Label>
									{contents.labels.all_document_read_right}
								</Form.Label>
								<div>
									<Switch
										id={'all_document_read_right'}
										onChange={handleSwitchChange}
										checked={newUser.all_document_read_right}
									/>
								</div>
							</Form.Group>
							<Form.Group
								className="mb-3 switchs add_documents_right"
								controlId="add_documents_right"
							>
								<Form.Label>{contents.labels.add_documents_right}</Form.Label>
								<div>
									<Switch
										id={'add_documents_right'}
										onChange={handleSwitchChange}
										checked={newUser.add_documents_right}
									/>
								</div>
							</Form.Group>
						</fieldset>
					</CollapsibleComponent>

					<fieldset className={'lined'} />

					<CollapsibleComponent title={`Contrat`}>
						<fieldset className={'new-user-infos'}>
							<Form.Group
								className="mb-3 form-lastName"
								controlId="contract_type_id"
							>
								<Form.Label>{contents.labels.contractType}</Form.Label>

								<Form.Select
									type="text"
									placeholder={contents.placeholders.contractType}
									onChange={handleContractInputChange}
									value={userContract.contract_type_id}
								>
									{init.contractTypes?.map((type, i) => (
										<ContractTypeOption key={i + type.id} contractType={type} />
									))}
								</Form.Select>
								<FaAsterisk />
							</Form.Group>

							<Form.Group
								className="mb-3 form-firstName"
								controlId="start_date"
							>
								<Form.Label>{contents.labels.startDate}</Form.Label>
								<Form.Control
									type="date"
									placeholder={contents.placeholders.startDate}
									onChange={handleContractInputChange}
									value={userContract.start_date}
								/>
								<FaAsterisk />
							</Form.Group>

							<Form.Group className="mb-3 form-firstName" controlId="end_date">
								<Form.Label>{contents.labels.endDate}</Form.Label>
								<Form.Control
									type="date"
									placeholder={contents.placeholders.endDate}
									onChange={handleContractInputChange}
									value={userContract.end_date}
								/>
								<FaAsterisk />
							</Form.Group>

							<Form.Group className="mb-3 form-phone" controlId="hourly_rate">
								<Form.Label>{contents.labels.hourlyRate}</Form.Label>
								<Form.Control
									type="text"
									placeholder={contents.placeholders.hourlyRate}
									onChange={handleContractInputChange}
									value={userContract.hourly_rate}
								/>
							</Form.Group>

							<Form.Group className="mb-3 form-phone" controlId="monthly_hours">
								<Form.Label>{contents.labels.monthlyHours}</Form.Label>
								<Form.Control
									type="text"
									placeholder={contents.placeholders.monthlyHours}
									onChange={handleContractInputChange}
									value={userContract.monthly_hours}
								/>
								<FaAsterisk />
							</Form.Group>
						</fieldset>
					</CollapsibleComponent>

					<fieldset className={'new-user-buttons'}>
						<Buttons
							submitTitle={contents.buttons.save}
							handleCancel={handleClickCancel}
							isDisabled={loading}
						/>
					</fieldset>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

const ReceiverOption = ({ receiver }) => {
	return (
		<option key={receiver.id} value={receiver.id}>
			{receiver.name}
		</option>
	);
};

const RoleOption = ({ role }) => {
	let title;

	switch (role.title) {
		case 'admin':
			title = "Responsable d'association";
			break;
		case 'manager':
			title = 'Responsable de centre';
			break;
		case 'employee':
			title = 'Collaborateur';
			break;
		default:
			title = role.title;
	}

	return (
		<option key={role.id} value={role.id}>
			{title}
		</option>
	);
};
