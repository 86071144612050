/**
 * Import modules
 */
import React from 'react';

/**
 * Import components
 */
import RecieverDetail from '../RecieverDetail/RecieverDetail';

/**
 * Import context
 */

/**
 * Import locals
 */
import './RecieverList.scss';
import contents from '../../contents/contents.json';

const titleCss = {
	line: 'recieversList-title',
	name: 'Nom',
	manager: 'Manager',
	first_name: 'Association',
};

const RecieverList = ({ recievers }) => {
	let tableHTML =
		recievers.length > 0 ? (
			recievers.map((item, i) => {
				return (
					<RecieverDetail
						key={`${i}_${item.id}`}
						item={item}
						index={i}
						isActive={item.status === 'active'}
					/>
				);
			})
		) : (
			<></>
		);
	return (
		<div
			className={'RecieverList'}
		>
			<table >
				<RecieverDetail
					key={'1d651a59-42e4-4fe9-8043-2253d280fdf7'}
					customCss={titleCss}
					item={contents.RecieverList.tableHeader}
					title={true}
					isActive={true}
				/>
				{tableHTML}
			</table >
		</div >
	);
};

export default RecieverList;
