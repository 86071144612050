/**
 * Import modules
 */

/**
 * Import locals
 */
import utility from '../utility';

export const appInit = async () => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq('GET', `${utility.apiURL}init`, token);
		return { ...res.data, status: res.status };
	} catch (error) {
		if (error.message === 'Network Error') {
			return { ...error };
		}
		return { ...error.response.data, status: error.response.status };
	}
};

export const autoLogin = async () => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'GET',
			`${utility.apiURL}user/autologin`,
			token
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		if (error.message === 'Network Error') {
			return { ...error };
		}
		return { ...error.response.data, status: error.response.status };
	}
};

export const login = async (user) => {
	try {
		const res = await utility.axiosReq(
			'POST',
			`${utility.apiURL}user/login/`,
			'',
			{ ...user }
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		if (!error.response) {
			const { message } = error.toJSON();
			switch (message) {
				case 'Network Error':
					return { ...error, message: 'Le serveur ne répond pas', status: 500 };
				// break;
				default:
					return { ...error.toJSON(), status: 500 };
			}
		}
		return { ...error.response.data, status: error.response.status };
	}
};

export const logout = async () => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'DELETE',
			`${utility.apiURL}user/logout`,
			token
		);
		utility.setToken(res.token);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const createResetUrl = async (email) => {
	try {
		const res = await utility.axiosReq(
			'POST',
			`${utility.apiURL}user/createreseturl`,
			'',
			email
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};
export const resetPassword = async (obj) => {
	try {
		const res = await utility.axiosReq(
			'POST',
			`${utility.apiURL}user/resetpassword`,
			'',
			{ ...obj }
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const getAllUsers = async () => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'GET',
			`${utility.apiURL}backoffice/getallusers`,
			token
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		if (error.message === 'Network Error') {
			return { ...error };
		}
		return { ...error.response.data, status: error.response.status };
	}
};
