/**
 * Import modules
 */
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import Message from '../Message/Message';

/**
 * Import components
 */
import Buttons from '../Buttons/Buttons';

/**
 * Import Contexts
 */
import { useBackOfficeContext } from '../../contexts/backOfficeContext';
import { useUserContext } from '../../contexts/userContext';
import { useLoadingContext } from '../../contexts/loadingContext';

/**
 * Import locals
 */
import './RecieverDetail.scss';
import { updateReciever } from '../../requests/AdminRequest';
import contents from '../../contents/contents.json';

const RecieverDetailModal = ({
	editMode,
	setEditMode,
	reciever,
	setReciever,
	customCss,
	isActive,
}) => {
	const { actions } = useLoadingContext();
	const { users, adminInitialisation } = useUserContext();
	const { setEdited } = useBackOfficeContext();
	const [editedReciever, setEditedReciever] = useState(reciever);

	const [errorMessage, setErrorMessage] = useState(null);

	const associations = adminInitialisation.associationList;

	const handleCloseEditModal = async () => {
		await setEditMode(!editMode);
	};

	const handleInputChange = async (evt) => {
		setErrorMessage(null);
		const editReciever = { ...editedReciever };
		let { id, value } = evt.target;
		if (evt.target.localName === 'select') {
			if (id !== 'status') {
				value = parseInt(value);
			}
		}

		editReciever[id] = value;
		setEditedReciever(editReciever);
	};

	const handleClickCancel = async (evt) => {
		evt.preventDefault();
		await setEditMode(!editMode);
		await setEditedReciever(reciever);
	};

	const handleSubmit = async (evt) => {
		evt.preventDefault();
		actions.setter(true, true);

		const res = await updateReciever(editedReciever);

		if (res.status !== 200) {
			setErrorMessage(res?.message);
		} else {
			await setReciever(res.data);
			await setEditedReciever(res.data);
			await setEditMode(false);
			await setEdited(true);
		}
		actions.setter(false, true);
	};

	let managerOptionHTML,
		associationOptionHTML = [];

	if (users) {
		managerOptionHTML = users
			.filter(
				(user) =>
					(user.roleId === 2 && user.recievers.length === 0) ||
					user.id === reciever.user_id
			)
			.map((user, i) => (
				<option
					key={`${editedReciever.id}_${user.id}_${i}`}
					value={user.id}
				>
					{`${user.first_name} ${user.last_name}`}
				</option >
			));
	}
	if (associations) {
		associationOptionHTML = associations.map((assos, i) => (
			<option
				key={`${assos.id}_${i}_${editedReciever.id}`}
				value={assos.id}
			>
				{assos.name}
			</option >
		));
	}

	const statusOptionsHTML = ['active', 'inactive'].map((status, i) => (
		<option
			key={i + status}
			value={status}
		>
			{status === 'active'
				? contents.options.active
				: contents.options.inactive}
		</option >
	));

	customCss.line2 = isActive ? `` : 'inactive';

	return (
		<Modal
			key={'e92d6c57-107c-4606-8a53-3c0e24f44989'}
			show={editMode}
			onHide={handleCloseEditModal}
			size={'xl'}
			aria-labelledby={'modals-content-option'}
			centered
			restoreFocus
			dialogClassName={'edit-association-modals'}
		>
			<Modal.Header >
				<h2 >{contents.titles.RecieverDetailModal}</h2 >
			</Modal.Header >
			<Modal.Body >
				<Message
					status={'error'}
					message={errorMessage}
				/>
				<Form
					onSubmit={handleSubmit}
				>
					<fieldset
						className={'edit-association'}
					>
						<Form.Group
							className="mb-3 form-name"
							controlId="name"
						>
							<Form.Label >{contents.labels.receiverName}</Form.Label >
							<Form.Control
								type="text"
								placeholder="Nom"
								onChange={handleInputChange}
								value={editedReciever.name}
							/>
						</Form.Group >

						<Form.Group
							className="mb-3 reciever-manager"
							controlId="user_id"
						>
							<Form.Label >{contents.labels.manager}</Form.Label >
							<Form.Select
								type="text"
								placeholder="Manager"
								onChange={handleInputChange}
								value={editedReciever.user_id}
							>
								{managerOptionHTML}
							</Form.Select >
						</Form.Group >

						<Form.Group
							className="mb-3 reciever-association"
							controlId="association_id"
						>
							<Form.Label >{contents.labels.receiverAssociation}</Form.Label >
							<Form.Select
								type="text"
								placeholder="Association"
								onChange={handleInputChange}
								value={editedReciever.association_id}
							>
								{associationOptionHTML}
							</Form.Select >
						</Form.Group >

						<Form.Group
							className="mb-3 status"
							controlId="status"
						>
							<Form.Label >{contents.labels.status}</Form.Label >
							<Form.Select
								type="text"
								placeholder="role"
								onChange={handleInputChange}
								value={editedReciever.status}
							>
								{statusOptionsHTML}
							</Form.Select >
						</Form.Group >
					</fieldset >

					<Buttons
						customCSS={'edit-user-buttons'}
						handleCancel={handleClickCancel}
					/>
				</Form >
			</Modal.Body >
		</Modal >
	);
};

export default RecieverDetailModal;
