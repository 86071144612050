/**
 * Import modules
 */
import { NavLink } from 'react-router-dom';
import slugify from 'react-slugify';
import React, { useEffect, useState } from 'react';
import { FaSort } from 'react-icons/fa';
import { VscFiles } from 'react-icons/vsc';
import { NumericFormat } from 'react-number-format';

/**
 * Import components
 */
import { useSubventionContext } from '../../contexts/subventionContext';

/**
 * Import locals
 */
import './PageSubventions.scss';
import { getAllSubventionListByAssociation } from '../../requests/SubventionRequests';
import utility from '../../utility';
import contents from '../../contents/contents.json';

const PageSubventions = ({ utils, filteredList, setFilteredList }) => {
	const { subventionListSetter } = useSubventionContext();

	const [sortingOrder, setSortingOrder] = useState({});

	const handleClickSort = async (evt) => {
		let { dataset } = evt.target;

		if (evt.target.localName === 'path') {
			dataset = evt.target.parentNode.dataset;
		}

		let value;
		sortingOrder[dataset.column] === 'ASC' ? (value = 'DESC') : (value = 'ASC');
		setSortingOrder({ [dataset.column]: value });

		const sortedList = utility.sorter(filteredList, dataset.column, value);
		setFilteredList(sortedList);
	};

	const subventionListHTML = filteredList.map((sub, i) => {
		const funderName = sub.funder !== null ? sub.funder.name : '';
		const CustomClassname = sub.etat === 'Cloturée' ? 'closed' : '';

		return (
			<NavLink
				key={`${i}-${sub.id}`}
				to={`/dashboard/subventions/${slugify(sub.name)}?id=${sub.id}`}
				className={`sub-detail-link`}
			>
				<tr
					key={'b640d9f0-301b-11ec-8d3d-0242ac130003'}
					className={`subvention-item sub-list ${CustomClassname}`}
				>
					<th className="subvention-item-content subvention-ref">
						{sub.ref} {sub?.documents?.length > 0 && <VscFiles />}
					</th>
					<th className="subvention-item-content subvention-name">
						{sub.name}
					</th>
					<th className="subvention-item-content subvention-funder">
						{funderName}
					</th>
					<th className="subvention-item-content subvention-start_date">
						{utility.dateShorter(sub.start_date)}
					</th>
					<th className="subvention-item-content subvention-end_date">
						{utility.dateShorter(sub.end_date)}
					</th>
					<th className="subvention-item-content subvention-status">
						{sub.status}
					</th>
					<th className="subvention-item-content subvention-versement">
						{sub.versement}
					</th>
					<th className="subvention-item-content subvention-etat">
						{sub.etat}
					</th>
					<th className="subvention-item-content subvention-amount_asked">
						<NumericFormat
							value={sub.amount_asked}
							displayType={'text'}
							thousandSeparator={' '}
							suffix={' €'}
							className="totaux-read"
							decimalScale={2}
						/>
					</th>
					<th className="subvention-item-content subvention-amount_contrated subvention-amount_contracted-item">
						<NumericFormat
							value={sub.amount_contracted}
							displayType={'text'}
							thousandSeparator={' '}
							suffix={' €'}
							className="totaux-read"
							decimalScale={2}
						/>
					</th>
					<th className="subvention-item-content subvention-total_recieved">
						<NumericFormat
							value={sub.total_recieved}
							displayType={'text'}
							thousandSeparator={' '}
							suffix={' €'}
							className="totaux-read"
							decimalScale={2}
						/>
					</th>
					<th className="subvention-item-content subvention-remains subvention-remains-item">
						<NumericFormat
							value={sub.remains}
							displayType={'text'}
							thousandSeparator={' '}
							suffix={' €'}
							className="totaux-read"
							decimalScale={2}
						/>
					</th>
				</tr>
			</NavLink>
		);
	});

	/**
	 * Set sortList
	 * Trigger: sortingOrder
	 */
	useEffect(() => {
		const prop = Object.keys(sortingOrder)[0];
		const value = Object.values(sortingOrder)[0];

		const sortedList = utility.sorter(filteredList, prop, value);
		setFilteredList(sortedList);
	}, [sortingOrder]);

	/**
	 * Get subvention List
	 * Trigger: once
	 */
	useEffect(() => {
		(async () => {
			if (utils.assosId) {
				const result = await getAllSubventionListByAssociation({
					assosId: utils.assosId,
					roleId: utils.roleId,
				});

				if (result.data) {
					await subventionListSetter(result.data);
				}
			}
		})();
	}, []);

	return (
		<div className={'PageSubventions'}>
			<table
				key={'b640de96-301b-11ec-8d3d-0242ac130003'}
				className="subventions-container"
			>
				<thead className="subventionList">
					<tr
						key={'b640df68-301b-11ec-8d3d-0242ac130003'}
						className="subvention-item subventionList-title"
					>
						<th className="subvention-item-content subvention-ref title">
							<div>{contents.PageSubventions.tableHeader.ref}</div>
							<FaSort data-column={'ref'} onClick={handleClickSort} />
						</th>
						<th className="subvention-item-content subvention-name title">
							<div>{contents.PageSubventions.tableHeader.name}</div>
							<FaSort data-column={'name'} onClick={handleClickSort} />
						</th>
						<th className="subvention-item-content subvention-funder title">
							<div>{contents.PageSubventions.tableHeader.funder}</div>
							<FaSort data-column={'funder'} onClick={handleClickSort} />
						</th>
						<th className="subvention-item-content subvention-start_date title">
							<div>{contents.PageSubventions.tableHeader.from}</div>
							<FaSort data-column={'start_date'} onClick={handleClickSort} />
						</th>
						<th className="subvention-item-content subvention-end_date title">
							<div>{contents.PageSubventions.tableHeader.to}</div>
							<FaSort data-column={'end_date'} onClick={handleClickSort} />
						</th>
						<th className="subvention-item-content subvention-status title">
							<div>{contents.PageSubventions.tableHeader.status}</div>
							<FaSort data-column={'status'} onClick={handleClickSort} />
						</th>
						<th className="subvention-item-content subvention-versement title">
							<div>{contents.PageSubventions.tableHeader.versement}</div>
							<FaSort data-column={'versement'} onClick={handleClickSort} />
						</th>
						<th className="subvention-item-content subvention-etat title">
							<div>{contents.PageSubventions.tableHeader.etat}</div>
							<FaSort data-column={'etat'} onClick={handleClickSort} />
						</th>
						<th className="subvention-item-content subvention-amount_asked title">
							<div>{contents.PageSubventions.tableHeader.askedAmount}</div>
							<FaSort data-column={'amount_asked'} onClick={handleClickSort} />
						</th>
						<th className="subvention-item-content subvention-amount_contrated title">
							<div>{contents.PageSubventions.tableHeader.contractedAmount}</div>
							<FaSort
								data-column={'amount_contracted'}
								onClick={handleClickSort}
							/>
						</th>
						<th className="subvention-item-content subvention-total_recieved title">
							<div>{contents.PageSubventions.tableHeader.recieved}</div>
							<FaSort
								data-column={'total_recieved'}
								onClick={handleClickSort}
							/>
						</th>
						<th className="subvention-item-content subvention-remains title">
							<div>{contents.PageSubventions.tableHeader.remains}</div>
							<FaSort data-column={'remains'} onClick={handleClickSort} />
						</th>
					</tr>
				</thead>
				<tbody className={'sub-list'}>{subventionListHTML}</tbody>
			</table>
		</div>
	);
};

export default PageSubventions;
