/**
 * Import modules
 */
import React, { useEffect } from 'react';
import { GoDesktopDownload, GoPencil } from 'react-icons/go';
import { ImPlus } from 'react-icons/im';
import { IoClose } from 'react-icons/io5';
import moment from 'moment';
import { Tooltip as ReactTooltip } from 'react-tooltip';

/**
 * Import components
 */
import Buttons from '../Buttons/Buttons';
import AddDocumentModal from './modals/AddDocumentModal';
import DocumentViewerModal from './modals/DocumentViewerModal';

/**
 * Import Contexts
 */
import { useUserContext } from '../../contexts/userContext';
import { useSubventionContext } from '../../contexts/subventionContext';

/**
 * Import locals
 */
import './SubventionsDocuments.scss';
import config from '../../config.json';
import contents from '../../contents/contents.json';
import { downloadFiles, previewFile } from '../../requests/filesRequest';

const { blobAddress } = config;

const SubventionsDocuments = ({ handleEditSubmit }) => {
	const { user } = useUserContext();
	const {
		selectedSubvention,
		editedSubvention,
		editedSection,
		fileDeleteErrorMessage,
		switchShowAddDocumentModal,
		editCancel,
		showRemoveDoc,
		setShowRemoveDoc,
		showFile,
		setShowFile,
	} = useSubventionContext();

	const handleShowRemovedoc = async () => {
		setShowRemoveDoc(!showRemoveDoc);
	};

	const handleDownloadClick = async (evt) => {
		evt.preventDefault();

		const res = await downloadFiles({
			type: 'subvention',
			sub: selectedSubvention.id,
			assos: selectedSubvention.association.id,
			detail: false,
		});

		const url = `${res.data.data.dataUrl}`;
		const link = document.createElement('a');
		link.href = url;
		link.id = 'dl';
		link.setAttribute('download', `Documents_de_${res.data.data.zipName}`);
		document.body.appendChild(link);
		link.click();

		const dlLink = document.getElementById('dl');
		dlLink.parentElement.removeChild(dlLink);
	};

	/**
	 * Get file preview
	 * Trigger: showFile.show
	 */
	useEffect(() => {
		(async () => {
			if (showFile.show) {
				setShowFile({ ...showFile, loading: true });
				const extTemp = showFile.url.split('.');
				const type = extTemp[extTemp.length - 1];

				if (type !== 'pdf') {
					const res = await previewFile({
						sub: selectedSubvention.id,
						assos: selectedSubvention.association.id,
						fileName: showFile.url,
					});
					setShowFile({
						...showFile,
						builtUrl: res.data,
						loading: false,
						type,
					});
				} else {
					setShowFile({
						...showFile,
						builtUrl: `${blobAddress}/${showFile.url}`,
						loading: false,
						type,
					});
				}
			}
		})();
	}, [showFile.show]);

	return (
		<div className={'SubventionsDocuments'}>
			<section className={'sub-expense-infos'}>
				<h4 key={'7240b173-8f8c-4409-b108-c1957b980921'}>
					{contents.titles.SubventionsDocuments}
				</h4>

				<div className={'document_button'}>
					{user.add_documents_right &&
						!(
							editedSubvention.etat === 'Cloturée' || editedSubvention.counted
						) && (
							<>
								<ImPlus
									className={'add-cross'}
									key={'12cc97b5-9f56-4783-98f1-afc0874f45f5'}
									onClick={switchShowAddDocumentModal}
									id={'documents'}
									data-tip={contents.toolTipDatas.addDocuments}
								/>
								<ReactTooltip
									place={'top'}
									effect={'solid'}
									className={'tooltipCustom subventionDocumentTooltip test'}
									multiline={true}
								/>
							</>
						)}
					{user.subvention_modify_right &&
						!(
							editedSubvention.etat === 'Cloturée' || editedSubvention.counted
						) &&
						editedSubvention?.documents?.length > 0 && (
							<>
								<GoPencil
									className={'edit-pencil'}
									key={'d8057f5c-96cc-4e85-9bf8-41bb4eafd8d2'}
									size={22}
									onClick={handleShowRemovedoc}
									data-tip={contents.toolTipDatas.editDocuments}
								/>
								<ReactTooltip
									place={'top'}
									effect={'solid'}
									className={'tooltipCustom subventionDocumentTooltip test'}
									multiline={true}
								/>
							</>
						)}

					{user.subvention_extract_right &&
						editedSubvention?.documents?.length > 0 && (
							<>
								<GoDesktopDownload
									className={'download-files'}
									key={'2e2556cf-5853-42a6-9521-ce52d56b0156'}
									onClick={handleDownloadClick}
									size={22}
									color={'#47C8F4'}
									data-tip={contents.toolTipDatas.downloadDocuments}
								/>
								<ReactTooltip
									place={'top'}
									effect={'solid'}
									className={'tooltipCustom subventionDocumentTooltip test'}
									multiline={true}
								/>
							</>
						)}
				</div>
				<legend>P = Document du projet | S = Document de la subvention</legend>
				<div
					key={'fa607295-e7ea-4929-bf5d-8c4cde9b4a42'}
					className={'delete-error'}
				>
					{fileDeleteErrorMessage ? fileDeleteErrorMessage : ''}
				</div>
				<ul
					key={'4a3e6050-a515-4e1d-8fb7-e617d2df801e'}
					className="documents-list"
				>
					{/*documentsHTML*/}
					<DocumentsList />
				</ul>
			</section>
			{editedSection.subDocuments && (
				<Buttons
					key={
						'93bd9634-f544-46d1-b0b1-87f7b3ede49c3ac5f35e-cc2a-4118-b814-6a916f4f76c7'
					}
					customCSS={'editButtons'}
					handleSubmit={handleEditSubmit}
					handleCancel={editCancel}
				/>
			)}
			<AddDocumentModal />
			<DocumentViewerModal />
		</div>
	);
};

export default SubventionsDocuments;

const DocumentsList = () => {
	const {
		editedSubvention,
		showRemoveDoc,
		setShowFile,
		fileToDelete,
		setFileToDelete,
		editedSection,
		setEditedSection,
	} = useSubventionContext();

	const handleShowFile = async (evt) => {
		const { name, url, type } = evt.target.dataset;

		if (type === 'xlsx' || type === 'xls') {
			const url2 = url.replaceAll(' ', '%20');
			const builtUrl = `${blobAddress}/${url2}`;

			const link = document.createElement('a');
			link.href = builtUrl;
			link.id = 'dl';
			document.body.appendChild(link);
			link.click();

			const dlLink = document.getElementById('dl');
			dlLink.parentElement.removeChild(dlLink);
		} else {
			await setShowFile({ show: true, url, name });
		}
	};

	const handleClickRemoveDoc = async (evt) => {
		let { index, section } = evt.target.dataset;
		if (evt.target.localName === 'path') {
			index = evt.target.parentNode.dataset.index;
			section = evt.target.parentNode.dataset.section;
		}
		const newList = [...editedSubvention.documents];

		const docToDel = editedSubvention.documents[index];
		docToDel.del = true;

		newList[index] = docToDel;
		setFileToDelete([...fileToDelete, docToDel.id]);
		setEditedSection({ ...editedSection, [section]: true });
	};

	useEffect(() => {}, [editedSection.subDocuments]);

	return (
		editedSubvention?.documents?.map((item, i) => {
			if (item.creation_date) {
				if (!moment(item.creation_date).isValid()) {
					const from = item.creation_date.split('-');
					item.creation_date = new Date(from[2], from[1] - 1, from[0]);
				}

				return (
					<li
						key={
							item.creation_date + i + 'b4b4271a-ef1e-4232-a361-c241d932685d'
						}
					>
						<div
							key={
								item.creation_date + i + '616a214b-f60f-4c13-820f-7fee87a5bbdc'
							}
							className={item.del ? 'toDel' : ''}
						>
							{moment(item.creation_date).format('DD-MM-YYYY')}
						</div>
						<span>
							{item.subvention_id && 'S '}
							{item.project_id && 'P '}
						</span>
						<span className={`uploader`}>
							{item.uploader
								? `${item.uploader.first_name} ${item.uploader.last_name}`
								: ''}
						</span>
						<button
							key={
								item.creation_date + '038316c8-67b8-424e-8209-b03353f88d0f' + i
							}
							className={item.del ? 'toDel' : ''}
							data-url={`${item.url}`}
							data-name={item.url.split('/').pop().split('.')[0]}
							data-type={item.url.split('/').pop().split('.')[1]}
							onClick={handleShowFile}
						>
							{item.url.split('/').pop().split('.')[0]}
						</button>
						{showRemoveDoc &&
							(item.subvention_id ? (
								<>
									<IoClose
										key={
											item.creation_date +
											i +
											'8ccd1bb1-5cde-4c1b-8890-688a20429c5d'
										}
										data-id={item.id}
										data-index={i}
										size={30}
										data-section={'subDocuments'}
										onClick={handleClickRemoveDoc}
										data-tip={contents.toolTipDatas.removeFile}
									/>
									<ReactTooltip
										place={'top'}
										effect={'solid'}
										className={'tooltipCustom subventionDocumentTooltip test'}
										multiline={true}
									/>
								</>
							) : (
								<p>Supprimer depuis le projet</p>
							))}
					</li>
				);
			} else {
				return <></>;
			}
		}) || <></>
	);
};
