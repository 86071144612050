/**
 * Import modules
 */
import React, { useEffect, useState } from 'react';

/**
 * Import components
 */
import { ProjectCard } from './ProjectCard';

/**
 * Import Contexts
 */
import { useUserContext } from '../../contexts/userContext';

/**
 * Import locals
 */
import './ProjectsList.scss';
import utility from '../../utility';

const projectTitle = {
	analytic_code: 'Code Analytique',
	name: 'Nom du projet',
	manager: 'Responsable',
	description: 'Description',
	startDate: 'Date de début',
	endDate: 'Date de fin',
	status: 'Statut',
	subventions: 'Subventions',
};

const ProjectsList = ({ filteredProjects, setFilteredProject }) => {
	const { user } = useUserContext();
	const [sortingOrder, setSortingOrder] = useState({});

	const handleClickSort = async (evt) => {
		let { dataset } = evt.target;

		if (evt.target.localName === 'path') {
			dataset = evt.target.parentNode.dataset;
		}

		let value;
		sortingOrder[dataset.column] === 'ASC' ? (value = 'DESC') : (value = 'ASC');
		setSortingOrder({ [dataset.column]: value });

		const sortedList = utility.sorter(filteredProjects, dataset.column, value);
		setFilteredProject(sortedList);
	};

	/**
	 * Set sortList
	 * Trigger: sortingOrder
	 */
	useEffect(() => {
		const prop = Object.keys(sortingOrder)[0];
		const value = Object.values(sortingOrder)[0];

		const sortedList = utility.sorter(filteredProjects, prop, value);
		setFilteredProject(sortedList);
	}, [sortingOrder]);

	return (
		<div className={'ProjectsList'}>
			<table
				key={'913cc71f-30b8-401b-bdf3-b379cb258425'}
				className="projects-container"
			>
				<thead className="projectsList-title">
					<ProjectCard
						handleOnClick={handleClickSort}
						titles={projectTitle}
						user={user}
					/>
				</thead>

				<tbody className={'projects-list'}>
					{filteredProjects.length > 0 &&
						filteredProjects.map((project, i) => (
							<ProjectCard key={i} project={project} user={user} />
						))}
				</tbody>
			</table>
		</div>
	);
};

export default ProjectsList;
