/**
 * Import modules
 */

/**
 * Import locals
 */
import utility from '../utility';

export const getAllbeneficiaries = async () => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'GET',
			`${utility.apiURL}beneficiary`,
			token
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const addBeneficiary = async (newRelation) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'POST',
			`${utility.apiURL}project/beneficiary`,
			token,
			newRelation
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const editBeneficiary = async (editedBeneficiary) => {
	try {
		delete editedBeneficiary.presence;
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'PUT',
			`${utility.apiURL}beneficiary`,
			token,
			editedBeneficiary
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const editBeneficiaryProject = async (editedBeneficiary) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'PUT',
			`${utility.apiURL}project/beneficiary`,
			token,
			editedBeneficiary
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const deleteBeneficiaryProject = async (projectId, beneficiaryId) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'DELETE',
			`${utility.apiURL}project/beneficiary/${projectId}/${beneficiaryId}`,
			token
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};
