// Import modules
import React, { useState } from 'react';

// Import components

// Import contexts

// Import Hooks

// Import locals
import './CollapsibleComponent.scss';

export const CollapsibleComponent = ({ title, children }) => {
	const [isOpen, setIsOpen] = useState(false);

	const toggleCollapsible = () => {
		setIsOpen(!isOpen);
	};
	return (
		<div className={'CollapsibleComponent'}>
			<div className={`Collapsible__title`} onClick={toggleCollapsible}>
				{title}
				<span className={`icon ${isOpen ? 'open' : 'closed'}`}>
					{isOpen ? '-' : '+'}
				</span>
			</div>
			{isOpen && <div className="collapsible-content">{children}</div>}
		</div>
	);
};
