/**
 * Import modules
 */
import React from 'react';

/**
 * Import components
 */

/**
 * Import contexts
 */

/**
 * Import locals
 */
import './Message.scss';

const Message = ({ status, message, className }) => {
	return (
		<div className={`Message ${className}`}>
			<span className={`message ${status}`}>{message}</span>
		</div>
	);
};

export default Message;
