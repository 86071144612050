/**
 * Import modules
 */
import React from 'react';

/**
 * Import components
 */
import Buttons from '../Buttons/Buttons';

/**
 * Import contexts
 */
import { useImportContext } from '../../contexts/importContext';

/**
 * Import contents
 */
import contents from '../../contents/contents.json';

/**
 * Import locals
 */
import './ImportCard.scss';
import config from '../../config.json';
import utility from '../../utility';

const { blobAddress } = config;

const ImportCard = () => {
	const {
		message,
		errorsMessages,
		options,
		inputFile,
		file,
		userRoleId,
		associations,
		selectValue,
		isDisabled,
		actions: {
			convertFile,
			switchOptions,
			setBase64,
			clearFile,
			importData,
			setMessage,
			setFile,
			setSelectValue,
			setAssociation,
			setDataTosend,
			setErrorsMessages,
		},
	} = useImportContext();

	const toBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handleSelectFile = async (event) => {
		const file = event.target.files[0];
		setDataTosend({});
		setFile(file);
		if (file) {
			if (
				file.type !==
				'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			) {
				setMessage(['Le fichier doit être au format xlsx']);
			} else {
				setMessage([]);
				const base64Uri = await toBase64(file);
				const Base64UriToBase64 = base64Uri.replace(
					'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
					''
				);

				setBase64(Base64UriToBase64);
				convertFile(Base64UriToBase64);
			}
			setErrorsMessages({});
		}
	};

	const submitImport = (evt) => {
		evt.preventDefault();
		importData();
	};

	const associationOptionsListHTML =
		userRoleId === 3
			? associations.map((association, i) => (
					<option key={i} value={association.id}>
						{association.name}
					</option>
			  ))
			: null;

	const handleSelectAssociation = async (evt) => {
		evt.preventDefault();

		const { label, value } = evt.target.selectedOptions[0];
		setSelectValue(value);
		setAssociation({ id: value, name: label });
	};

	return (
		<div className={'ImportCard'}>
			<section>
				<a
					href={`${blobAddress}/Models/${utility.version}/Sofia - import BDD.xlsx`}
					download
				>
					{contents.links.downloadModele}
				</a>
			</section>
			<section>
				<form onSubmit={submitImport}>
					{userRoleId === 3 && (
						<select
							onChange={handleSelectAssociation}
							value={selectValue}
							name="association"
							id="association"
						>
							{associationOptionsListHTML}
						</select>
					)}
					<div className={'fileInput'}>
						<input
							id={'file'}
							className={'inputFile'}
							type="file"
							onChange={handleSelectFile}
							ref={inputFile}
						/>
						<label htmlFor="file">
							{file ? file.name : contents.labels.fileInput}
						</label>
					</div>
					<div className={'options'}>
						{contents?.ImportCard.options?.map((option, i) => (
							<div key={i}>
								<input
									id={option.name}
									type="checkbox"
									onClick={switchOptions}
									checked={options[option.name]}
								/>
								<label htmlFor={option.name}>{option.label}</label>
							</div>
						))}
					</div>
					<Buttons
						customCSS={'buttonCss'}
						handleCancel={clearFile}
						submitTitle={'Importer'}
						isDisabled={isDisabled}
					/>
				</form>
			</section>
			{message?.length > 0 && (
				<section className={'information'}>
					<label htmlFor="info">Information:</label>
					<p id={'info'}>{message}</p>
				</section>
			)}
			{Object.keys(errorsMessages).length > 0 && (
				<section className={'errorsList'}>
					<label htmlFor="errors">Liste des lignes ignorées:</label>

					<div className="errorsUl">
						{Object.keys(errorsMessages).map(
							(key) =>
								errorsMessages[key].length > 0 && (
									<div key={key} className={'errorFamily'}>
										<label htmlFor="errors">{key}:</label>
										<ul id={'errors'}>
											{errorsMessages[key].map((message, i) => (
												<li key={i + '-' + message}>{message}</li>
											))}
										</ul>
									</div>
								)
						)}
					</div>
				</section>
			)}
		</div>
	);
};

export default ImportCard;
