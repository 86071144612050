/**
 * Import modules
 */
import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';

/**
 * Import components
 */
import Buttons from '../../../Buttons/Buttons';
import Message from '../../../Message/Message';

/**
 * Import Contexts
 */
import { useManagerAdminContext } from '../../../../contexts/ManagerAdminContext';

/**
 * Import locals
 */
import './AccountForm.scss';
import contents from '../../../../contents/contents.json';

const initialEditedItem = { name: '', code: '', isShown: true };

const AccountForm = ({
	title,
	handleSubmitChanges,
	message = '',
	setMessage = null,
}) => {
	const { selectedItem, setSelectedItem, switchEditMode } =
		useManagerAdminContext();

	const [editedItem, setEditedItem] = useState(initialEditedItem);

	const handleInputChange = (evt) => {
		const { id, value } = evt.target;
		setEditedItem({ ...editedItem, [id]: value });
		setMessage && setMessage('');
	};

	const handleSwitchChange = async (value, evt, id) => {
		const editItem = { ...editedItem };
		editItem[id] = value;
		setEditedItem(editItem);
	};

	const handleCancelChanges = () => {
		setEditedItem(initialEditedItem);
		switchEditMode();
		setMessage && setMessage('');
	};

	const handleFormSubmit = async (evt) => {
		evt.preventDefault();
		const result = await handleSubmitChanges(editedItem);
		result && setEditedItem(initialEditedItem);
		result && setSelectedItem(initialEditedItem);
	};

	useEffect(() => {
		setEditedItem(selectedItem);
	}, [selectedItem]);

	return (
		<form className={'AccountForm'} onSubmit={handleFormSubmit}>
			<h3>{title}</h3>
			<Message message={contents.messages.AccountFormDesc} />
			<label htmlFor="code">{contents.labels.code}</label>

			<input
				type="number"
				id={'code'}
				value={editedItem.code}
				onChange={handleInputChange}
			/>
			<label htmlFor="name">{contents.labels.lastname}</label>
			<input
				type="text"
				id={'name'}
				value={editedItem.name}
				onChange={handleInputChange}
			/>
			<div className={'switch-content'}>
				<label htmlFor="name">{contents.labels.status}</label>
				<Switch
					id={'isShown'}
					onChange={handleSwitchChange}
					checked={editedItem.isShown}
					uncheckedIcon={
						<div className={'switch-item switch-inactive'}>
							{contents.labels.hidden}
						</div>
					}
					checkedIcon={
						<div className={'switch-item switch-active'}>
							{contents.labels.shown}
						</div>
					}
					width={150}
				/>
			</div>
			<Message status={'error fetch-error'} message={message} />
			<Buttons customCSS={'buttonsWidth'} handleCancel={handleCancelChanges} />
		</form>
	);
};

export default AccountForm;
