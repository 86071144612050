/**
 * Import modules
 */
import { NavLink, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

/**
 * Import components
 */
import SubventionGeneralInfos from './SubventionGeneralInfos';
import SubventionFinancialInfos from './SubventionFinancialInfos';
import SubventionRelationInfos from './SubventionRelationInfos';
import SubventionsDocuments from './SubventionsDocuments';
import SubventionReminder from './SubventionReminder';
import SubventionDangerZone from './SubventionDangerZone';
import DuplicateRefModal from './modals/DuplicateRefModal';
import WebSiteCard from './WebSiteCard';

/**
 * Import Contexts
 */
import { useLoadingContext } from '../../contexts/loadingContext';
import { useSubventionContext } from '../../contexts/subventionContext';
import { useKeyContext } from '../../contexts/keyContext';
import { useUserContext } from '../../contexts/userContext';

/**
 * Import locals
 */
import './SubventionDetail.scss';

import {
	editSubvention,
	getOneSubventionListById,
} from '../../requests/SubventionRequests';
import './SubventionDetail.scss';
import contents from '../../contents/contents.json';

import { deleteFiles } from '../../requests/filesRequest';

const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

const SubventionDetail = ({ setItemEdited }) => {
	const {
		setReload,
		setSelectedSubvention,
		editedSubvention,
		setEditedSubvention,
		editedSection,
		setEditedSection,
		setFileDeleteErrorMessage,
		resetSection,
		setShowRemoveDoc,
		fileToDelete,
		setFileToDelete,
		setDuplicate,
		setDuplicateSubvention,
		actions: { setEditSelectSite },
	} = useSubventionContext();
	const { keySetter } = useKeyContext();
	const { user } = useUserContext();

	let subId = useQuery().get('id');
	let location = useLocation();

	const { actions } = useLoadingContext();

	const [errorMessage, setErrorMessage] = useState('');

	const editable =
		user.subvention_modify_right &&
		!(editedSubvention.etat === 'Cloturée' || editedSubvention.counted);

	const handleEditSubmit = async (event) => {
		event.preventDefault();

		function uniqueByKeepLast(data, key) {
			return [...new Map(data?.map((x) => [key(x), x])).values()];
		}

		const objToSend = { ...editedSubvention };

		objToSend.projects = uniqueByKeepLast(objToSend.projects, (el) => el.id);

		await actions.setter(true, true);
		if (editedSection.subDocuments && fileToDelete.length > 0) {
			const res = await deleteFiles(fileToDelete, 'subvention');

			if (res.status !== 200) {
				switch (res.message.code) {
					case 'ENOENT':
						setFileDeleteErrorMessage(contents.messages.fileNotFound);
						break;

					default:
						setFileDeleteErrorMessage(res.message);
				}
				setFileToDelete([]);
			} else {
				setReload(true);
				setFileToDelete([]);
				setFileDeleteErrorMessage(null);
			}
		}

		const result = await editSubvention(objToSend);

		if (result.status === 400) {
			await setDuplicate(true);
			setDuplicateSubvention(result.duplicate);
		} else if (result.status !== 200) {
			setErrorMessage(
				`${contents.messages.serverError}${contents.messages.serverError2} '${result.message}'`
			);
		}

		if (result) {
			await setEditedSubvention({ ...result.data, edited: false });
			await setSelectedSubvention({ ...result.data });
			await setItemEdited(true);
			await setEditSelectSite(false);
			await resetSection();
		}

		await actions.setter(false, true);
		setReload(true);

		setShowRemoveDoc(false);

		await setEditedSection({
			subGeneralInfos: false,
			subFinancialInfos: false,
			subAccountingInfos: false,
			subDocuments: false,
			subReminder: false,
			subDangerZone: false,
		});
	};

	/**
	 * Get subvention details
	 * Trigger: user, location.pathname
	 */
	useEffect(() => {
		(async () => {
			if (location.pathname.includes('/dashboard/subvention')) {
				keySetter('subventions');
			}
			if (user.id) {
				await actions.setter(true);
				const result = await getOneSubventionListById(subId);

				if (result.data) {
					const data = {
						...result.data,
						amount_asked: parseFloat(result.data.amount_asked),
						remains: parseFloat(result.data.remains),
						total_recieved: parseFloat(result.data.total_recieved),
						give_back: parseFloat(result.data.give_back),
						internalRef: result.data.internal_ref,
					};

					await setSelectedSubvention(data);
					await setEditedSubvention(data);
					await setEditedSubvention({
						...result.data,
						edited: false,
					});
				} else if (result.status === 403) {
					setErrorMessage(contents.messages.notAuthorized);
				}
			}
			await actions.setter(false);
		})();
	}, [user, location.pathname]);

	return (
		<div className={'SubventionDetail'}>
			{errorMessage && (
				<div
					key={'684c5044-e82c-4ae8-a1a6-a6d21dcc25cc'}
					className="error-message"
				>
					{errorMessage}
				</div>
			)}
			{!editedSubvention.id ? (
				<div />
			) : (
				<>
					<NavLink
						key={'4a3546ec-c546-4468-9d49-f9db373cc27d'}
						className={'return-button'}
						to={'/dashboard'}
					>
						{contents.links.goBack}
					</NavLink>
					<SubventionGeneralInfos
						editable={editable}
						handleEditSubmit={handleEditSubmit}
					/>
					<SubventionFinancialInfos
						editable={editable}
						handleEditSubmit={handleEditSubmit}
					/>

					<SubventionRelationInfos
						editable={editable}
						handleEditSubmit={handleEditSubmit}
					/>

					<SubventionsDocuments handleEditSubmit={handleEditSubmit} />

					<SubventionReminder
						editable={editable}
						handleEditSubmit={handleEditSubmit}
					/>

					<WebSiteCard
						editable={editable}
						handleEditSubmit={handleEditSubmit}
					/>

					{user.subvention_modify_right && (
						<SubventionDangerZone handleEditSubmit={handleEditSubmit} />
					)}
				</>
			)}
			<DuplicateRefModal />
		</div>
	);
};

export default SubventionDetail;
