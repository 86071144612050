/**
 * Import modules
 */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { BsExclamationTriangle } from 'react-icons/bs';
import slugify from 'react-slugify';

/**
 * Import components
 */

/**
 * Import Contexts
 */
import { useSubventionContext } from '../../../contexts/subventionContext';

/**
 * Import locals
 */
import './DuplicateRefModal.scss';
import contents from '../../../contents/contents.json';

const DuplicateRefModal = () => {
	const {
		duplicate,
		setDuplicate,
		duplicateSubvention,
		setDuplicateSubvention,
	} = useSubventionContext();

	const handleCloseDuplicateModal = () => {
		setDuplicateSubvention({});
		setDuplicate(!duplicate);
	};

	return (
		<Modal
			key={'1c52aef9-25e8-4f1e-ae3d-c5f3964393aa'}
			show={duplicate}
			onHide={handleCloseDuplicateModal}
			aria-labelledby={'modals-content-duplicate'}
			centered
			restoreFocus
			dialogClassName={'modals-duplicate'}
		>
			<Modal.Header >
				<Modal.Title
					className={'modal_flex_title'}
				>
					<BsExclamationTriangle />
					<h2 >{contents.titles.warning}</h2 >
					<BsExclamationTriangle />
				</Modal.Title >
			</Modal.Header >
			<Modal.Body >
				<div
					key={'78617b86-1dd7-4873-8eba-7974867adaf0'}
					className={'text'}
				>
					{`${contents.messages.DuplicateRefModal1} `}

					<NavLink
						to={`/dashboard/subventions/${slugify(
							duplicateSubvention.name
						)}?id=${duplicateSubvention.id}`}
						onClick={handleCloseDuplicateModal}
					>
						{duplicateSubvention.name}
					</NavLink >
					{` ${contents.messages.DuplicateRefModal2}`}
				</div >
			</Modal.Body >
			<Modal.Footer
				className={'modal_centered_footer'}
			>
				<button
					key={'f5cfc7e5-c02b-4060-b4db-36154350cb43'}
					onClick={handleCloseDuplicateModal}
				>
					{contents.buttons.ok}
				</button >
			</Modal.Footer >
		</Modal >
	);
};

export default DuplicateRefModal;
