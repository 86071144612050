import React from 'react';
import { useHistory } from 'react-router-dom';

import { useKeyContext } from '../../contexts/keyContext';

import './Presentation.scss';
import contents from '../../contents/contents.json';

export const Presentation = () => {
	const { handleClickHome } = useKeyContext();
	let history = useHistory();
	return (
		<div className={'Presentation'}>
			<div className={'logo'}>
				<img
					src="/LogoSofiaFonce-sm.webp"
					alt="Sofia's logo"
					onClick={handleClickHome}
				/>
			</div>
			<h1 className="presentation__title">{contents.titles.Presentation}</h1>
			<div className="presentation__content">
				<div className="presentation__content__video">
					<iframe
						className="presentation__content__video__ytframe"
						width="560"
						height="315"
						src={`${contents.links.yt_base_url + contents.links.video_id}`}
						title={contents.links.video_title}
						//frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					/>
				</div>
				<div className="presentation__content__text">
					<p
						dangerouslySetInnerHTML={{
							__html: contents.Presentation.contentText,
						}}
					/>
					<div className="presentation__header">
						<button
							className={'contact_button'}
							onClick={() => history.push('/contact')}
						>
							{contents.buttons.more_info_button_text}
						</button>
						<button
							className={'connect_button'}
							onClick={() => history.push('/login')}
						>
							{contents.buttons.loginButtonText}
						</button>
					</div>
				</div>
			</div>
			<div className="prices-container">
				<h2 className="prices-title">{contents.Presentation.price_title}</h2>
				<img
					className={`prices-img`}
					src={'/sofia_prices.png'}
					alt="Sofia's prices"
					onClick={() => history.push('/contact')}
				/>
			</div>
			<div className="footer">
				By
				<a href="https://www.so-app.fr/" target="_blank" rel="noreferrer">
					{contents.links.soAppLink}
				</a>
			</div>
		</div>
	);
};
