import React, { createContext, useContext, useState } from 'react';

// context creation
export const formContext = createContext(false);

// context hook
export const useUserContext = () => {
	const context = useContext(formContext);
	if (!context) {
		console.error(
			'UserContext should not be undefined. Did you forget to wrap your component with UserContextProvider ?'
		);
	}
	return context;
};

// provider creation
export const UserContextProvider = ({ children }) => {
	const [user, setUser] = useState({});
	const [init, setInit] = useState({
		Subvention_typeList: [],
		managers: [],
		FunderList: [],
		Accounting_productList: [],
		Accounting_tiersList: [],
		recievers: [],
		projects: [],
	});
	const [adminInitialisation, setAdminInitialisation] = useState({});

	const [users, setUsers] = useState([]);

	const initSetter = (obj) => {
		setInit(obj);
	};

	const initAdminSetter = (value) => {
		setAdminInitialisation(value);
	};

	let value = {
		user,
		users,
		setUsers,
		init,
		adminInitialisation,
		setUser,
		initSetter,
		initAdminSetter,
	};

	return <formContext.Provider value={value}>{children}</formContext.Provider>;
};
