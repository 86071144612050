/**
 * Import modules
 */

/**
 * Import locals
 */
import utility from "../utility";

export const adminInit = async () => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			"GET",
			`${utility.apiURL}backoffice/init`,
			token
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		if (error.message === "Network Error") {
			return { ...error };
		}
		return { ...error.response.data, status: error.response.status };
	}
};

export const getAllUsers = async () => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			"GET",
			`${utility.apiURL}backoffice/getallusers`,
			token
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const createUser = async (user) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			"POST",
			`${utility.apiURL}backoffice/createuser`,
			token,
			user
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const updateUser = async (user) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			"PUT",
			`${utility.apiURL}backoffice/updateuser`,
			token,
			user
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const getAllAssociations = async () => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			"GET",
			`${utility.apiURL}backoffice/getallassociations`,
			token
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const createAssociation = async (association) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			"POST",
			`${utility.apiURL}backoffice/createassociation`,
			token,
			association
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const updateAssociation = async (association) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			"PUT",
			`${utility.apiURL}backoffice/updateassociation`,
			token,
			association
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const getAllRecievers = async () => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			"GET",
			`${utility.apiURL}backoffice/getallrecievers`,
			token
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const createReciever = async (reciever) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			"POST",
			`${utility.apiURL}backoffice/createreciever`,
			token,
			reciever
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const updateReciever = async (reciever) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			"PUT",
			`${utility.apiURL}backoffice/updatereciever`,
			token,
			reciever
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};
