/**
 * Import modules
 */
import React from 'react';

/**
 * Import components
 */
import ImportCard from '../../../../ImportCard/ImportCard';

/**
 * Import contexts
 */

/**
 * Import locals
 */
import './ImportPage.scss';

const ImportPage = () => {
	return (
		<div className={'ImportPage'}>
			<ImportCard />
		</div>
	);
};

export default ImportPage;
