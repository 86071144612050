/**
 * Import modules
 */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

/**
 * Import components
 */
import Buttons from '../../Buttons/Buttons';

/**
 * Import Contexts
 */
import { useSubventionContext } from '../../../contexts/subventionContext';
import { useLoadingContext } from '../../../contexts/loadingContext';

/**
 * Import locals
 */
import './DeleteSubventionModal.scss';
import { deleteSubvention } from '../../../requests/SubventionRequests';
import contents from '../../../contents/contents.json';

const DeleteSubventionModal = () => {
	const { actions } = useLoadingContext();
	const {
		selectedSubvention,
		showDeleteSubventionModal,
		switchShowDeleteSubventionModal,
	} = useSubventionContext();

	const history = useHistory();

	const handleValidDeleteSub = async (evt) => {
		evt.preventDefault();
		actions.setter(true, true);
		const result = await deleteSubvention(selectedSubvention.id);

		if (result.data !== 1) {
			//error
		} else {
			switchShowDeleteSubventionModal();
			history.push('/dashboard');
		}
		actions.setter(false, true);
	};

	return (
		<Modal
			key={'3b1f4078-e92e-46be-a583-21fc9d08d3b1'}
			show={showDeleteSubventionModal}
			onHide={switchShowDeleteSubventionModal}
			size={'l'}
			aria-labelledby={'modals-content-closeSub'}
			centered
			restoreFocus
			dialogClassName={'delete-subvention'}
		>
			<Modal.Header
				closeButton
			>
				<Modal.Title >{contents.titles.DeleteSubventionModal}</Modal.Title >
			</Modal.Header >
			<Modal.Body >
				<span
					key={'080e6de5-d0e0-4ed3-a341-8e82fed60b71'}
					className="advertizing"
				>
					{contents.messages.advertIrreversible}
				</span >

				<Buttons
					key={'6a372088-6b8e-4b45-9005-5d8fa35789dc'}
					handleCancel={switchShowDeleteSubventionModal}
					customCSS={'editButtons'}
					handleSubmit={handleValidDeleteSub}
				/>
			</Modal.Body >
		</Modal >
	);
};

export default DeleteSubventionModal;
