/**
 * Import modules
 */
import React, { useState, useEffect } from 'react';
import { ImPlus } from 'react-icons/im';
import { Tooltip as ReactTooltip } from 'react-tooltip';

/**
 * Import components
 */
import AccountList from '../AccountList';
import AccountForm from '../AccountForm';
import AccountAddTiersModal from './modals/AccountAddTiersModal';

/**
 * Import Contexts
 */
import { useUserContext } from '../../../../../contexts/userContext';
import { useManagerAdminContext } from '../../../../../contexts/ManagerAdminContext';

/**
 * Import locals
 */
import { editTiersAccount } from '../../../../../requests/accountsRequests';
import './TiersAccountPage.scss';
import contents from '../../../../../contents/contents.json';

const TiersAccountPage = () => {
	const { init, initSetter } = useUserContext();
	const { selectedItem, switchShowModal } = useManagerAdminContext();

	const [message, setMessage] = useState('');

	const ShowModal = async () => {
		switchShowModal('tiersAccount');
	};

	const handleSubmitChanges = async (item) => {
		if (!item.id) {
			setMessage(contents.messages.noAccountSelected);
			return false;
		}
		const result = await editTiersAccount(item);

		switch (result.status) {
			case 400:
				setMessage(result.message);
				break;
			case 500:
				setMessage(result.message);
				break;

			default:
				initSetter({ ...init, Accounting_tiersList: result.data });
				return true;
		}
	};

	useEffect(() => {
		setMessage('');
	}, [selectedItem]);

	return (
		<div className={'TiersAccountPage'}>
			<AccountList list={init.Accounting_tiersList} />
			<AccountForm
				title={contents.titles.tiersAccount}
				handleSubmitChanges={handleSubmitChanges}
				message={message}
				setMessage={setMessage}
			/>
			<ImPlus
				key={'b0aeeb56-7304-4ea1-b156-cc165b81900e'}
				onClick={ShowModal}
				size={25}
				color={'#47C8F4'}
				data-tip={contents.toolTipDatas.tiersAccount}
			/>
			<ReactTooltip
				place={'top'}
				effect={'solid'}
				className={'tooltipCustom tiersAccountTooltip test'}
				multiline={true}
			/>
			<AccountAddTiersModal />
		</div>
	);
};

export default TiersAccountPage;
