/**
 * Import modules
 */
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FaAsterisk } from 'react-icons/fa';
import Switch from 'react-switch';

/**
 * Import components
 */
import Buttons from '../../../../../Buttons/Buttons';
import Message from '../../../../../Message/Message';

/**
 * Import Contexts
 */
import { useManagerAdminContext } from '../../../../../../contexts/ManagerAdminContext';
import { useUserContext } from '../../../../../../contexts/userContext';

/**
 * Import locals
 */
import './AccountAddProductModal.scss';
import { addProductAccount } from '../../../../../../requests/accountsRequests';
import contents from '../../../../../../contents/contents.json';

const initialNewProductAccount = {
	code: '',
	name: '',
	association_id: null,
	isShown: true,
};

const AccountAddProductModal = () => {
	const { showAddModal, switchShowModal } = useManagerAdminContext();
	const { user, init, initSetter } = useUserContext();

	const [newProductAccount, setNewProductAccount] = useState(
		initialNewProductAccount
	);
	const [message, setMessage] = useState('');

	const ShowModal = async () => {
		switchShowModal('productAccount');
	};

	const handleInputChange = (evt) => {
		const { id, value } = evt.target;
		setNewProductAccount({
			...newProductAccount,
			[id]: value,
			association_id: user.assos[0].id,
		});
		setMessage('');
	};

	const handleSwitchChange = async (value, evt, id) => {
		const editItem = { ...newProductAccount };
		editItem[id] = value;
		setNewProductAccount(editItem);
	};

	const handleCancel = () => {
		setNewProductAccount(initialNewProductAccount);
		switchShowModal('productAccount');
	};

	const handleSubmit = async (evt) => {
		evt.preventDefault();
		const result = await addProductAccount(newProductAccount);

		switch (result.status) {
			case 400:
				setMessage(result.message);
				break;
			case 500:
				setMessage(result.message);
				break;

			default:
				initSetter({ ...init, Accounting_productList: result.data });
				switchShowModal();
				setNewProductAccount(initialNewProductAccount);
		}
	};

	return (
		<Modal
			key={'5f7ea943-b258-4eff-a635-9d74762f2ab4'}
			show={showAddModal.productAccount}
			onHide={ShowModal}
			aria-labelledby={'modals-content-option'}
			centered
			restoreFocus
			dialogClassName={'AccountAddProductModal'}
		>
			<Modal.Header closeButton>
				<Modal.Title>{contents.titles.AccountAddProductModal}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form
					key={'e9187c2f-f2b0-4986-9193-11c56b060cbe'}
					onSubmit={handleSubmit}
				>
					<Form.Group className={'form-group form-sub-name'} controlId={'code'}>
						<Form.Label>{contents.labels.code}</Form.Label>
						<div className={'input-requierement'}>
							<Form.Control
								required
								type={'number'}
								value={newProductAccount.code}
								onChange={handleInputChange}
							/>
							<FaAsterisk size={'7px'} color={'red'} />
						</div>
					</Form.Group>

					<Form.Group className={'form-group form-sub-name'} controlId={'name'}>
						<Form.Label>{contents.labels.lastname}</Form.Label>
						<div className={'input-requierement'}>
							<Form.Control
								required
								type={'text'}
								value={newProductAccount.name}
								onChange={handleInputChange}
							/>
							<FaAsterisk size={'7px'} color={'red'} />
						</div>
					</Form.Group>
					<div className={'form-group switch-content'}>
						<label htmlFor="name">{contents.labels.status}</label>
						<Switch
							id={'isShown'}
							onChange={handleSwitchChange}
							checked={newProductAccount.isShown}
							uncheckedIcon={
								<div className={'switch-item switch-inactive'}>
									{contents.labels.hidden}
								</div>
							}
							checkedIcon={
								<div className={'switch-item switch-active'}>
									{contents.labels.shown}
								</div>
							}
							width={150}
						/>
					</div>

					<Message status={'error form-message'} message={message} />

					<Buttons handleCancel={handleCancel} customCSS={'form-group'} />
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default AccountAddProductModal;
