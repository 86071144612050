import React, { createContext, useContext, useState } from 'react';

// context creation
export const webSiteContext = createContext(false);

// context hook
export const useWebSiteContext = () => {
	const context = useContext(webSiteContext);
	if (!context) {
		console.error(
			'WebSiteContext should not be undefined. Did you forget to wrap your component with WebSiteContextProvider ?'
		);
	}
	return context;
};

// provider creation
export const WebSiteContextProvider = ({ children }) => {
	const [selectedItem, setSelectedItem] = useState({
		name: '',
	});

	const [webSiteList, setWebSiteList] = useState([]);

	let value = {
		selectedItem,
		webSiteList,
		actions: {
			setSelectedItem,
			setWebSiteList,
		},
	};

	return (
		<webSiteContext.Provider value={value}>{children}</webSiteContext.Provider>
	);
};
