/**
 * Import modules
 */

/**
 * Import locals
 */
import utility from '../utility';

export const sendMail = async (content) => {
	try {
		const res = await utility.axiosReq(
			'POST',
			`${utility.apiURL}mail/send`,
			null,
			content
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		if (error?.response?.data) {
			return { ...error.response.data, status: error.response.status };
		}
		return { data: {}, status: 500 };
	}
};
