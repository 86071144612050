/**
 * Import modules
 */

/**
 * Import locals
 */
import utility from '../utility';

export const getAllContratType = async () => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'GET',
			`${utility.apiURL}contract/type`,
			token
		);
		return { data: res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const createUserContract = async (contract) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'POST',
			`${utility.apiURL}contract/create`,
			token,
			contract
		);
		return { data: res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const updateUserContract = async (contract) => {
	try {
		console.log('[contracsRequests.js]{L:41} contract: ', contract);
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'PUT',
			`${utility.apiURL}contract/update`,
			token,
			contract
		);
		return { data: res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};
