// Import modules
import React, { useEffect, useState } from 'react';

// Import components

// Import contexts

// Import Hooks

// Import locals
import './FilesTab.scss';
import {
	getAllFilesList,
	previewFile,
} from '../../../../../requests/filesRequest';
import { useUserContext } from '../../../../../contexts/userContext';
import { useSubventionContext } from '../../../../../contexts/subventionContext';
import config from '../../../../../config.json';
import DocumentViewerModal from '../../../../SubventionDetail/modals/DocumentViewerModal';
const { blobAddress } = config;

export const FilesTab = () => {
	const { user } = useUserContext();
	const [files, setFiles] = useState([]);

	const formatedFiles =
		files.length > 0
			? files.map((file) => {
					let origin = 'projet';
					let name;
					if (file.subvention) {
						origin = 'subvention';
					}

					if (file.subvention) {
						name = file.subvention.name;
					} else {
						name = file.project.name;
					}

					return {
						id: file.id,
						origin,
						name,
						url: file.url,
						creation_date: file.creation_date,
						uploader: file.uploader
							? `${file.uploader?.first_name} ${file.uploader?.last_name}`
							: '',
					};
			  })
			: [];

	useEffect(() => {
		(async () => {
			const getFiles = await getAllFilesList(user.assos[0].id);
			setFiles(getFiles.data);
		})();
	}, []);

	return (
		<div className={'FilesTab'}>
			<table>
				<thead>
					<FileCard
						file={{
							origin: 'Origine',
							name: 'Nom',
							creation_date: `Date d'ajout`,
							url: 'Lien',
							uploader: { first_name: 'Ajouté par', last_name: '' },
						}}
						isTitle={true}
					/>
				</thead>
				<tbody>
					{formatedFiles.map((file) => (
						<FileCard key={file.id} file={file} />
					))}
				</tbody>
			</table>

			<DocumentViewerModal />
		</div>
	);
};

const FileCard = ({ file, isTitle = false }) => {
	const { showFile, setShowFile } = useSubventionContext();

	const handleShowFile = async (evt) => {
		const { name, url, type } = evt.target.dataset;

		if (type === 'xlsx' || type === 'xls') {
			const url2 = url.replaceAll(' ', '%20');
			const builtUrl = `${blobAddress}/${url2}`;

			const link = document.createElement('a');
			link.href = builtUrl;
			link.id = 'dl';
			document.body.appendChild(link);
			link.click();

			const dlLink = document.getElementById('dl');
			dlLink.parentElement.removeChild(dlLink);
		} else {
			await setShowFile({ show: true, url, name });
		}
	};

	/**
	 * Get file preview
	 * Trigger: showFile.show
	 */
	useEffect(() => {
		(async () => {
			if (showFile.show) {
				setShowFile({ ...showFile, loading: true });
				const extTemp = showFile.url.split('.');
				const type = extTemp[extTemp.length - 1];

				if (type !== 'pdf') {
					const res = await previewFile({
						fileName: showFile.url,
					});
					setShowFile({
						...showFile,
						builtUrl: res.data,
						loading: false,
						type,
					});
				} else {
					setShowFile({
						...showFile,
						builtUrl: `${blobAddress}/${showFile.url}`,
						loading: false,
						type,
					});
				}
			}
		})();
	}, [showFile.show]);

	return (
		<tr className={`FileCard ${!isTitle && 'row_gap'}`}>
			<th className={`fileCard_origin ${isTitle && 'file_title'}`}>
				{file.origin}
			</th>
			<th className={`fileCard_name ${isTitle && 'file_title'}`}>
				{file.name}
			</th>
			<th className={`fileCard_creation_date ${isTitle && 'file_title'}`}>
				{file.creation_date}
			</th>
			<th>{isTitle ? 'Uploader par' : file.uploader}</th>
			<th className={`fileCard_url ${isTitle && 'file_title'}`}>
				{isTitle ? (
					'Lien'
				) : (
					<button
						key={file.creation_date + file.id}
						className={file.del ? 'toDel' : ''}
						data-url={`${file.url}`}
						data-name={file.url.split('/').pop().split('.')[0]}
						data-type={file.url.split('/').pop().split('.')[1]}
						onClick={handleShowFile}
					>
						{file.url.split('/').pop().split('.')[0]}
					</button>
				)}
			</th>
		</tr>
	);
};
