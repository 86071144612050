/**
 * Import modules
 */
import React, {
	createContext,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import * as exelToJson from 'convert-excel-to-json';
import moment from 'moment';

/**
 * Import contexts
 */
import { useUserContext } from './userContext';
import { useBackOfficeContext } from './backOfficeContext';
import { useLoadingContext } from './loadingContext';

/**
 * Import locales
 */
import { massImportData } from '../requests/massiveImport';
import utility from '../utility';

// context creation
export const formContext = createContext('');

// context hook
export const useImportContext = () => {
	const context = useContext(formContext);
	if (!context) {
		console.error(
			'ImportContext should not be undefined. Did you forget to wrap your component with ImportContextProvider ?'
		);
	}
	return context;
};

const initialOptions = {
	subventions: true,
	projects: true,
	funders: true,
	productAccounts: true,
	users: true,
};

const initialAssociation = {
	id: null,
	name: null,
};

// provider creation
export const ImportContextProvider = ({ children }) => {
	const { user } = useUserContext();
	const { associations } = useBackOfficeContext();
	const {
		actions: { setter },
	} = useLoadingContext();

	const inputFile = useRef(null);

	const [selectValue, setSelectValue] = useState();

	const [file, setFile] = useState();
	const [message, setMessage] = useState(null);
	const [options, setOptions] = useState(initialOptions);
	const [base64, setBase64] = useState();
	const [association, setAssociation] = useState(initialAssociation);
	const [data, setData] = useState({});
	const [dataTosend, setDataTosend] = useState({});
	const [isDisabled, setIsDisabled] = useState(true);

	const [errorsMessages, setErrorsMessages] = useState({});

	const switchOptions = async (event) => {
		const { id } = event.target;
		setOptions({ ...options, [id]: !options[id] });
	};

	const convertFile = (base64) => {
		const exelToObject = exelToJson({
			source: base64,
			header: {
				rows: 1,
			},
			sheets: [
				{
					name: 'Subventions',
					columnToKey: {
						A: 'name',
						B: 'Date de Début',
						C: 'Date de fin',
						D: 'subvention_type',
						E: 'accounting_product_id',
						F: 'accounting_tier_id',
						G: 'funder',
						H: 'amount_asked',
						I: 'amount_contracted',
						J: 'status',
						K: 'funder_ref',
						L: 'versement',
						M: 'etat',
						N: 'counted',
					},
				},
				{
					name: 'Utilisateurs Association',
					header: {
						rows: 1,
					},
					columnToKey: {
						A: 'first_name',
						B: 'last_name',
						C: 'email',
						D: 'phone',
						E: 'TYPE DE COMPTE',
						F: 'NOM CENTRE RECEVEUR',
						G: 'subvention_create_right',
						H: 'subvention_modify_right',
						I: 'subvention_extract_right',
						J: 'project_create_right',
						K: 'project_modify_right',
						L: 'project_extract_right',
						M: 'add_documents_right',
						N: 'all_document_read_right',
						O: 'admin_right',
					},
				},
				{
					name: 'Comptes produits',
					header: {
						rows: 1,
					},
					columnToKey: {
						A: 'N COMPTE PRODUIT',
						B: 'LIBELLE COMPTE PRODUIT',
					},
				},
				{
					name: 'financeurs',
					header: {
						rows: 1,
					},
					columnToKey: {
						A: 'N COMPTE TIERS',
						B: 'NOM ORGANISME FINANCEUR',
						C: 'SITES WEB',
					},
				},
				{
					name: 'Projets',
					header: {
						rows: 1,
					},
					columnToKey: {
						A: 'name',
						B: 'description',
						C: 'Date de Début',
						D: 'Date de fin',
						E: 'code analytique',
						F: 'etat',
					},
				},
			],
		});

		exelToObject.funders = exelToObject['financeurs'].map((funder) => {
			if (funder['SITES WEB']) {
				funder.web_sites = [];
				funder['SITES WEB'].split(';').forEach((site) => {
					let [key, value] = site.split(',');
					key = key.replace('nom: ', '');
					value = value.replace(' url: ', '');
					funder.web_sites.push({ name: key, url: value });
				});
			}
			delete funder['SITES WEB'];
			return funder;
		});

		exelToObject.subventions = exelToObject['Subventions'].filter(
			(elem) => elem.funder !== ''
		);
		exelToObject.users = exelToObject['Utilisateurs Association'];
		exelToObject.productAccounts = exelToObject['Comptes produits'];
		exelToObject.projects = exelToObject['Projets'];

		delete exelToObject['Subventions'];
		delete exelToObject['Utilisateurs Association'];
		delete exelToObject['Comptes produits'];
		delete exelToObject['financeurs'];
		delete exelToObject['Projets'];

		setData(exelToObject);
	};

	const validateFile = (data, associationId, associationName) => {
		const object = { ...data };
		let errorMessages = [];
		const errorsMessages = {
			'Organismes financeur et comptes tiers': [],
			'Comptes produit': [],
			Utilisateurs: [],
			Projets: [],
			Subventions: [],
		};
		const result = {};

		let funderList = [];
		let productAccountList = [];
		let userList = [];
		let projectList = [];
		let subventionList = [];

		if (object.funders && options.funders) {
			for (const i in object.funders) {
				const subMessages = [];
				let funder = object.funders[i];
				funder.name = funder['NOM ORGANISME FINANCEUR'];
				funder.code = funder['N COMPTE TIERS'];
				funder.is_shown = Boolean(true);
				funder.unique_key = `${associationId}-${funder['N COMPTE TIERS']}`;
				funder.association_id = associationId;

				if (!funder['N COMPTE TIERS']) {
					subMessages.push(
						`Il manque le numéro de compte tiers pour le nom: ${funder['NOM ORGANISME FINANCEUR']}`
					);
				}

				if (!funder['NOM ORGANISME FINANCEUR']) {
					subMessages.push(
						`Il manque le nom de l'organisme pour le numéro: ${funder['N COMPTE TIERS']}`
					);
				}

				if (subMessages.length) {
					errorsMessages['Organismes financeur et comptes tiers'] = [
						...errorsMessages['Organismes financeur et comptes tiers'],
						...subMessages,
					];
				}
				errorMessages = [...errorMessages, ...subMessages];
				if (!subMessages.length) {
					delete funder['NOM ORGANISME FINANCEUR'];
					delete funder['N COMPTE TIERS'];
					funderList.push(funder);
				}
			}
			if (!funderList.length) {
				funderList = null;
			}
			result.funders = funderList;
		}

		if (object.productAccounts && options.productAccounts) {
			for (const i in object.productAccounts) {
				const subMessages = [];
				let product = object.productAccounts[i];
				product.name = product['LIBELLE COMPTE PRODUIT'];
				product.code = product['N COMPTE PRODUIT'];
				product.association_id = associationId;
				product.is_shown = Boolean(true);
				product.unique_key = `${associationId}-${product['N COMPTE PRODUIT']}`;

				if (!product['N COMPTE PRODUIT']) {
					subMessages.push(
						`Il manque le numero de compte produit pour le libellé: ${product['LIBELLE COMPTE PRODUIT']}`
					);
				}

				if (!product['LIBELLE COMPTE PRODUIT']) {
					subMessages.push(
						`Il manque le libellé pour le numero: ${product['N COMPTE PRODUIT']}`
					);
				}
				if (subMessages.length) {
					errorsMessages['Comptes produit'] = [
						...errorsMessages['Comptes produit'],
						...subMessages,
					];
				}
				errorMessages = [...errorMessages, ...subMessages];
				if (!subMessages.length) {
					delete product['LIBELLE COMPTE PRODUIT'];
					delete product['N COMPTE PRODUIT'];
					productAccountList.push(product);
				}
			}
			if (!productAccountList.length) {
				funderList = null;
			}
			result.productAccounts = productAccountList;
		}

		if (object.users && options.users) {
			for (const i in object.users) {
				const subMessages = [];
				let user = object.users[i];

				user.creation_date = new Date();
				user.last_modified_date = new Date();

				let roleId = null;

				switch (user['TYPE DE COMPTE']) {
					case 'Centre receveur':
						roleId = 2;
						break;
					case 'Administration':
						roleId = 1;
						break;

					default:
						roleId = null;
						break;
				}

				user.role_id = roleId;
				user.status = 'active';
				user.subvention_create_right = user.subvention_create_right === 'Oui';
				user.subvention_modify_right = user.subvention_modify_right === 'Oui';
				user.subvention_extract_right = user.subvention_extract_right === 'Oui';
				user.project_create_right = user.project_create_right === 'Oui';
				user.project_modify_right = user.project_modify_right === 'Oui';
				user.project_extract_right = user.project_extract_right === 'Oui';
				user.add_documents_right = user.add_documents_right === 'Oui';
				user.all_document_read_right = user.all_document_read_right === 'Oui';
				user.admin_right = user.admin_right === 'Oui';
				user.recieverName = user['NOM CENTRE RECEVEUR'];
				user.association_id = associationId;

				if (!user.email) {
					subMessages.push(
						`Il manque l'email pour l'utilisateur: ${user.first_name} ${user.last_name}`
					);
				}

				if (!user.role_id) {
					subMessages.push(
						`Le type de compte n'est pas renseigné pour l'utilisateur: ${user.first_name} ${user.last_name}`
					);
				}

				if (user.role_id === 2 && !user['NOM CENTRE RECEVEUR']) {
					subMessages.push(
						`Le nom du centre receveur n'est pas renseigné pour l'utilisateur: ${user.first_name} ${user.last_name}`
					);
				}

				if (subMessages.length) {
					errorsMessages['Utilisateurs'] = [
						...errorsMessages['Utilisateurs'],
						...subMessages,
					];
				}
				errorMessages = [...errorMessages, ...subMessages];
				if (!subMessages.length) {
					delete user['TYPE DE COMPTE'];
					delete user['NOM CENTRE RECEVEUR'];

					userList.push(user);
				}
			}
			if (!userList.length) {
				userList = null;
			}
			result.users = userList;
		}

		if (object.projects && options.projects) {
			for (const i in object.projects) {
				const subMessages = [];
				let project = object.projects[i];

				project.start_date =
					project['Date de Début'] === undefined
						? new Date().toLocaleDateString('en')
						: new Date(project['Date de Début']).toLocaleDateString('en');
				project.end_date =
					project['Date de fin'] === undefined
						? new Date().toLocaleDateString('en')
						: new Date(project['Date de fin']).toLocaleDateString('en');

				project.analytic_code = project['code analytique'];
				project.association_id = associationId;
				project.unique_key = `${associationId}-${project['code analytique']}`;
				project.manager = {};
				project.etat = project.etat !== '' ? project.etat : 'Non commencé';

				if (!project.name) {
					subMessages.push(
						`Il manque le nom du projet pour: ${project.analytic_code}`
					);
				}

				if (!project.analytic_code) {
					subMessages.push(`Il manque le code interne pour: ${project.name}`);
				}

				if (subMessages.length) {
					errorsMessages['Projets'] = [
						...errorsMessages['Projets'],
						...subMessages,
					];
				}
				errorMessages = [...errorMessages, ...subMessages];
				if (!subMessages.length) {
					delete project['code analytique'];
					delete project['Date de Début'];
					delete project['Date de fin'];
					projectList.push(project);
				}
			}
			if (!projectList.length) {
				projectList = null;
			}
			result.projects = projectList;
		}

		if (object.subventions && options.subventions) {
			const usedReferencies = [];

			for (const i in object.subventions) {
				const subMessages = [];
				let subvention = object.subventions[i];

				subvention.start_date =
					subvention['Date de Début'] === undefined
						? new Date.now().toLocaleDateString('en')
						: utility
								.addHours(2, new Date(subvention['Date de Début']))
								.toLocaleDateString('en');
				subvention.end_date =
					subvention['Date de fin'] === undefined
						? new Date.now().toLocaleDateString('en')
						: utility
								.addHours(2, new Date(subvention['Date de fin']))
								.toLocaleDateString('en');

				/**
				 * Create unique subvention reference
				 */
				const year = new Date(subvention.start_date)
					.getFullYear()
					.toString()
					.substring(2);
				let month = (new Date(subvention.start_date).getMonth() + 1).toString();
				if (parseInt(month) < 10) {
					month = `0${month}`;
				}

				const baseRef = `${associationName.slice(0, 3)}-${year}${month}`;
				usedReferencies.push(baseRef);

				const getRef = usedReferencies.filter((ref) => ref === baseRef);

				subvention.ref = `${baseRef}-${getRef.length}`;

				subvention.funder =
					subvention.funder === 'Choisir un compte tiers'
						? null
						: subvention.funder;
				subvention.counted =
					subvention.counted === 'défaut false' ? false : subvention.counted;
				subvention.association_id = associationId;
				subvention.payments = [];
				subvention.documents = [];
				subvention.counted = subvention.counted === 'Oui';
				subvention.expense_reciepts_time_limit = 1;
				subvention.balance_submission_time_limit = 1;
				subvention.expense_reciepts_date = subvention.end_date;
				subvention.balance_submission_date = subvention.end_date;
				subvention.duration = Math.round(
					moment
						.duration(
							moment(subvention.end_date).diff(moment(subvention.start_date))
						)
						.asMonths()
				);
				subvention.n_duration = Math.round(
					moment
						.duration(
							moment(subvention.end_date).diff(moment(subvention.start_date))
						)
						.asMonths()
				);

				if (!subvention.start_date) {
					subMessages.push(
						`Il manque la date de début pour la subvention: ${subvention.name}.`
					);
				}

				if (!subvention.end_date) {
					subMessages.push(
						`Il manque la date de fin pour la subvention: ${subvention.name}.`
					);
				}

				if (!subvention.funder) {
					subMessages.push(
						`L'organisme financeur n'est pas renseigné pour la subvention: ${subvention.name}.`
					);
				}

				if (!subvention.accounting_product_id) {
					subMessages.push(
						`Compte produit non renseigné pour la subvention: ${subvention.name}.`
					);
				}

				if (!subvention.accounting_tier_id) {
					subMessages.push(
						`Compte tiers non renseigné pour la subvention: ${subvention.name}.`
					);
				}

				if (!subvention.subvention_type) {
					subMessages.push(
						`Le type de subvention n'est pas renseigné pour la subvention: ${subvention.name}.`
					);
				}

				if (!subvention.status) {
					subMessages.push(
						`Le status de la demande n'est pas renseigné pour la subvention: ${subvention.name}.`
					);
				}

				if (!subvention.versement) {
					subMessages.push(
						`Le status du versement n'est pas renseigné pour la subvention: ${subvention.name}.`
					);
				}

				if (!subvention.etat) {
					subMessages.push(
						`L'etat de la subvention n'est pas renseigné pour la subvention: ${subvention.name}.`
					);
				}

				if (subMessages.length) {
					errorsMessages['Subventions'] = [
						...errorsMessages['Subventions'],
						...subMessages,
					];
				}
				errorMessages = [...errorMessages, ...subMessages];
				if (!subMessages.length) {
					delete subvention['Date de Début'];
					delete subvention['Date de fin'];

					if (subvention.funder !== '') {
						subventionList.push(subvention);
					}
				}
			}
			if (!subventionList.length) {
				subventionList = null;
			}
			result.subventions = subventionList;
		}

		Object.keys(errorsMessages).forEach((item) => {
			if (errorsMessages[item].length === 0) {
				delete errorsMessages[item];
			}
		});

		setErrorsMessages(errorsMessages);
		setDataTosend(result);
	};

	const importData = async () => {
		setIsDisabled(true);
		try {
			setMessage([]);
			setErrorsMessages({});
			setter(true, true);
			const result = await massImportData(dataTosend);
			if (!result) {
				setMessage(['pas de retour']);
			} else {
				if (result.status !== 200) {
					setMessage([result.message]);
				} else {
					setMessage(['Import terminé avec succès']);
				}
			}
			setIsDisabled(false);
			setter(false, true);
		} catch (error) {
			console.error(error);
		}
	};

	const clearFile = () => {
		inputFile.current.value = '';
		setBase64();
		setMessage();
		setErrorsMessages({});
		setData({});
		setDataTosend({});
		setFile();
	};

	useEffect(() => {
		if (user?.role_id !== 3 && user?.role_id !== undefined) {
			setAssociation({ id: user.assos[0].id, name: user.assos[0].name });
		}
	}, []);

	useEffect(() => {
		if (user.role_id === 3) {
			if (associations[0]) {
				setSelectValue(associations[0].id);
				setAssociation({ id: associations[0].id, name: associations[0].name });
			}
		}
	}, [associations]);

	useEffect(() => {
		if (Object.keys(data).length) {
			if (
				data?.funders?.length === 0 &&
				data?.productAccounts?.length === 0 &&
				data?.projects?.length === 0 &&
				data?.subventions?.length === 0 &&
				data?.users?.length === 0
			) {
				setMessage(['Aucunes données trouvées']);
			} else {
				validateFile(data, association.id, association.name);
			}
		}
	}, [data]);

	useEffect(() => {
		if (Object.keys(data).length) {
			setMessage(null);
			convertFile(base64);
		}
	}, [options]);

	useEffect(() => {
		const optionsChecked =
			(options['funders'] &&
				Object.prototype.hasOwnProperty.call(dataTosend, 'funders')) ||
			(options['productAccounts'] &&
				Object.prototype.hasOwnProperty.call(dataTosend, 'productAccounts')) ||
			(options['projects'] &&
				Object.prototype.hasOwnProperty.call(dataTosend, 'projects')) ||
			(options['subventions'] &&
				Object.prototype.hasOwnProperty.call(dataTosend, 'subventions')) ||
			(options['users'] &&
				Object.prototype.hasOwnProperty.call(dataTosend, 'users'));

		const arrayCheck =
			(Object.prototype.hasOwnProperty.call(dataTosend, 'funders') &&
				dataTosend?.funders?.length > 0) ||
			(Object.prototype.hasOwnProperty.call(dataTosend, 'productAccounts') &&
				dataTosend?.productAccounts?.length > 0) ||
			(Object.prototype.hasOwnProperty.call(dataTosend, 'projects') &&
				dataTosend?.projects?.length > 0) ||
			(Object.prototype.hasOwnProperty.call(dataTosend, 'subventions') &&
				dataTosend?.subventions?.length > 0) ||
			(Object.prototype.hasOwnProperty.call(dataTosend, 'users') &&
				dataTosend?.users?.length > 0);

		if (optionsChecked && arrayCheck) {
			setIsDisabled(false);
		} else {
			setIsDisabled(true);
		}
	}, [dataTosend]);

	let value = {
		message,
		errorsMessages,
		options,
		inputFile,
		dataTosend,
		file,
		associations,
		userRoleId: user.role_id,
		selectValue,
		isDisabled,
		actions: {
			convertFile,
			validateFile,
			switchOptions,
			setBase64,
			clearFile,
			setAssociation,
			importData,
			setMessage,
			setFile,
			setSelectValue,
			setDataTosend,
			setErrorsMessages,
		},
	};

	return <formContext.Provider value={value}>{children}</formContext.Provider>;
};
