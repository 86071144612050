/**
 * Import modules
 */
import React from 'react';

/**
 * Import components
 */
import CloseSubventionModal from './modals/CloseSubventionModal';
import DeleteSubventionModal from './modals/DeleteSubventionModal';
import Buttons from '../Buttons/Buttons';

/**
 * Import Contexts
 */
import { useSubventionContext } from '../../contexts/subventionContext';
import { useUserContext } from '../../contexts/userContext';

/**
 * Import locals
 */
import './SubventionDangerZone.scss';
import contents from '../../contents/contents.json';

const SubventionDangerZone = ({ handleEditSubmit }) => {
	const { user } = useUserContext();
	const {
		selectedSubvention,
		editedSubvention,
		setEditedSubvention,
		editedSection,
		setEditedSection,
		editCancel,
		switchShowCloseSubventionModal,
		showDeleteSubventionModal,
		switchShowDeleteSubventionModal,
	} = useSubventionContext();

	const handleClickCloseSub = async () => {
		if (selectedSubvention.etat !== 'Cloturée') {
			switchShowCloseSubventionModal();
		}
	};

	const handleCheckboxChange = async () => {
		await setEditedSection({ subDangerZone: true });
		setEditedSubvention({
			...editedSubvention,
			counted: !editedSubvention.counted,
		});
	};

	return (
		<div
			className={'SubventionDangerZone'}
		>
			<section
				key={'e36ba9e9-bd5e-483b-bbc5-6d5806f27909'}
				className={'sub-danger-zone'}
			>
				<h4
					key={'6a340cec-0307-4bce-baa0-b68dafb8af92'}
					className='danger-zone'
				>
					{contents.titles.SubventionDangerZone}
				</h4 >

				<label
					key={'ee386c60-4b13-4a0c-8188-3eca811917fd'}
					className='danger-zone'
				>
					{contents.labels.closeSubvention}
				</label >
				<input
					id={'closeSubvention'}
					type='checkbox'
					className={editedSubvention.etat === 'Cloturée' ? 'disabled' : ''}
					onChange={handleClickCloseSub}
					checked={editedSubvention.etat === 'Cloturée'}
					disabled={editedSubvention.etat === 'Cloturée'}
				/>

				<label
					key={'88aa855d-a55a-4cdb-ad56-bd3d325f50df'}
					className={'financial-infos-counted'}
					htmlFor='counted'
				>
					{contents.labels.counted}
				</label >
				<input
					key={'36a6b2ea-6394-4d83-807c-861fb63b3acc'}
					className={'financial-infos-counted'}
					type={'checkbox'}
					id={'counted'}
					value={editedSubvention.counted}
					checked={editedSubvention.counted}
					disabled={!user.subvention_modify_right || selectedSubvention.counted}
					onChange={handleCheckboxChange}
				/>

				{!editedSubvention.funder_ref || editedSubvention.funder_ref === '' ? (
					<>
						<label
							key={'1a97d44e-8fc9-4e3f-985c-e267960ebf78'}
							className={'delete-sub'}
							htmlFor='delete-sub'
						>
							{contents.labels.deleteSubvention}
						</label >
						<input
							key={'752f2572-fa66-4366-9ecd-ab7f52362e12'}
							type={'checkbox'}
							id={'delete-sub'}
							value={showDeleteSubventionModal}
							checked={showDeleteSubventionModal}
							disabled={!user.subvention_modify_right}
							onChange={switchShowDeleteSubventionModal}
						/>
					</>
				) : (
					<></>
				)}
			</section >
			{editedSection.subDangerZone && (
				<Buttons
					key={'0f89bab0-3cbe-4a86-9e42-34ce69108fef'}
					customCSS={'editButtons'}
					handleSubmit={handleEditSubmit}
					handleCancel={editCancel}
				/>
			)}
			<CloseSubventionModal />
			<DeleteSubventionModal />
		</div >
	);
};

export default SubventionDangerZone;
