/**
 * Import modules
 */
import React, { useEffect, useState } from 'react';

/**
 * Import components
 */
import Buttons from '../../../../../Buttons/Buttons';

/**
 * Import Contexts
 */
import { useUserContext } from '../../../../../../contexts/userContext';

/**
 * Import locals
 */
import { Form, Modal } from 'react-bootstrap';
import contents from '../../../../../../contents/contents.json';
import { createWebSite } from '../../../../../../requests/webSiteRequests';
import Message from '../../../../../Message/Message';

const initialWebSiteState = {
	name: '',
	url: '',
	funder_id: '',
};

export const AddWebSiteModal = ({ editMode, setEditMode }) => {
	const { init, initSetter } = useUserContext();

	const [newWebSite, setNewWebSite] = useState(initialWebSiteState);
	const [error, setError] = useState();
	const handleCloseEditModal = async () => {
		await setEditMode(!editMode);
	};

	const handleInputChange = async (evt) => {
		const editAssos = { ...newWebSite };
		const { id, value } = evt.target;

		editAssos[id] = value;

		if (id === 'funder_id') {
			editAssos[id] = parseInt(value);
		}
		setNewWebSite(editAssos);
	};

	const handleClickCancel = async (evt) => {
		evt.preventDefault();
		setNewWebSite(initialWebSiteState);
		await setEditMode(!editMode);
	};

	const handleSubmit = async (evt) => {
		evt.preventDefault();

		const res = await createWebSite(newWebSite);

		if (res.status !== 200) {
			setError(res.message);
			return;
		}

		initSetter({ ...init, FunderList: res.data });
		await setEditMode(false);
	};

	useEffect(() => {
		setNewWebSite({
			...initialWebSiteState,
			funder_id: init?.FunderList[0]?.id,
		});
	}, [init.FunderList]);

	return (
		<Modal
			key={'e05cc63c-957b-4aeb-bfbf-a65ebd0034b5'}
			show={editMode}
			onHide={handleCloseEditModal}
			size={'xl'}
			aria-labelledby={'modals-content-option'}
			centered
			restoreFocus
			dialogClassName={'edit-association-modals'}
		>
			<Modal.Header>
				<h2>{contents.titles.AssociationDetailModal.title}</h2>
			</Modal.Header>
			<Modal.Body>
				<Message status={'error'} message={error} />
				<Form onSubmit={handleSubmit}>
					<fieldset className={'edit-association'}>
						<Form.Group className="mb-3 form-name" controlId="name">
							<Form.Label>{contents.labels.lastname}</Form.Label>
							<Form.Control
								type="text"
								placeholder={contents.placeholders.lastname}
								onChange={handleInputChange}
								value={newWebSite.name}
							/>
						</Form.Group>

						<Form.Group className="mb-3 form-address" controlId="url">
							<Form.Label>{contents.labels.url}</Form.Label>
							<Form.Control
								type="text"
								placeholder={contents.placeholders.url}
								onChange={handleInputChange}
								value={newWebSite.address}
							/>
						</Form.Group>

						<Form.Group className="mb-3 form-zipcode" controlId="funder_id">
							<Form.Label>{contents.labels.funder}</Form.Label>
							<Form.Control
								as={'select'}
								custom
								onChange={handleInputChange}
								value={newWebSite.funder_id}
							>
								{init.FunderList.map((funder) => (
									<option key={funder.id} value={funder.id}>
										{funder.name}
									</option>
								))}
							</Form.Control>
						</Form.Group>
					</fieldset>

					<fieldset className={'edit-user-buttons'}>
						<Buttons
							submitTitle={contents.buttons.save}
							handleCancel={handleClickCancel}
						/>
					</fieldset>
				</Form>
			</Modal.Body>
		</Modal>
	);
};
