/**
 * Import modules
 */

/**
 * Import locals
 */
import utility from '../utility';

export const getTotals = async () => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq('GET', `${utility.apiURL}totals`, token);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};
