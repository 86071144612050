/**
 * Import modules
 */
import React from 'react';
import { Modal } from 'react-bootstrap';

/**
 * Import components
 */
import Buttons from '../../Buttons/Buttons';

/**
 * Import Contexts
 */
import { useSubventionContext } from '../../../contexts/subventionContext';

/**
 * Import locals
 */
import './CloseSubventionModal.scss';
import contents from '../../../contents/contents.json';

const CloseSubventionModal = () => {
	const {
		editedSubvention,
		setEditedSubvention,
		editedSection,
		setEditedSection,
		showCloseSubventionModal,
		switchShowCloseSubventionModal,
	} = useSubventionContext();

	const handleValidCloseSub = async () => {
		setEditedSubvention({ ...editedSubvention, etat: 'Cloturée' });
		setEditedSection({ ...editedSection, subDangerZone: true });
		switchShowCloseSubventionModal();
	};

	return (
		<Modal
			key={'8b520282-166b-4266-8f2e-7f614ef183d1'}
			show={showCloseSubventionModal}
			onHide={switchShowCloseSubventionModal}
			size={'l'}
			aria-labelledby={'modals-content-closeSub'}
			centered
			restoreFocus
			dialogClassName={'close-subvention'}
		>
			<Modal.Header
				closeButton
			>
				<Modal.Title >{contents.titles.CloseSubventionModal}</Modal.Title >
			</Modal.Header >
			<Modal.Body >
				<span
					key={'16b88cdd-c809-4f65-9aad-6ef008350afe'}
					className='advertizing'
				>
					{contents.messages.advertIrreversible}
				</span >
 
				<Buttons
					key={'0f89bab0-3cbe-4a86-9e42-34ce69108fef'}
					customCSS={'editButtons'}
					handleSubmit={handleValidCloseSub}
					handleCancel={switchShowCloseSubventionModal}
				/>
			</Modal.Body >
		</Modal >
	);
};

export default CloseSubventionModal;
