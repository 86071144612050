/**
 * Import modules
 */
import React, { useEffect, useState } from 'react';

/**
 * Import context
 */
import { useSubventionContext } from '../../contexts/subventionContext';
import { useProjectContext } from '../../contexts/projectContext';

/**
 * Import locals
 */
import './Totaux.scss';
import { getTotals } from '../../requests/totals';
import { TotalCard } from './TotalCard';
import { useUserContext } from '../../contexts/userContext';

const initialTotalsState = {
	asked: {
		quantity: '0',
		amount_asked: '0',
	},
	accepted: {
		quantity: '0',
		amount_contracted: '0',
		amount_received: '0',
		amount_remains: '0',
	},
};

const Totaux = () => {
	const { user } = useUserContext();
	const { selectedSubvention } = useSubventionContext();

	const { selectedProject } = useProjectContext();

	const [totals, setTotals] = useState(initialTotalsState);

	useEffect(() => {
		(async () => {
			const totals = await getTotals();
			setTotals(totals.data);
		})();
	}, [selectedSubvention, selectedProject]);

	return user?.role?.title === 'admin' ? (
		<div className={'Totaux'}>
			<details className={`detail-totaux`}>
				<summary>Totaux année en cours</summary>
				<div className="totaux ">
					<div className="asked">
						<h2>Subventions demandées:</h2>
						<TotalCard
							title={'Quantité'}
							value={totals?.asked?.quantity}
							customCss={`asked__item__quantity`}
						/>
						<TotalCard
							title={'Total demandé'}
							value={totals?.asked?.amount_asked}
							customCss={`asked__item__asked_amount`}
							suffix={' €'}
							decimals={true}
						/>
					</div>
					<div className="accepted">
						<h2>Subventions en cours:</h2>
						<TotalCard
							title={'Quantité'}
							value={totals?.accepted?.quantity}
							customCss={`accepted__item__quantity`}
						/>
						<TotalCard
							title={'Total subventionné'}
							value={totals?.accepted?.amount_contracted}
							customCss={`accepted__item__amount_contracted`}
							suffix={' €'}
							decimals={true}
						/>
						<TotalCard
							title={'Total perçu'}
							value={totals?.accepted?.amount_received}
							customCss={`accepted__item__amount_received`}
							suffix={' €'}
							decimals={true}
						/>
						<TotalCard
							title={'Total  à percevoir'}
							value={totals?.accepted?.amount_remains}
							customCss={`accepted__item__amount_remains`}
							suffix={' €'}
							decimals={true}
						/>
					</div>
				</div>
			</details>
			<div className="totaux without-detail">
				<div className="asked">
					<h2>Subventions demandées année en cours:</h2>
					<TotalCard
						title={'Quantité'}
						value={totals?.asked?.quantity}
						customCss={`asked__item__quantity`}
					/>
					<TotalCard
						title={'Total demandé'}
						value={totals?.asked?.amount_asked}
						customCss={`asked__item__asked_amount`}
						suffix={' €'}
						decimals={true}
					/>
				</div>
				<div className="accepted">
					<h2>Subventions année en cours:</h2>
					<TotalCard
						title={'Quantité'}
						value={totals?.accepted?.quantity}
						customCss={`accepted__item__quantity`}
					/>
					<TotalCard
						title={'Total subventionné'}
						value={totals?.accepted?.amount_contracted}
						customCss={`accepted__item__amount_contracted`}
						suffix={' €'}
						decimals={true}
					/>
					<TotalCard
						title={'Total perçu'}
						value={totals?.accepted?.amount_received}
						customCss={`accepted__item__amount_received`}
						suffix={' €'}
						decimals={true}
					/>
					<TotalCard
						title={'Total  à percevoir'}
						value={totals?.accepted?.amount_remains}
						customCss={`accepted__item__amount_remains`}
						suffix={' €'}
						decimals={true}
					/>
				</div>
			</div>
		</div>
	) : null;
};

export default Totaux;
