/**
 * Import modules
 */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import { FaBell } from 'react-icons/fa';

/**
 * Import components
 */
import Notification from '../Notification/Notification';

/**
 * Import Contexts
 */
import { useSubventionContext } from '../../contexts/subventionContext';
/**
 * Import locals
 */
import './Reminder.scss';
import contents from '../../contents/contents.json';
import Message from '../Message/Message';

const Reminder = () => {
	const { subventionList, reload } = useSubventionContext();

	const history = useHistory();

	const [showModal, setShowModal] = useState(false);
	const [newNotification, setNewNotification] = useState(false);
	const [notified, setNotified] = useState({ date: '', bool: false });

	const [notificationsList, setNotificationsList] = useState([]);

	/**
	 * Get list of notifications ( expanse and balance)
	 * Trigger: subventionList, reload
	 */
	useEffect(() => {
		(async () => {
			const filteredSubventions = subventionList.filter(
				(sub) => sub.etat !== 'Cloturée'
			);

			const editNotificationList = [...notificationsList];

			for (let item of filteredSubventions) {
				const currentDate = moment().format('YYYY-MM-DD');

				let expenseRemindType;

				switch (item.expense_reciepts_time_type) {
					case 'mois':
						expenseRemindType = 'months';
						break;
					case 'semaines':
						expenseRemindType = 'weeks';
						break;
					case 'années':
						expenseRemindType = 'years';
						break;
				}

				let balanceRemindType;

				switch (item.balance_submission_time_type) {
					case 'mois':
						balanceRemindType = 'months';
						break;
					case 'semaines':
						balanceRemindType = 'weeks';
						break;
					case 'années':
						balanceRemindType = 'years';
						break;
				}

				const expenseRemindDate = moment(item.expense_reciepts_date)
					.subtract(item.expense_reciepts_time_limit, expenseRemindType)
					.format('YYYY-MM-DD');

				const balanceRemindDate = moment(item.balance_submission_date)
					.subtract(item.balance_submission_time_limit, balanceRemindType)
					.format('YYYY-MM-DD');

				const expenseDiff = item.expense_reciepts_date
					? moment(currentDate).diff(expenseRemindDate)
					: null;

				const balanceDiff = item.balance_submission_date
					? moment(currentDate).diff(balanceRemindDate)
					: null;

				if (
					expenseDiff === 0 &&
					(notified.date === moment().format('DD/MM/YYYY')) ^
						(notified.date === '')
				) {
					const newExpense = {
						name: item.name,
						id: item.id,
						ref: item.ref,
						date: item.expense_reciepts_date,
						type: 'Justificatif',
						new: !notified.bool,
					};

					if (
						!editNotificationList.some(
							(x) => x.id === item.id && x.type === newExpense.type
						)
					) {
						setNewNotification(!notified.bool);
						editNotificationList.push(newExpense);
					}
				}

				if (
					balanceDiff === 0 &&
					(notified.date === moment().format('DD/MM/YYYY')) ^
						(notified.date === '')
				) {
					const newBalance = {
						name: item.name,
						id: item.id,
						ref: item.ref,
						date: item.balance_submission_date,
						type: 'Bilan',
						new: !notified.bool,
					};

					if (
						!notificationsList.some(
							(x) => x.id === item.id && x.type === newBalance.type
						)
					) {
						setNewNotification(!notified.bool);
						editNotificationList.push(newBalance);
					}
				}

				if (expenseDiff > 0) {
					const oldExpense = {
						name: item.name,
						id: item.id,
						ref: item.ref,
						date: item.expense_reciepts_date,
						type: 'Justificatif',
						new: false,
					};
					if (
						moment(currentDate).diff(moment(item.expense_reciepts_date)) === 0
					) {
						oldExpense.lastDay = true;
					}

					if (
						!notificationsList.some(
							(x) => x.id === item.id && x.type === oldExpense.type
						)
					) {
						editNotificationList.push(oldExpense);
					}
				}

				if (balanceDiff > 0) {
					const oldBalance = {
						name: item.name,
						id: item.id,
						ref: item.ref,
						date: item.balance_submission_date,
						type: 'Bilan',
						new: false,
					};

					if (
						moment(currentDate).diff(moment(item.balance_submission_date)) === 0
					) {
						oldBalance.lastDay = true;
					}

					if (
						!notificationsList.some(
							(x) => x.id === item.id && x.type === oldBalance.type
						)
					) {
						editNotificationList.push(oldBalance);
					}
				}
				//editNotificationList.push(remind);
			}

			setNotificationsList(editNotificationList.flat());
		})();
	}, [subventionList, reload]);

	const handleShowModal = async () => {
		setShowModal(true);
		setNewNotification(false);
		setNotified({ date: moment().format('DD/MM/YYYY'), bool: true });
	};

	const handleCloseAddModal = () => {
		setShowModal(false);
	};

	const handleClickToSubvention = async (evt) => {
		evt.preventDefault();

		let { url } = evt.target.dataset;

		if (evt.target.nodeName === 'path') {
			url = evt.target.parentNode.dataset.url;
		}

		await setShowModal(false);
		history.push(url);
	};

	return (
		<div
			key={'8424624e-a86f-4fc5-b2e2-7896be734f48'}
			className={'Reminder'}
			data-tip={contents.toolTipDatas.Reminder}
		>
			<FaBell
				className={`bell ${newNotification ? 'new-notif' : ''}`}
				key={'b3f7d08c-a10e-4c01-89e3-31ab23392360'}
				size={25}
				onClick={handleShowModal}
			/>

			<Modal
				key={'b640e24c-301b-11ec-8d3d-0242ac130003'}
				show={showModal}
				size={'lg'}
				onHide={handleCloseAddModal}
				aria-labelledby={'modals-content-option'}
				centered
				restoreFocus
				dialogClassName={'modal_reminder'}
			>
				<Modal.Header
					closeButton
				>
					<Modal.Title >{contents.titles.Reminder}</Modal.Title >
				</Modal.Header >
				<Modal.Body >
					<Message
						status={'reminder-text'}
					/>
					<div
						className={'reminder-text'}
					>
						{contents.messages.reminderAdvert}
					</div >
					<h2 >{contents.Reminder.tableTitles.new}</h2 >
					<table >
						<thead
							className={'reminder_table'}
						>
							<Notification
								item={contents.Reminder.tableHeader}
								Reactkey={'36af35ce-4da4-44a3-b8f0-2377d470d11c'}
								title={true}
							/>
						</thead >
						<tbody
							className={'reminder_table'}
						>
							{notificationsList
								.filter((item) => item.new)
								.map((item, i) => (
									<Notification
										key={i}
										item={item}
										Reactkey={item.ref + i}
										handler={handleClickToSubvention}
									/>
								))}
						</tbody >
					</table >

					<h2 >{contents.Reminder.tableTitles.old}</h2 >
					<table >
						<thead
							className={'reminder_table'}
						>
							<Notification
								item={contents.Reminder.tableHeader}
								key={'79eb56c8-8744-48b6-9f13-ea0fb2850989'}
								title={true}
							/>
						</thead >
						<tbody
							className={'reminder_table'}
						>
							{notificationsList
								.filter((item) => !item.new)
								.sort((a, b) => new Date(a.date) - new Date(b.date))
								.map((item, i) => (
									<Notification
										key={`${item.id}-item-${i}`}
										item={item}
										index={i}
										handler={handleClickToSubvention}
									/>
								))}
						</tbody >
					</table >
				</Modal.Body >
				<Modal.Footer >
					<button
						onClick={handleCloseAddModal}
					>{contents.buttons.ok}</button >
				</Modal.Footer >
			</Modal >
		</div >
	);
};

export default Reminder;
