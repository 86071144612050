/**
 * Import modules
 */
import React, { useEffect, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import Switch from 'react-switch';

/**
 * Import components
 */
import Message from '../Message/Message';
import Buttons from '../Buttons/Buttons';
import { CollapsibleComponent } from '../CollapsibleComponent/CollapsibleComponent';
import { ContractTypeOption } from './ContractTypeOption';

/**
 * Import Contexts
 */
import { useLoadingContext } from '../../contexts/loadingContext';
import { useBackOfficeContext } from '../../contexts/backOfficeContext';
import { useUserContext } from '../../contexts/userContext';

/**
 * Import locals
 */
import './UserDetail.scss';
import { updateUser } from '../../requests/AdminRequest';
import contents from '../../contents/contents.json';
import { updateUserContract } from '../../requests/contracsRequests';

const initUserContractState = {
	user_id: '',
	contract_type_id: -1,
	start_date: '',
	end_date: '',
	hourly_rate: '',
	monthly_hours: '',
};

const contractTypeNull = {
	id: -1,
	name: 'Selectionner un type de contrat',
};

const UserDetailModal = ({
	associationList,
	recieverList,
	roleList,
	item,
	title = false,
	setEdited,
	editMode,
	setEditMode,
}) => {
	const { actions } = useLoadingContext();
	const { recievers } = useBackOfficeContext();
	const { init } = useUserContext();

	const [roleList2, setRoleList] = useState([]);
	const [user, setUser] = useState(item);
	const [editedUser, setEditedUser] = useState(item);
	const [filteredRecievers, setFilteredRecievers] = useState([]);
	const [errorMessage, setErrorMessage] = useState(null);

	const [roleOptionsHTML, setRoleOptionsHTML] = useState([]);
	const [statusOptionsHTML, setStatusOptionsHTML] = useState([]);

	const [userContract, setUserContract] = useState(
		item.contract ? item.contract : initUserContractState
	);

	const handleCloseEditModal = async () => {
		await setEditMode(!editMode);
	};

	const handleInputChange = async (evt) => {
		setErrorMessage(null);
		const editUser = { ...editedUser };
		const { id, value, name } = evt.target;
		let title = null;

		if (evt.target.localName === 'select') {
			title = evt.target.selectedOptions[0].innerText;
		}
		switch (id) {
			case 'role':
				editUser[id] = { id: value, title };
				editUser.roleId = parseInt(value);
				editUser.role_id = parseInt(value);
				editUser.roleName = title;
				switch (name) {
					case 'Responsable association':
						editUser.recievers = [];
						editUser.assos =
							user.role.title !== 'superAdmin'
								? [user.assos[0].id]
								: [associationList[0]];
						break;
					case 'Responsable centre bénéficaire':
						editUser.assos =
							user.role.title !== 'superAdmin'
								? [user.assos[0].id]
								: [associationList[0]];
						break;
					case 'Collaborateur':
						editUser.recievers = [];
						editUser.assos =
							user.role.title !== 'superAdmin'
								? [user.assos[0].id]
								: [associationList[0]];
						break;
					case 'Administrateur global':
						editUser.recievers = [];
						editUser.assos = [];
						break;
					default:
						break;
				}

				break;

			case 'association':
				editUser.assos = associationList?.filter(
					(asso) => asso.id === parseInt(value)
				);

				editedUser.roleId === 2
					? (editUser.recievers = [
							recieverList?.filter(
								(reciever) => reciever.id === parseInt(value)
							)[0],
					  ])
					: (editUser.recievers = []);
				break;

			case 'reciever':
				editUser.recievers = [
					recieverList?.filter(
						(reciever) => reciever.id === parseInt(value)
					)[0],
				];
				editUser.recievers[0].user_id = user.id;
				editUser.recievers[0].userId = user.id;
				break;
			case 'phone':
				editUser[id] = value.replace(
					/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
					`$1 $2 $3 $4 $5`
				);

				break;

			default:
				editUser[id] = value;
		}
		setEditedUser(editUser);
	};

	const handleSwitchChange = async (value, evt, id) => {
		const editUser = { ...editedUser };
		editUser[id] = value;
		setEditedUser(editUser);
	};

	const handleClickCancel = async (evt) => {
		evt.preventDefault();
		await setEditMode(!editMode);
		await setEditedUser(user);
	};

	const handleSubmit = async (evt) => {
		actions.setter(true, true);
		evt.preventDefault();

		const res = await updateUser(editedUser);

		if (res.status !== 200) {
			if (res.status === 409) {
				setErrorMessage(contents.messages.duplicateMailError);
			}
		} else {
			const userContractToUpdate = { ...userContract };
			delete userContractToUpdate.contractTypeId;

			const result = await updateUserContract({
				...userContractToUpdate,
				user_id: item.id,
			});

			if (result.status !== 200) {
				if (result.status === 409) {
					setErrorMessage(contents.messages.duplicateMailError);
				}
			} else {
				await setUserContract(result.data.data);
				await setUser(res.data);
				await setEditMode(false);
				await setEdited(true);
			}
		}
		actions.setter(false, true);
	};

	const handleContractInputChange = async (evt) => {
		const editContract = { ...userContract };
		const { id, value } = evt.target;
		setErrorMessage(null);

		switch (id) {
			case 'contract_type_id':
				editContract[id] = parseInt(value);
				break;
			case 'start_date':
				editContract[id] = value;
				break;
			case 'end_date':
				editContract[id] = value;
				break;
			case 'hourly_rate':
				if (/^[\d]*[,.]?\d*?$/.test(value)) {
					editContract[id] = value.replace('.', ',');
				}
				break;
			case 'monthly_hours':
				if (/^[\d]*[,.]?\d*?$/.test(value)) {
					editContract[id] = value.replace('.', ',');
				}
				break;

			default:
				editContract[id] = value;
		}
		setUserContract(editContract);
	};

	useEffect(() => {
		setFilteredRecievers(
			recievers?.filter(
				(reciever) => reciever.user_id === null || reciever.user_id === user.id
			)
		);

		if (
			editedUser.role_id === 2 &&
			(editedUser.recievers.length === 0 ||
				editedUser.recievers[0] === undefined)
		) {
			if (filteredRecievers.length > 0) {
				const selectedReciever = filteredRecievers[0];
				selectedReciever.user_id = user.id;
				selectedReciever.userId = user.id;
				setEditedUser({ ...editedUser, recievers: [selectedReciever] });
			}
		}
	}, [editMode, recievers]);

	/**
	 * Translate role Name
	 * Trigger: roleList
	 */
	useEffect(() => {
		if (roleList) {
			const newList = [];

			for (const item of roleList) {
				const newItem = { ...item };
				switch (item.title) {
					case 'admin':
						newItem.title = 'Responsable association';
						break;
					case 'manager':
						newItem.title = 'Responsable centre bénéficaire';
						break;
					case 'superAdmin':
						newItem.title = 'Administrateur global';
						break;
					case 'employee':
						newItem.title = 'Collaborateur';
						break;
					default:
						if (title) {
							newItem.title = item.title;
						} else {
							newItem.title = '';
						}
						break;
				}
				newList.push(newItem);
			}

			setRoleList(newList);
		}
	}, [roleList]);

	/**
	 * Set role and status select's options
	 * Trigger: roleList2
	 */
	useEffect(() => {
		if (roleList2) {
			setRoleOptionsHTML(
				roleList2.map((role, i) => (
					<option key={role + i} value={role.id} name={role.title}>
						{role.title}
					</option>
				))
			);
		}
		setStatusOptionsHTML(
			['active', 'inactive'].map((status, i) => (
				<option key={i + status} value={status}>
					{status === 'active' ? 'actif' : 'inactif'}
				</option>
			))
		);
	}, [roleList2]);

	return (
		<Modal
			key={'e05cc63c-957b-4aeb-bfbf-a65ebd0034b5'}
			show={editMode}
			onHide={handleCloseEditModal}
			size={'xl'}
			aria-labelledby={'modals-content-option'}
			centered
			restoreFocus
			dialogClassName={'edit-user UserDetailModal'}
		>
			<Modal.Header>
				<h2>{contents.titles.UserDetailModal}</h2>
			</Modal.Header>
			<Modal.Body>
				<Message status={'error'} message={errorMessage} />
				<Form onSubmit={handleSubmit}>
					<CollapsibleComponent title={`Informations Générale`}>
						<fieldset className={'edit-user-infos'}>
							<Form.Group className="mb-3 form-lastname" controlId="last_name">
								<Form.Label>{contents.labels.lastname}</Form.Label>
								<Form.Control
									type="text"
									placeholder={contents.placeholders.lastname}
									onChange={handleInputChange}
									value={editedUser.last_name}
								/>
							</Form.Group>
							<Form.Group
								className="mb-3 form-firstname"
								controlId="first_name"
							>
								<Form.Label>{contents.labels.firstname}</Form.Label>
								<Form.Control
									type="text"
									placeholder={contents.placeholders.firstname}
									onChange={handleInputChange}
									value={editedUser.first_name}
								/>
							</Form.Group>

							<Form.Group className="mb-3 form-email" controlId="email">
								<Form.Label>{contents.labels.mail}</Form.Label>
								<Form.Control
									type="email"
									placeholder={contents.placeholders.mail}
									onChange={handleInputChange}
									value={editedUser.email}
								/>
							</Form.Group>

							<Form.Group className="mb-3 form-phone" controlId="phone">
								<Form.Label>{contents.labels.phone}</Form.Label>
								<Form.Control
									type="tel"
									placeholder={contents.placeholders.phone}
									onChange={handleInputChange}
									value={editedUser.phone}
								/>
							</Form.Group>
						</fieldset>
						<fieldset className={'edit-user-group'}>
							<Form.Group className="mb-3 form-role" controlId="role">
								<Form.Label>{contents.labels.role}</Form.Label>
								<Form.Select
									type="text"
									placeholder={contents.placeholders.role}
									onChange={handleInputChange}
									value={editedUser.role_id}
								>
									{roleOptionsHTML}
								</Form.Select>
							</Form.Group>

							{user.role.title === 'superAdmin' && (
								<Form.Group
									className="mb-3 form-association"
									controlId="association"
								>
									<Form.Label>{contents.labels.association}</Form.Label>

									{associationList && editedUser.roleId !== 3 ? (
										<Form.Select
											type="text"
											placeholder={contents.placeholders.association}
											onChange={handleInputChange}
											value={editedUser.assos[0] && editedUser.assos[0].id}
										>
											{associationList.map((asso, i) => (
												<option key={i + asso.id} value={asso.id}>
													{asso.name}
												</option>
											))}
										</Form.Select>
									) : (
										<Form.Select
											type="text"
											placeholder={contents.placeholders.role}
											disabled={true}
										/>
									)}
								</Form.Group>
							)}

							{recieverList && editedUser.roleId === 2 ? (
								<Form.Group className="mb-3 form-reciever" controlId="reciever">
									<Form.Label>{contents.labels.receiver}</Form.Label>
									<Form.Select
										type="text"
										placeholder={contents.placeholders.receiver}
										onChange={handleInputChange}
										value={
											editedUser.recievers[0] && editedUser.recievers[0].id
										}
										disabled={!editedUser.assos[0]}
									>
										{editedUser.assos[0] &&
											filteredRecievers?.map((reciever, i) => (
												<option
													key={i + reciever.id}
													value={reciever.id}
													disabled={
														!!reciever.user_id &&
														reciever.user_id !== editedUser.id
													}
												>
													{reciever.name}
												</option>
											))}
									</Form.Select>
								</Form.Group>
							) : (
								<div className={'form-reciever'} />
							)}
							<div className={'form-blank'} />
							<Form.Group className="mb-3 status" controlId="status">
								<Form.Label>{contents.labels.status}</Form.Label>
								<Form.Select
									type="text"
									placeholder={contents.placeholders.status}
									onChange={handleInputChange}
									value={editedUser.status}
								>
									{statusOptionsHTML}
								</Form.Select>
							</Form.Group>
						</fieldset>
					</CollapsibleComponent>

					<fieldset className={'lined'} />

					<CollapsibleComponent title={`Droits`}>
						<fieldset className={'edit-user-rights'}>
							<h3>{contents.titles.BackOfficeModal.user.subventions}</h3>
							<Form.Group
								className="mb-3 switchs subvention_modify_right"
								controlId="subvention_modify_right"
							>
								<Form.Label>
									{contents.labels.subvention_create_right}
								</Form.Label>
								<div>
									<Switch
										id={'subvention_create_right'}
										onChange={handleSwitchChange}
										checked={editedUser.subvention_create_right}
									/>
								</div>
							</Form.Group>
							<Form.Group
								className="mb-3 switchs subvention_modify_right"
								controlId="subvention_modify_right"
							>
								<Form.Label>
									{contents.labels.subvention_modify_right}
								</Form.Label>
								<div>
									<Switch
										id={'subvention_modify_right'}
										onChange={handleSwitchChange}
										checked={editedUser.subvention_modify_right}
									/>
								</div>
							</Form.Group>

							<Form.Group
								className="mb-3 switchs subvention_extract_right"
								controlId="subvention_extract_right"
							>
								<Form.Label>
									{contents.labels.subvention_extract_right}
								</Form.Label>
								<div>
									<Switch
										id={'subvention_extract_right'}
										onChange={handleSwitchChange}
										checked={editedUser.subvention_extract_right}
									/>
								</div>
							</Form.Group>
							<Form.Group
								className="mb-3 switchs add_documents_right"
								controlId="add_documents_right"
							>
								<Form.Label>{contents.labels.add_documents_right}</Form.Label>
								<div>
									<Switch
										id={'add_documents_right'}
										onChange={handleSwitchChange}
										checked={editedUser.add_documents_right}
									/>
								</div>
							</Form.Group>
							<Form.Group
								className="mb-3 switchs admin_right"
								controlId="admin_right"
							>
								<Form.Label>{contents.labels.admin_right}</Form.Label>
								<div>
									<Switch
										id={'admin_right'}
										onChange={handleSwitchChange}
										checked={editedUser.admin_right}
									/>
								</div>
							</Form.Group>
						</fieldset>
						<fieldset className={'edit-user-projects'}>
							<h3>{contents.titles.BackOfficeModal.user.projects}</h3>
							<Form.Group
								className="mb-3 switchs project_create_right"
								controlId="project_create_right"
							>
								<Form.Label>{contents.labels.project_create_right}</Form.Label>
								<div>
									<Switch
										id={'project_create_right'}
										onChange={handleSwitchChange}
										checked={editedUser.project_create_right}
									/>
								</div>
							</Form.Group>
							<Form.Group
								className="mb-3 switchs project_modify_right"
								controlId="project_modify_right"
							>
								<Form.Label>{contents.labels.project_modify_right}</Form.Label>
								<div>
									<Switch
										id={'project_modify_right'}
										onChange={handleSwitchChange}
										checked={editedUser.project_modify_right}
									/>
								</div>
							</Form.Group>
							<Form.Group
								className="mb-3 switchs project_extract_right"
								controlId="project_extract_right"
							>
								<Form.Label>{contents.labels.project_extract_right}</Form.Label>
								<div>
									<Switch
										id={'project_extract_right'}
										onChange={handleSwitchChange}
										checked={editedUser.project_extract_right}
									/>
								</div>
							</Form.Group>
							<Form.Group
								className="mb-3 switchs switchs all_document_read_right"
								controlId="all_document_read_right"
							>
								<Form.Label>
									{contents.labels.all_document_read_right}
								</Form.Label>
								<div>
									<Switch
										id={'all_document_read_right'}
										onChange={handleSwitchChange}
										checked={editedUser.all_document_read_right}
									/>
								</div>
							</Form.Group>
							<div className={'form-blank'} />
						</fieldset>
					</CollapsibleComponent>

					<fieldset className={'lined'} />

					<CollapsibleComponent title={`Contrat`}>
						<fieldset className={'edit-user-contract'}>
							<Form.Group
								className="mb-3 form-lastName"
								controlId="contract_type_id"
							>
								<Form.Label>{contents.labels.contractType}</Form.Label>

								<Form.Select
									type="text"
									placeholder={contents.placeholders.contractType}
									onChange={handleContractInputChange}
									value={userContract.contract_type_id}
								>
									<ContractTypeOption
										key={'contract_type_null'}
										contractType={contractTypeNull}
									/>
									{init.contractTypes?.map((type, i) => (
										<ContractTypeOption key={i + type.id} contractType={type} />
									))}
								</Form.Select>
							</Form.Group>

							<Form.Group
								className="mb-3 form-firstName"
								controlId="start_date"
							>
								<Form.Label>{contents.labels.startDate}</Form.Label>
								<Form.Control
									type="date"
									placeholder={contents.placeholders.startDate}
									onChange={handleContractInputChange}
									value={userContract.start_date}
								/>
							</Form.Group>

							<Form.Group className="mb-3 form-firstName" controlId="end_date">
								<Form.Label>{contents.labels.endDate}</Form.Label>
								<Form.Control
									type="date"
									placeholder={contents.placeholders.endDate}
									onChange={handleContractInputChange}
									value={userContract.end_date}
								/>
							</Form.Group>

							<Form.Group className="mb-3 form-phone" controlId="hourly_rate">
								<Form.Label>{contents.labels.hourlyRate}</Form.Label>
								<Form.Control
									type="text"
									placeholder={contents.placeholders.hourlyRate}
									onChange={handleContractInputChange}
									value={userContract.hourly_rate}
								/>
							</Form.Group>

							<Form.Group className="mb-3 form-phone" controlId="monthly_hours">
								<Form.Label>{contents.labels.monthlyHours}</Form.Label>
								<Form.Control
									type="text"
									placeholder={contents.placeholders.monthlyHours}
									onChange={handleContractInputChange}
									value={userContract.monthly_hours}
								/>
							</Form.Group>
						</fieldset>
					</CollapsibleComponent>

					<fieldset className={'edit-user-buttons'}>
						<Buttons handleCancel={handleClickCancel} />
					</fieldset>
				</Form>
			</Modal.Body>
		</Modal>
	);
};

export default UserDetailModal;
