// Import modules
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

// Import components
import DuplicateSubventionModal from './DuplicateSubventionModal';
import { DuplicateProjectModal } from './DuplicateProjectModal';

// Import contexts
import { useDashboardContext } from '../../../contexts/DashboarContext';

// Import Hooks

// Import locals
import contents from '../../../contents/contents.json';

export const DuplicateModalContainer = () => {
	let location = useLocation();

	const {
		showDuplicateModal,
		actions: { switchShowDuplicateModal },
	} = useDashboardContext();

	let modalTilte = '';
	if (location.pathname.startsWith('/dashboard/subventions/'))
		modalTilte = contents.titles.DuplicateSubventionModal;
	if (location.pathname.startsWith('/dashboard/projects/'))
		modalTilte = contents.titles.DuplicateProjectModal;

	return (
		<div className={'DuplicateModalContainer'}>
			<Modal
				key={'78917c3d-ef6c-4d9a-b7b8-b1769b1c98be'}
				show={showDuplicateModal}
				onHide={switchShowDuplicateModal}
				size={'xl'}
				aria-labelledby={'modals-content-option'}
				centered
				restoreFocus
				dialogClassName={'duplicate-sub'}
			>
				<Modal.Header closeButton>
					<Modal.Title>{modalTilte}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{location.pathname.startsWith('/dashboard/subventions/') && (
						<DuplicateSubventionModal />
					)}
					{location.pathname.startsWith('/dashboard/projects/') && (
						<DuplicateProjectModal />
					)}
				</Modal.Body>
			</Modal>
		</div>
	);
};
