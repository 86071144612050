/**
 * Import modules
 */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.css';
/**
 * Import contexts
 */
import { LoadingContextProvider } from './contexts/loadingContext';
import { KeyContextProvider } from './contexts/keyContext';
import { UserContextProvider } from './contexts/userContext';
import { SubventionContextProvider } from './contexts/subventionContext';
import { DashboardContextProvider } from './contexts/DashboarContext';
import { ManagerAdminContextProvider } from './contexts/ManagerAdminContext';
import { ProjectContextProvider } from './contexts/projectContext';

/**
 * Import locals
 */
import './assets/scss/_reset.scss';
import './assets/scss/_variables.scss';
import './assets/scss/index.scss';
import './index.css';
import reportWebVitals from './reportWebVitals';

/**
 * Import Components
 */
import App from './App';

const Root = () => (
	<Router>
		<React.StrictMode>
			<SubventionContextProvider>
				<ProjectContextProvider>
					<UserContextProvider>
						<LoadingContextProvider>
							<DashboardContextProvider>
								<KeyContextProvider>
									<ManagerAdminContextProvider>
										<App />
									</ManagerAdminContextProvider>
								</KeyContextProvider>
							</DashboardContextProvider>
						</LoadingContextProvider>
					</UserContextProvider>
				</ProjectContextProvider>
			</SubventionContextProvider>
		</React.StrictMode>
	</Router>
);

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
