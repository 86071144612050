/**
 * Import modules
 */
import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

/**
 * Import components
 */
import Buttons from '../Buttons/Buttons';
import Message from '../Message/Message';

/**
 * Import Contexts
 */
import { useLoadingContext } from '../../contexts/loadingContext';

/**
 * Import locals
 */
import './AskPasswordReset.scss';
import { createResetUrl } from '../../requests/userRequests';
import contents from '../../contents/contents.json';

const AskPasswordReset = () => {
	const { loading, actions } = useLoadingContext();

	const history = useHistory();
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [status, setStatus] = useState('');

	const formReset = () => {
		setEmail('');
	};

	const handleInputChange = (evt) => {
		setEmail(evt.target.value);
		setStatus('');
		setMessage('');
	};

	const handleSubmit = async (evt) => {
		evt.preventDefault();

		const regex =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const isValidEmail = regex.test(String(email).toLowerCase());

		if (isValidEmail) {
			// sent to API
			actions.setter(true, true);
			const result = await createResetUrl({ email });

			if (result.status !== 200) {
				setMessage(result.message);
				setStatus('error');
			} else {
				setMessage(`${contents.messages.mailSend} ${email}`);
				setStatus('success');
			}
			actions.setter(false, true);
		} else {
			setMessage(contents.messages.invalidMail);
			setStatus('error');
		}
	};

	const handleClickReset = () => {
		formReset();
		history.push('/login');
	};
	return (
		<div
			className={'AskPasswordReset'}
		>
			<h1
				className="AskPasswordReset-title"
			>
				{contents.titles.AskPasswordReset}
			</h1 >

			<Message
				status={status}
				message={message}
				loading={loading}
			/>
			<form
				className="AskPasswordReset-form"
				onSubmit={handleSubmit}
			>
				<div
					className="email-container formInput-container"
				>
					<label
						htmlFor="email"
					>{contents.labels.mail}</label >
					<input
						className={'email-input'}
						type="text"
						id="email"
						name="email"
						placeholder={contents.placeholders.mail}
						value={email.email}
						onChange={handleInputChange}
					/>
				</div >

				<Link
					className={'return'}
					to={'/login'}
				>
					{contents.links.return}
				</Link >

				<Buttons
					submitTitle={contents.buttons.send}
					isDisabled={loading}
					handleCancel={handleClickReset}
					customCSS={'AskPasswordReset_buttons'}
				/>
			</form >
		</div >
	);
};

export default AskPasswordReset;
