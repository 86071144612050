/**
 * Import modules
 */
import React, { useEffect } from 'react';

/**
 * Import components
 */
import { WebSiteCard } from './WebSiteCard';

/**
 * Import Contexts
 */
import { useWebSiteContext } from '../../../../../contexts/webSiteContext';

/**
 * Import locals
 */
import './WebSite.scss';
import { getAllWebSiteByFunderId } from '../../../../../requests/webSiteRequests';
import { useUserContext } from '../../../../../contexts/userContext';

export const WebSites = ({ editMode }) => {
	const { init } = useUserContext();
	const {
		selectedItem,
		webSiteList,
		actions: { setWebSiteList },
	} = useWebSiteContext();

	useEffect(() => {
		if (selectedItem.id) {
			(async () => {
				const result = await getAllWebSiteByFunderId(selectedItem.id);
				setWebSiteList(result.data);
			})();
		}
	}, [selectedItem, init.FunderList]);

	return (
		<div className={`WebSite`}>
			<h3>Liste des sites du financeur</h3>
			{webSiteList?.length === 0 ? (
				<span>Pas de site de remise configuré pour le moment</span>
			) : (
				<ul className={`WebSite__list`}>
					{webSiteList?.map((website, i) => (
						<WebSiteCard key={i} website={website} editMode={editMode} />
					))}
				</ul>
			)}
		</div>
	);
};
