/**
 * Import modules
 */
import { BsThreeDotsVertical } from 'react-icons/bs';
import React, { useEffect, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

/**
 * Import components
 */
import UserDetailModal from './UserDetailModal';

/**
 * Import Contexts
 */

/**
 * Import locals
 */
import './UserDetail.scss';
import contents from '../../contents/contents.json';
import { useUserContext } from '../../contexts/userContext';

const baseCss = {
	line: '',
	role: { title: '' },
	asso: '',
	firstname: '',
	lastname: '',
	email: '',
	status: '',
	readRight: '',
	edithight: '',
	dlRight: '',
	extha: '',
};

const UserDetail = ({
	associationList,
	recieverList,
	roleList,
	index,
	thKey,
	item,
	customCss = baseCss,
	title = false,
	isActive,
	setEdited,
}) => {
	const { user: currentUser } = useUserContext();
	const [editMode, setEditMode] = useState(false);
	const [user, setUser] = useState(item);

	const entity =
		item.recievers.length > 0
			? item.recievers[0].name
			: item.assos.length > 0
			? item.assos[0].name
			: '';
	const role = contents.roles[item.role.title];

	const handleThreeDotClick = () => {
		setEditMode(true);
	};

	/**
	 * Set editedUser
	 * thigger: item
	 */
	useEffect(() => {
		const editUser = { ...user };
		editUser.entity =
			item.recievers.length > 0
				? item.recievers[0].name
				: item.assos.length > 0
				? item.assos[0].name
				: '';
		editUser.roleName = contents.roles[item.role.title];
		editUser.assosId = item.assos[0] ? item.assos[0].id : null;
		editUser.assosName = item.assos[0] ? item.assos[0].name : null;
		editUser.recieverId = item.recievers[0] ? item.recievers[0].id : null;
		editUser.recieverName = item.recievers[0] ? item.recievers[0].name : null;
		setUser(editUser);
	}, [item]);

	customCss.line2 = isActive ? `` : 'inactive';

	return (
		<tr
			key={thKey}
			id={index}
			className={`UserDetail ${customCss.line} ${customCss.line2} ${
				currentUser.role.title === 'admin' && 'UserDetail-admin'
			}`}
		>
			{currentUser.role.title === 'superAdmin' && (
				<th className={'UserDetail-assos'}>{entity}</th>
			)}
			<th className={'UserDetail-lastname'}>{user.last_name}</th>
			<th className={'UserDetail-firstname'}>{user.first_name}</th>
			<th className={'UserDetail-email'}>{user.email}</th>
			<th className={`UserDetail-role ${customCss.role.title}`}>{role}</th>
			<th className={'UserDetail-status'}>
				{user.status === 'active'
					? 'actif'
					: user.status === 'inactive'
					? 'inactif'
					: user.status}
			</th>
			<th className={'UserDetail-extha'}>
				{title ? (
					<></>
				) : (
					<BsThreeDotsVertical
						id={`dot-${index}`}
						data-index={index}
						onClick={handleThreeDotClick}
					/>
				)}
			</th>
			<ReactTooltip
				place={'top'}
				effect={'solid'}
				className={'tooltipCustom userDeatilTooltip test'}
				multiline={true}
			/>

			<UserDetailModal
				item={item}
				setEdited={setEdited}
				editMode={editMode}
				setEditMode={setEditMode}
				roleList={roleList}
				associationList={associationList}
				recieverList={recieverList}
			/>
		</tr>
	);
};

export default UserDetail;
