/**
 * Import modules
 */
import { Form, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { FaAsterisk } from 'react-icons/fa';

/**
 * Import components
 */
import Buttons from '../../Buttons/Buttons';
import Message from '../../Message/Message';

/**
 * Import Contexts
 */
import { useLoadingContext } from '../../../contexts/loadingContext';
import { useUserContext } from '../../../contexts/userContext';
import { useDashboardContext } from '../../../contexts/DashboarContext';

/**
 * Import locals
 */
import './CreateProjectModal.scss';
import { addProject } from '../../../requests/projectsRequests';
import slugify from 'react-slugify';
import contents from '../../../contents/contents.json';

const CreateProjectModal = ({ setEdited }) => {
	const { actions } = useLoadingContext();
	const {
		newProject,
		setNewProject,
		cancelCreate,
		showCreateProjectModal,
		newProjectMeetRequierements,
		actions: { switchShowCreateModal },
	} = useDashboardContext();
	const { user } = useUserContext();

	let history = useHistory();

	const [message, setMessage] = useState('');

	const handleStartDate = async (evt) => {
		setNewProject({
			...newProject,
			startDate: evt.target.value,
		});
		setMessage('');
	};

	const handleEndDate = async (evt) => {
		await setNewProject({
			...newProject,
			endDate: evt.target.value,
		});
		setMessage('');
	};

	const handleInputChange = async (evt) => {
		const { id, value } = evt.target;
		setNewProject({
			...newProject,
			[id]: value,
			associationId: user.assos[0].id,
		});
		setMessage('');
	};

	const handleInputManagerChange = (evt) => {
		const { id, value } = evt.target;

		setNewProject({
			...newProject,
			manager: {
				...newProject.manager,
				[id]: value,
			},
		});
		setMessage('');
	};

	const handleSubmit = async (event) => {
		event.preventDefault();

		actions.setter(true, true);

		if (!newProjectMeetRequierements) {
			setMessage(contents.messages.meetRequirement);
			event.stopPropagation();
		} else {
			const newProjectToSend = {
				...newProject,
				uniqueKey: `${user.assos[0].id}-${newProject.projectAnalyticCode}`,
			};

			const result = await addProject(newProjectToSend);

			if (result.status !== 200) {
				switch (result.status) {
					case 500:
						setMessage(result.message);
						break;

					default:
						setMessage(result.message);
						break;
				}
			} else {
				setMessage('');
				await setEdited(true);

				switchShowCreateModal('projects');

				history.push(
					`/dashboard/projects/${slugify(result.data.name)}?id=${
						result.data.id
					}`,
				);
			}
		}

		actions.setter(false, true);
	};

	/**
	 * Build new subvention referencie
	 * Trigger: init, startDate
	 */

	let etatHTML = ['Non commencé', 'En cours', 'Cloturé'].map((item, i) => (
		<option
			key={`${item}-${i}`}
			value={item}
		>
			{item}
		</option >
	));

	return (
		<Modal
			key={'b640e24c-301b-11ec-8d3d-0242ac130003'}
			show={showCreateProjectModal}
			onHide={() => switchShowCreateModal('projects')}
			size={'xl'}
			aria-labelledby={'modals-content-option'}
			centered
			restoreFocus
			dialogClassName={'add-project'}
		>
			<Modal.Header
				closeButton
			>
				<Modal.Title >{contents.titles.CreateProjectModal}</Modal.Title >
			</Modal.Header >
			<Modal.Body >
				<Form
					key={'e9187c2f-f2b0-4986-9193-11c56b060cbe'}
					onSubmit={handleSubmit}
				>
					<Form.Group
						className={'form-project-group form-code'}
						controlId={'projectAnalyticCode'}
					>
						<Form.Label >{contents.labels.projectAnalyticCode}</Form.Label >
						<Form.Control
							required
							type={'text'}
							value={newProject.projectAnalyticCode}
							onChange={handleInputChange}
						/>
						<FaAsterisk
							size={'7px'}
							color={'red'}
						/>
					</Form.Group >

					<Form.Group
						className={'form-project-group form-proj-name'}
						controlId={'projectName'}
					>
						<Form.Label >{contents.labels.projectName}</Form.Label >
						<Form.Control
							required
							type={'text'}
							value={newProject.subventionName}
							onChange={handleInputChange}
						/>
						<FaAsterisk
							size={'7px'}
							color={'red'}
						/>
					</Form.Group >

					<Form.Group
						className={'form-project-group form-etat'}
						controlId={'etat'}
					>
						<Form.Label >{contents.labels.etat}</Form.Label >
						<Form.Select
							onChange={handleInputChange}
							value={newProject.etat}
							required
						>
							{etatHTML}
						</Form.Select >
					</Form.Group >

					<div
						className={'form-project-group form-manager'}
					>
						<h3 >{contents.labels.manager} </h3 >
						<Form.Group
							className={'form-project-group form-manager-last-name'}
							controlId={'last_name'}
						>
							<Form.Label >{contents.labels.lastname}</Form.Label >
							<Form.Control
								type={'text'}
								value={newProject.manager.last_name}
								onChange={handleInputManagerChange}
							/>
						</Form.Group >
						<Form.Group
							className={'form-project-group form-manager-first-name'}
							controlId={'first_name'}
						>
							<Form.Label >{contents.labels.firstname}</Form.Label >
							<Form.Control
								type={'text'}
								value={newProject.manager.first_name}
								onChange={handleInputManagerChange}
							/>
						</Form.Group >
						<Form.Group
							className={'form-project-group form-manager-phone'}
							controlId={'phone'}
						>
							<Form.Label >{contents.labels.phone}</Form.Label >
							<Form.Control
								type={'tel'}
								value={newProject.manager.phone}
								onChange={handleInputManagerChange}
							/>
						</Form.Group >
						<Form.Group
							className={'form-project-group form-manager-email'}
							controlId={'email'}
						>
							<Form.Label >{contents.labels.mail}</Form.Label >
							<Form.Control
								type={'email'}
								value={newProject.manager.email}
								onChange={handleInputManagerChange}
							/>
						</Form.Group >
						<div
							className={'form-manager-inputs'}
						/>
					</div >

					<div
						className={'form-project-group form-dates'}
					>
						<Form.Group
							className={'form-project-group form-start-date'}
							controlId={'subventionStartDate'}
						>
							<Form.Label >{contents.labels.from}</Form.Label >

							<input
								required
								id={'startDate'}
								type='date'
								onChange={handleStartDate}
								value={newProject.startDate}
							/>
							<FaAsterisk
								size={'7px'}
								color={'red'}
							/>
						</Form.Group >

						<Form.Group
							className={'form-project-group form-end-date'}
							controlId={'subventionEndDate'}
						>
							<Form.Label >{contents.labels.to}</Form.Label >

							<input
								required
								id={'endDate'}
								type='date'
								onChange={handleEndDate}
								min={newProject.startDate}
								value={newProject.endDate}
							/>
							<FaAsterisk
								size={'7px'}
								color={'red'}
							/>
						</Form.Group >
					</div >

					<Message
						status={'error'}
						message={message}
					/>

					<div
						className='buttons'
					>
						<Buttons
							handleCancel={cancelCreate}
						/>
					</div >
				</Form >
			</Modal.Body >
		</Modal >
	);
};

export default CreateProjectModal;
