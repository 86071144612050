import React, { createContext, useContext, useState } from 'react';

// context creation
export const formContext = createContext(false);

// context hook
export const useLoadingContext = () => {
	const context = useContext(formContext);
	if (!context) {
		console.error(
			'LoadingContext should not be undefined. Did you forget to wrap your component with LoadingContextProvider ?'
		);
	}
	return context;
};

// provider creation
export const LoadingContextProvider = ({ children }) => {
	const [loading, setLoading] = useState(false);
	const [buttonLoading, setButtonLoading] = useState();

	const switcher = (button = false) => {
		if (button) {
			setButtonLoading(!buttonLoading);
		} else {
			setLoading(!loading);
		}
	};

	const setter = (value, button = false) => {
		if (button) {
			setButtonLoading(value);
		} else {
			setLoading(value);
		}
	};

	let value = {
		loading,
		buttonLoading,
		actions: {
			switcher,
			setter,
		},
	};

	return <formContext.Provider value={value}>{children}</formContext.Provider>;
};
