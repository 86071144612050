/**
 * Import modules
 */
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FaAsterisk } from 'react-icons/fa';
import Switch from 'react-switch';

/**
 * Import components
 */

/**
 * Import components
 */
import Buttons from '../../../../../Buttons/Buttons';
import Message from '../../../../../Message/Message';

/**
 * Import Contexts
 */
import { useManagerAdminContext } from '../../../../../../contexts/ManagerAdminContext';
import { useUserContext } from '../../../../../../contexts/userContext';

/**
 * Import locals
 */
import './AccountAddTiersModal.scss';
import { addTiersAccount } from '../../../../../../requests/accountsRequests';
import contents from '../../../../../../contents/contents.json';

const initialNewProductAccount = {
	code: '',
	name: '',
	association_id: null,
	isShown: true,
};

const AccountAddTiersModal = () => {
	const { showAddModal, switchShowModal } = useManagerAdminContext();
	const { user, init, initSetter } = useUserContext();

	const [newTiersAccount, setNewTiersAccount] = useState(
		initialNewProductAccount
	);
	const [message, setMessage] = useState('');

	const handleInputChange = (evt) => {
		const { id, value } = evt.target;
		setNewTiersAccount({
			...newTiersAccount,
			[id]: value,
			association_id: user.assos[0].id,
		});
		setMessage('');
	};

	const ShowModal = async () => {
		switchShowModal('tiersAccount');
	};

	const handleSwitchChange = async (value, evt, id) => {
		const editItem = { ...newTiersAccount };
		editItem[id] = value;
		setNewTiersAccount(editItem);
	};

	const handleCancel = () => {
		setNewTiersAccount(initialNewProductAccount);
		switchShowModal('tiersAccount');
	};

	const handleSubmit = async (evt) => {
		evt.preventDefault();
		const result = await addTiersAccount(newTiersAccount);

		switch (result.status) {
			case 400:
				setMessage(result.message);
				break;
			case 500:
				setMessage(result.message);
				break;

			default:
				initSetter({ ...init, Accounting_tiersList: result.data });
				switchShowModal();
				setNewTiersAccount(initialNewProductAccount);
		}
	};

	return (
		<Modal
			key={'ea126393-96dd-4c31-967c-3eeefef199bd'}
			show={showAddModal.tiersAccount}
			onHide={ShowModal}
			aria-labelledby={'modals-content-option'}
			centered
			restoreFocus
			dialogClassName={'AccountAddTiersModal'}
		>
			<Modal.Header
				closeButton
			>
				<Modal.Title >{contents.titles.AccountAddTiersModal}</Modal.Title >
			</Modal.Header >
			<Modal.Body >
				<Form
					key={'98a7430b-f832-45b4-9c9a-9f963723a1bd'}
					onSubmit={handleSubmit}
				>
					<Form.Group
						className={'form-group form-sub-name'}
						controlId={'code'}
					>
						<Form.Label >{contents.labels.code}</Form.Label >
						<div
							className={'input-requierement'}
						>
							<Form.Control
								required
								type={'text'}
								value={newTiersAccount.code}
								onChange={handleInputChange}
							/>
							<FaAsterisk
								size={'7px'}
								color={'red'}
							/>
						</div >
					</Form.Group >

					<Form.Group
						className={'form-group form-sub-name'}
						controlId={'name'}
					>
						<Form.Label >{contents.labels.lastname}</Form.Label >
						<div
							className={'input-requierement'}
						>
							<Form.Control
								required
								type={'text'}
								value={newTiersAccount.name}
								onChange={handleInputChange}
							/>
							<FaAsterisk
								size={'7px'}
								color={'red'}
							/>
						</div >
					</Form.Group >
					<div
						className={'form-group switch-content'}
					>
						<label
							htmlFor="name"
						>{contents.labels.status}</label >
						<Switch
							id={'isShown'}
							onChange={handleSwitchChange}
							checked={newTiersAccount.isShown}
							uncheckedIcon={
								<div
									className={'switch-item switch-inactive'}
								>
									{contents.labels.hidden}
								</div >
							}
							checkedIcon={
								<div
									className={'switch-item switch-active'}
								>
									{contents.labels.shown}
								</div >
							}
							width={150}
						/>
					</div >

					<Message
						status={'error form-message'}
						message={message}
					/>

					<Buttons
						handleCancel={handleCancel}
						customCSS={'form-group'}
					/>
				</Form >
			</Modal.Body >
		</Modal >
	);
};

export default AccountAddTiersModal;
