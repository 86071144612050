/**
 * Import modules
 */
import React from 'react';
import { ImPlus } from 'react-icons/im';
import { NavLink } from 'react-router-dom';
import slugify from 'react-slugify';
import { NumericFormat } from 'react-number-format';
import { Tooltip as ReactTooltip } from 'react-tooltip';

/**
 * Import components
 */
import Buttons from '../Buttons/Buttons';
import AddSelfFinancingModal from './modals/AddSelfFinancingModal';

/**
 * Import contexts
 */
import { useUserContext } from '../../contexts/userContext';
import { useProjectContext } from '../../contexts/projectContext';

/**
 * Import locals
 */
import './ProjectSubventionInfos.scss';
import contents from '../../contents/contents.json';

const ProjectSubventionInfos = ({ handleEditSubmit }) => {
	const { user } = useUserContext();
	const {
		editedSection,
		selectedProject,
		editedProject,
		switchShowAddSelfModal,
		editCancel,
	} = useProjectContext();

	let percentValue = [];

	if (selectedProject.subventions) {
		percentValue = selectedProject.subventions?.map((sub) => {
			return (sub.amount_contracted * sub.m2m_subvention_project.percent) / 100;
		});
	}

	let subventionHTML,
		autoFinancementHTML = <></>;

	if (editedProject.id) {
		subventionHTML = editedProject.subventions?.map((sub, i) => {
			return (
				<tr key={`${sub.id}-subdetail-${i}`} className={'rows subvention_rows'}>
					<th key={`${i}-afbf42d1-f894-4244-8c7a-c44ae837d186-${sub.id}`}>
						{user.roleId === 1 || sub.recievers?.length > 0 ? (
							<NavLink
								key={`${i}-a24f89c2-0e8a-4a24-bac1-3fb33475e299-${sub.id}`}
								to={`/dashboard/subventions/${slugify(sub.name)}?id=${sub.id}`}
							>
								{sub.name}
							</NavLink>
						) : (
							<span>{sub.name}</span>
						)}
					</th>
					<th key={`${i}-26cb1a25-2b6a-401f-bab9-3da0504b4f55-${sub.id}`}>
						{sub.ref}
					</th>
					<th key={`${i}-472434b1-0d87-4387-a177-b61a01dca085-${sub.id}`}>
						<NumericFormat
							key={'eba6bb5b-5fef-4c0d-b27f-6e428a69954c'}
							value={sub.amount_contracted}
							displayType={'text'}
							thousandSeparator={' '}
							suffix={' €'}
							decimalScale={2}
							className="payment-item-3"
						/>
					</th>
					<th key={`${i}-a1bcd4d8-9f84-4c97-915a-1e5c7e8cd1dd-${sub.id}`}>
						<NumericFormat
							key={`${i}-7c214ffc-c244-4780-8479-514b705facec-${sub.id}`}
							value={sub.m2m_subvention_project.percent}
							displayType={'text'}
							thousandSeparator={' '}
							suffix={' %'}
							decimalScale={2}
							className="payment-item-3"
						/>
					</th>
					<th key={`${i}-9b703636-0324-4d59-8110-ede0fd6e26b4-${sub.id}`}>
						<NumericFormat
							key={`${i}-9b8c7a0b-6cf2-4517-a65e-a1767252f9b7-${sub.id}`}
							value={percentValue[i]}
							displayType={'text'}
							thousandSeparator={' '}
							suffix={' €'}
							decimalScale={2}
							className="payment-item-3"
						/>
					</th>
				</tr>
			);
		});

		if (editedProject?.self_financings) {
			autoFinancementHTML = editedProject.self_financings?.map((item, i) => (
				<tr
					key={`${item.id}-subdetail-${i}`}
					className={'rows self_financing_rows'}
				>
					<th key={`${i}-6a748791-28f0-40c4-98b2-719366c226c9-${item.id}`}>
						{item.origin}
					</th>
					<th key={`${i}-02e1e06b-a743-472f-93f1-937ef8bfbfc5-${item.id}`}>
						<NumericFormat
							key={`${i}-36770b35-34bc-4cc5-9570-e9b5f2db74a8-${item.id}`}
							value={item.amount}
							displayType={'text'}
							thousandSeparator={' '}
							suffix={' €'}
							decimalScale={2}
							className="payment-item-3"
						/>
					</th>
				</tr>
			));
		}
	}
	return (
		<div className={'ProjectSubventionInfos'}>
			<section
				key={'b6118237-ac27-4bfb-be58-09e1ad909bb7'}
				className={'project-subvention-infos'}
			>
				<div>
					<h3>{contents.titles.ProjectSubventionInfos}</h3>

					<table className="table-subventions">
						<thead>
							<tr className="rows subvention_rows">
								<th>
									<span>
										{contents.ProjectSubventionInfos.tableSubventionHeader.name}
									</span>
								</th>
								<th>
									<span>
										{contents.ProjectSubventionInfos.tableSubventionHeader.ref}
									</span>
								</th>
								<th>
									<span>
										{
											contents.ProjectSubventionInfos.tableSubventionHeader
												.amount
										}
									</span>
								</th>
								<th>
									<span>
										{
											contents.ProjectSubventionInfos.tableSubventionHeader
												.percent
										}
									</span>
								</th>
								<th>
									<span>
										{
											contents.ProjectSubventionInfos.tableSubventionHeader
												.quote
										}
									</span>
								</th>
							</tr>
						</thead>
						<tbody>{subventionHTML}</tbody>
					</table>
				</div>

				<div>
					<div className="self_financing_title">
						<h3>{contents.titles.selfFinancing}</h3>

						{user.project_modify_right && (
							<ImPlus
								onClick={switchShowAddSelfModal}
								data-tip={contents.toolTipDatas.selfFinancing}
							/>
						)}
					</div>
					<table>
						<thead>
							<tr className="rows self_financing_rows">
								<th>
									<span>
										{
											contents.ProjectSubventionInfos.tableSelFinancingHeader
												.origin
										}
									</span>
								</th>
								<th>
									<span>
										{
											contents.ProjectSubventionInfos.tableSelFinancingHeader
												.amount
										}
									</span>
								</th>
							</tr>
						</thead>
						<tbody>{autoFinancementHTML}</tbody>
					</table>
					<ReactTooltip
						place={'top'}
						effect={'solid'}
						className={'tooltipCustom dashboardTooltip test'}
						multiline={true}
					/>
				</div>
			</section>
			{editedSection.projectSubventionInfos && (
				<Buttons
					key={'209155a9-977c-4663-81ae-b72b9cd5f544'}
					customCSS={'editButtons'}
					handleSubmit={handleEditSubmit}
					handleCancel={editCancel}
				/>
			)}
			<AddSelfFinancingModal />
		</div>
	);
};

export default ProjectSubventionInfos;
