/**
 * Import modules
 */
import {
	Redirect,
	Route,
	Switch,
	useHistory,
	useLocation,
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';

/**
 * Import locals
 */
import './App.scss';
import { appInit, autoLogin } from './requests/userRequests';
import utility from './utility';
import config from './config.json';

/**
 * Import components
 */
import { Presentation } from './components/Presentation/Presentation';
import Header from './components/Header/Header';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import AskPasswordReset from './components/AskPasswordReset/AskPasswordReset';
import ResetPassword from './components/ResetPassword/ResetPassword';
import BackOffice from './components/BackOffice/BackOffice';
import NotFound from './components/NotFound/NotFound';
import { BackOfficeContextProvider } from './contexts/backOfficeContext';
import Contact from './components/Contact/Contact';

/**
 * Import Context
 */
import { useKeyContext } from './contexts/keyContext';
import { useUserContext } from './contexts/userContext';
import { useManagerAdminContext } from './contexts/ManagerAdminContext';

const { env } = config;

const PrivateRoute = ({ component: Component, authed, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) =>
				authed === true ? (
					<Component {...props} />
				) : (
					<Redirect to={{ pathname: '/login' }} />
				)
			}
		/>
	);
};

function App() {
	const { keySetter } = useKeyContext();
	const { setAdminKey } = useManagerAdminContext();
	const { user: loggedUser, setUser, initSetter } = useUserContext();

	const [edited, setEdited] = useState(true);
	const history = useHistory();
	const location = useLocation();

	/**
	 * Redirection vers prod
	 */

	useEffect(() => {
		if (
			env === 'dev' &&
			window.location.href.startsWith('https://presentation.so-fia.org')
		) {
			window.location.replace('https://so-fia.org');
		}
	}, []);

	const [, base, key1, key2] = location.pathname.split('/');
	const keyWord = loggedUser.roleId === 1 ? 'subventions' : 'projects';

	if (base === 'backoffice' && loggedUser.id && loggedUser.roleId !== 3) {
		keySetter(keyWord);
		history.push(`/dashboard/${keyWord}`);
	}

	if (base === 'dashboard' && loggedUser.id && loggedUser.roleId === 3) {
		keySetter('utilisateurs');
		history.push(`/backoffice/utilisateurs`);
	}

	keySetter(key1);
	if (key1 === 'admin' && loggedUser.id && !loggedUser.admin_right) {
		keySetter(keyWord);
		history.push(`/${base}/${keyWord}`);
	}
	if (key1 === 'admin') setAdminKey(key2);

	/**
	 * Auto login from token and set redirect if not logged
	 * Trigger: once
	 */
	useEffect(() => {
		(async () => {
			const location = history.location.pathname;
			if (!loggedUser.id) {
				const token = utility.getToken();
				if (token) {
					const tryToLog = await autoLogin();

					if (tryToLog.status === 200) {
						utility.setToken(tryToLog.token);

						await setUser({ ...tryToLog.data });
						switch (tryToLog.data.roleId) {
							case 1:
								keySetter('subventions');
								break;
							case 2:
								keySetter('projects');
								break;
							case 3:
								keySetter('utilisateurs');
								break;

							default:
								break;
						}
					} else {
						await utility.setToken();
						history.push('/login');
					}
				} else {
					if (
						!location.startsWith('/password-create') &&
						!location.startsWith('/ask-password-reset') &&
						!location.startsWith('/password-reset') &&
						location !== '/' &&
						location !== '/contact'
					) {
						history.push('/login');
					}
				}
			}
		})();
	}, []);

	/**
	 * Get init datas
	 * Trigger: loggedUser, edited
	 */
	useEffect(() => {
		if (edited && loggedUser.id) {
			(async () => {
				const data = await appInit();

				if (loggedUser.assos) {
					if (loggedUser.assos[0]) {
						await initSetter({
							...data.data,
							managers: loggedUser.assos[0].managers,
							recievers: loggedUser.assos[0].recievers,
						});
					}
				} else {
					await initSetter({
						...data.data,
						managers: [],
						projects: [],
						recievers: [],
					});
				}
				setEdited(false);
			})();
		}
	}, [loggedUser, edited]);

	const showHeader =
		location.pathname === '/' ? false : location.pathname !== '/contact';

	return (
		<div className="App">
			{showHeader && <Header />}
			<div className="Main">
				<Switch>
					<Route exact path="/">
						<Presentation />
					</Route>

					<Route exact path="/contact">
						<Contact />
					</Route>

					<Route exact path="/login">
						<Login setEdited={setEdited} />
					</Route>

					<PrivateRoute
						authed={!!loggedUser.id && loggedUser.role.title !== 'superAdmin'}
						path="/dashboard"
					>
						<Dashboard setEdited={setEdited} />
					</PrivateRoute>
					{/*
					<Route
						path="/dashboard"
					>
						<Dashboard
							setEdited={setEdited}
						/>
					</Route >
					*/}

					<Route path="/ask-password-reset">
						<AskPasswordReset />
					</Route>

					<Route path="/password-reset/:id/:token">
						<ResetPassword title={'Changement de mot de passe'} />
					</Route>

					<Route path="/password-create/:id/:token">
						<ResetPassword title={'Création du mot de passe'} />
					</Route>

					<PrivateRoute authed={!!loggedUser.id} path="/files" />

					<PrivateRoute
						authed={!!loggedUser.id && loggedUser.role.title === 'superAdmin'}
						path="/backoffice"
					>
						<BackOfficeContextProvider>
							<BackOffice />
						</BackOfficeContextProvider>
					</PrivateRoute>

					<Route>
						<NotFound />
					</Route>
				</Switch>
			</div>
		</div>
	);
}

export default App;
