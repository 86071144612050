// Import modules
import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import { FaAsterisk } from 'react-icons/fa';

// Import components
import Message from '../../Message/Message';
import Buttons from '../../Buttons/Buttons';

// Import contexts
import { useProjectContext } from '../../../contexts/projectContext';

// Import Hooks

// Import locals
import './AddProjectUserRelation.scss';
import contents from '../../../contents/contents.json';
import { addEmployeeToProject } from '../../../requests/projectsRequests';

const initNewRelation = {
	projectId: '',
	userId: '',
	duration: 0,
};

const initNewRelationValidation = {
	userId: false,
	duration: false,
};

export const AddProjectUserRelation = ({
	show,
	onHide,
	setShowAddModal,
	setEdited,
}) => {
	const { selectedProject, employees, projectEmployee } = useProjectContext();
	initNewRelation.projectId = selectedProject.id;

	const [newRelation, setNewRelation] = useState(initNewRelation);
	const [errorMessage, setErrorMessage] = useState(null);
	const [relationValidation, setRelationValidation] = useState(
		initNewRelationValidation
	);

	const handleSubmit = async (evt) => {
		evt.preventDefault();
		const testValidity = Object.values(relationValidation).indexOf(false);

		if (testValidity >= 0) {
			let property;
			switch (Object.entries(relationValidation)[testValidity][0]) {
				case 'last_name':
					property = 'Le nom';
					break;
				case 'first_name':
					property = 'Le prénom';
					break;
				case 'email':
					property = "L'email";
					break;
				case 'phone':
					property = 'Le téléphone';
					break;
				case 'roleId':
					property = 'Le role';
					break;
				case 'association_id':
					property = "L'association";
					break;

				default:
					property = Object.entries(relationValidation)[testValidity][0];
			}
			setErrorMessage(`${property} ${contents.messages.propertyError}`);
			return;
		}
		const result = await addEmployeeToProject(newRelation);
		console.log('[AddProjectUserRelation.jsx]{L:74} result: ', result);
		switch (result.status) {
			case 409:
				setErrorMessage(contents.messages.duplicateMailError);
				break;

			case 404:
				history.push('/404');
				break;

			case 200:
				await setNewRelation(initNewRelation);
				await setErrorMessage(null);
				await setErrorMessage(null);
				await setShowAddModal(false);
				setEdited(true);
				break;

			default:
				setErrorMessage(contents.messages.duplicateMailError);
				break;
		}
	};

	const handleInputChange = (evt) => {
		const { id, value } = evt.target;
		setErrorMessage(null);
		setNewRelation({ ...newRelation, [id]: parseInt(value) });
		value > 0
			? setRelationValidation({ ...relationValidation, [id]: true })
			: setRelationValidation({ ...relationValidation, [id]: false });
	};

	const handleClickCancel = async (evt) => {
		evt.preventDefault();
		await setNewRelation(initNewRelation);
		await setErrorMessage(null);
		await setShowAddModal(!show);
	};

	const filteredEmployees = employees?.filter(
		(employee) =>
			!projectEmployee?.some((projectUser) => projectUser.id === employee.id)
	);

	return (
		<Modal
			key={'d1643303-d6fe-46f2-8d39-5d58be8fdf50'}
			show={show}
			onHide={onHide}
			size={'xl'}
			aria-labelledby={'modals-content-option'}
			centered
			restoreFocus
			dialogClassName={'add-modals sm-modal'}
		>
			<Modal.Header closeButton>
				<Modal.Title>{contents.titles.addProjectUser}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="modal__body">
					<Message status={'error'} message={errorMessage} />

					<Form
						key={'bcb3ce41-dd62-4299-8e22-9314a0011e55'}
						onSubmit={handleSubmit}
					>
						<fieldset>
							<Form.Group className="mb-3 form-role" controlId="userId">
								<Form.Label>
									{contents.labels.employee}
									<FaAsterisk size={10} />
								</Form.Label>
								<Form.Select
									type="text"
									placeholder={contents.placeholders.employee}
									onChange={handleInputChange}
									value={newRelation.roleId}
								>
									<option key={'05af2618-af21-4b7d-a2c8-0961b4df7add'}>
										{contents.placeholders.employee}
									</option>
									{filteredEmployees?.map((employee, i) => (
										<EmployeeOption key={i + employee.id} employee={employee} />
									))}
								</Form.Select>
							</Form.Group>

							<Form.Group className="mb-3 form-role" controlId="duration">
								<Form.Label>
									{contents.labels.duration}
									<FaAsterisk size={10} />
								</Form.Label>
								<Form.Control
									type="number"
									onChange={handleInputChange}
									value={newRelation.duration}
								/>
							</Form.Group>
						</fieldset>

						<fieldset className={'new-user-buttons'}>
							<Buttons
								submitTitle={contents.buttons.save}
								handleCancel={handleClickCancel}
							/>
						</fieldset>
					</Form>
				</div>
			</Modal.Body>
		</Modal>
	);
};

const EmployeeOption = ({ employee }) => {
	return (
		<option key={employee.id} value={employee.id}>
			{`${employee.first_name} ${employee.last_name}`}
		</option>
	);
};
