/**
 * Import modules
 */
import React from 'react';

/**
 * Import components
 */
import Buttons from '../Buttons/Buttons';

/**
 * Import Contexts
 */
import { useSubventionContext } from '../../contexts/subventionContext';

/**
 * Import locals
 */
import './SubventionReminder.scss';
import contents from '../../contents/contents.json';

const SubventionReminder = ({ editable, handleEditSubmit }) => {
	const {
		selectedSubvention,
		editedSubvention,
		setEditedSubvention,
		editedSection,
		setEditedSection,
		editCancel,
	} = useSubventionContext();

	const handleInputChange = async (evt) => {
		const { id, value } = evt.target;
		await setEditedSubvention({
			...editedSubvention,
			[id]: value,
		});
		await setEditedSection({ ...editedSection, subReminder: true });
	};

	return (
		<div className={'SubventionReminder'}>
			<section
				key={'b6dde0d5-edf3-4f31-9d82-77151f69aca0'}
				className={'sub-reminder-infos'}
			>
				<div
					key={'96f6710e-2ea2-48aa-8365-0ffe6b88a305'}
					className="reminder-item-delais"
				>
					{contents.titles.SubventionReminder.delay}
				</div>
				<div
					key={'4ad86ac1-2702-457c-82d6-7d13a2bb7504'}
					className="reminder-item-rappel"
				>
					{contents.titles.SubventionReminder.remind}
				</div>
				<div
					key={'0751ac56-db29-4b90-a742-4c145ffa1c0c'}
					className="reminder-item-expense"
				>
					{contents.titles.SubventionReminder.expense}
				</div>
				{!editable ? (
					<>
						<span
							key={'35382d25-08b9-4b7c-9bf0-0388b358770e'}
							className="reminder-item-expense_reciepts_date"
						>
							{selectedSubvention.expense_receipts.date}
						</span>
						<span
							key={'e9b4e482-248b-4a00-99ff-683348c6b3e7'}
							className="reminder-expense-item-limit"
						>
							{selectedSubvention.expense_receipts.time_limit}
						</span>
						<span
							key={'301828cf-e500-438c-995a-ac5d730b5b5d'}
							className={'reminder-item-expense-type'}
							id="expense_date_type"
						>
							{selectedSubvention.expense_receipts.time_type}
						</span>
					</>
				) : (
					<>
						<input
							id={'expense_reciepts_date'}
							type="date"
							onChange={handleInputChange}
							value={editedSubvention.expense_receipts.date}
						/>
						<input
							key={'4cfaa6a0-00d7-4ecb-8113-9ab06ce72888'}
							className="reminder-expense-item-limit"
							type="number"
							id={'expense_reciepts_time_limit'}
							data-section={'subReminder'}
							value={editedSubvention.expense_receipts.time_limit}
							onChange={handleInputChange}
						/>
						<select
							key={'0f0188d3-f967-4d65-b265-95c650393b9a'}
							name="expense_date_type"
							className={'reminder-item-expense-type'}
							id="expense_reciepts_time_type"
							data-section={'subReminder'}
							autoComplete="true"
							onChange={handleInputChange}
							value={editedSubvention.expense_receipts.time_type}
						>
							{contents.remindOptions.map((option, i) => (
								<option key={i + option} value={option}>
									{option}
								</option>
							))}
						</select>
					</>
				)}
				<div
					key={'2bc74986-8454-4fa3-9476-bc48ac3fecbe'}
					className="expense-text"
				>
					avant
				</div>

				<div
					key={'dc92c593-d7f7-4678-a058-1cfca37c47e9'}
					className="reminder-item-balance"
				>
					{contents.titles.SubventionReminder.balance}
				</div>
				{!editable ? (
					<>
						<span
							key={'49341678-fb50-4f51-9fc3-66a4bb8f6ac0'}
							className="reminder-item-balance_submission_date"
						>
							{selectedSubvention.balance_submission.date}
						</span>
						<span
							key={'ccf97872-87f6-4591-b015-f424201ba2e2'}
							className="reminder-balance-item-limit"
						>
							{selectedSubvention.balance_submission.time_limit}
						</span>
						<span
							key={'7830b3fe-2f18-479a-9127-68f724f67496'}
							className={'reminder-item-balance-type'}
							id="expense_date_type"
						>
							{selectedSubvention.balance_submission.time_type}
						</span>
					</>
				) : (
					<>
						<input
							id={'balance_submission_date'}
							type="date"
							onChange={handleInputChange}
							value={editedSubvention.balance_submission.date}
						/>

						<input
							key={'8bc69ab2-e180-4ce7-9328-9847774c7090'}
							type="number"
							className="reminder-balance-item-limit"
							id={'balance_submission_time_limit'}
							data-section={'subReminder'}
							value={editedSubvention.balance_submission.time_limit}
							onChange={handleInputChange}
						/>
						<select
							key={'c2f2d6b9-add0-447e-bbd6-43841c52c368'}
							name="expense_date_type"
							className={'reminder-item-balance-type'}
							id="balance_submission_time_type"
							data-section={'subReminder'}
							onChange={handleInputChange}
							autoComplete="true"
							value={editedSubvention.balance_submission.time_type}
						>
							{contents.remindOptions.map((option, i) => (
								<option key={i + option} value={option}>
									{option}
								</option>
							))}
						</select>
					</>
				)}
				<div
					key={'e99cd0b5-78b6-4d1c-8a65-7dcc6ff7d013'}
					className="balance-text"
				>
					avant
				</div>
			</section>

			{editedSection.subReminder && (
				<Buttons
					key={'2faaefce-b809-4dce-9b48-18403340c810'}
					customCSS={'editButtons'}
					handleSubmit={handleEditSubmit}
					handleCancel={editCancel}
				/>
			)}
		</div>
	);
};

export default SubventionReminder;
