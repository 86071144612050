/**
 * Import modules
 */
import React, { useState } from 'react';
import { ImPlus } from 'react-icons/im';
import { GoPencil, GoX } from 'react-icons/go';
import { BiSave, BiTrash } from 'react-icons/bi';
import Switch from 'react-switch';

/**
 * Import components
 */
import AddParticipantModal from './modals/AddParticipantModal';

/**
 * Import contexts
 */
import { useUserContext } from '../../contexts/userContext';
import { useProjectContext } from '../../contexts/projectContext';

/**
 * Import locals
 */
import './ProjectParticipants.scss';
import contents from '../../contents/contents.json';
import utlis from '../../utility';
import {
	deleteBeneficiaryProject,
	editBeneficiary,
	editBeneficiaryProject,
} from '../../requests/beneficiaryRequests';
import { getOneProjectListById } from '../../requests/projectsRequests';
import Message from '../Message/Message';

const ProjectParticipants = () => {
	// const { user } = useUserContext();
	const { participants, switchShowAddParticipantModal } = useProjectContext();

	const [editMode, setEditMode] = useState(false);

	const participantHTML = participants.map((participant, i) => (
		<ParticipantRow
			key={i}
			participant={participant}
			classes={'table-row'}
			editMode={editMode}
		/>
	));

	const handleSwitchEditMode = async () => {
		setEditMode(!editMode);
	};

	return (
		<div className={'ProjectParticipants'}>
			<section className={'project-participants'}>
				<div className="header">
					<h3>{contents.titles.ProjectParticipants}</h3>
					<ImPlus
						onClick={switchShowAddParticipantModal}
						data-tip={contents.toolTipDatas.ProjectParticipants}
					/>
					<GoPencil onClick={handleSwitchEditMode} />
				</div>
				{participants.length > 0 ? (
					<table>
						<thead className={'participants-title'}>
							<ParticipantRow
								key={'7aef0e81-656c-47ed-bf4b-97ea8b7190b4'}
								participant={contents.ParticipantRow.tableHeader}
								classes={'table-row'}
								isTitle={true}
								editMode={editMode}
							/>
						</thead>
						<tbody className={'participants-list'}>{participantHTML}</tbody>
					</table>
				) : (
					<p className={'no-participant'}>{contents.noParticipant}</p>
				)}
			</section>
			<AddParticipantModal />
		</div>
	);
};

export default ProjectParticipants;

const ParticipantRow = ({
	participant,
	classes,
	editMode,
	isTitle = false,
}) => {
	const {
		selectedProject,
		setSelectedProject,
		setEditedProject,
		setParticipants,
	} = useProjectContext();
	const { user } = useUserContext();
	const [editedBeneficiary, setEditedBeneficiary] = useState(participant);
	const [error, setError] = useState(null);

	const handleInputChange = async (evt) => {
		evt.preventDefault();
		setError(null);
		const { value, name, checked } = evt.target;

		if (name === 'presence') {
			setEditedBeneficiary({ ...editedBeneficiary, presence: checked });
		} else {
			setEditedBeneficiary({ ...editedBeneficiary, [name]: value });
		}
	};

	const handleSwitchPresence = async (checked) => {
		setEditedBeneficiary({ ...editedBeneficiary, presence: checked });
	};

	const handleSubmit = async (evt) => {
		evt.preventDefault();

		const updatedBeneficiary = await editBeneficiary({
			...editedBeneficiary,
		});
		if (updatedBeneficiary.status !== 200) {
			setError(updatedBeneficiary.message);
			return;
		}

		const updatedBeneficiaryProject = await editBeneficiaryProject({
			id: editedBeneficiary.m2m_id,
			beneficiary_id: editedBeneficiary.id,
			project_id: selectedProject.id,
			presence: editedBeneficiary.presence,
		});
		if (updatedBeneficiaryProject.status !== 200) {
			setError(updatedBeneficiaryProject.message);
			return;
		}

		const updatedProject = await getOneProjectListById(selectedProject.id);

		if (updatedProject.status !== 200) {
			setError(updatedProject.message);
			return;
		}

		setSelectedProject(updatedProject.data);
		setEditedProject(updatedProject.data);
		setParticipants(updatedProject.data.beneficiaries);
	};

	const handleCancel = async () => {
		setEditedBeneficiary(participant);
	};

	const handleDeleteBeneficiary = async () => {
		await deleteBeneficiaryProject(selectedProject.id, participant.id);

		const updatedProject = await getOneProjectListById(selectedProject.id);
		setSelectedProject(updatedProject.data);
		setEditedProject(updatedProject.data);
		setParticipants(updatedProject.data.beneficiaries);
	};

	const age = utlis.calculateAge(participant.birthday);

	return (
		<>
			{error && <Message className={``} message={error} status={`error`} />}
			<tr className={classes}>
				{isTitle && (
					<>
						<th>{participant.last_name}</th>
						<th>{participant.first_name}</th>
						<th>{participant.email}</th>
						<th className={`not-displayed`}>
							{editMode && user.project_modify_right
								? 'Date de naissance'
								: 'Age'}
						</th>
						<th className={`not-displayed`}>{participant.address}</th>
						<th className={`not-displayed`}>{participant.zipcode}</th>
						<th className={`not-displayed`}>{participant.city}</th>
						<th>{participant.present}</th>
					</>
				)}
				{!isTitle && !editMode && (
					<>
						<th>{participant.last_name}</th>
						<th>{participant.first_name}</th>
						<th>{participant.email}</th>
						<th className={`not-displayed`}>{`${age} ans`}</th>
						<th className={`not-displayed`}>{participant.address}</th>
						<th className={`not-displayed`}>{participant.zipcode}</th>
						<th className={`not-displayed`}>{participant.city}</th>
						<th>{participant.presence ? 'Oui' : 'Non'}</th>
					</>
				)}
				{!isTitle && editMode && (
					<>
						<th>
							{user.project_modify_right ? (
								<input
									type="text"
									name="last_name"
									value={editedBeneficiary.last_name}
									onChange={handleInputChange}
								/>
							) : (
								participant.last_name
							)}
						</th>
						<th>
							{user.project_modify_right ? (
								<input
									type="text"
									name="first_name"
									value={editedBeneficiary.first_name}
									onChange={handleInputChange}
								/>
							) : (
								participant.first_name
							)}
						</th>
						<th>
							{user.project_modify_right ? (
								<input
									type="text"
									name="email"
									value={editedBeneficiary.email}
									onChange={handleInputChange}
								/>
							) : (
								participant.email
							)}
						</th>
						<th className={`not-displayed`}>
							{user.project_modify_right ? (
								<input
									type="date"
									name="birthday"
									value={editedBeneficiary.birthday}
									onChange={handleInputChange}
								/>
							) : (
								`${age} ans`
							)}
						</th>
						<th className={`not-displayed`}>
							{user.project_modify_right ? (
								<input
									type="text"
									name="address"
									value={editedBeneficiary.address}
									onChange={handleInputChange}
								/>
							) : (
								participant.address
							)}
						</th>
						<th className={`not-displayed`}>
							{user.project_modify_right ? (
								<input
									type="text"
									name="zipcode"
									value={editedBeneficiary.zipcode}
									onChange={handleInputChange}
								/>
							) : (
								participant.zipcode
							)}
						</th>
						<th className={`not-displayed`}>
							{user.project_modify_right ? (
								<input
									type="text"
									name="city"
									value={editedBeneficiary.city}
									onChange={handleInputChange}
								/>
							) : (
								participant.city
							)}
						</th>
						<th>
							<Switch
								height={20}
								className={'switch'}
								checked={editedBeneficiary.presence}
								onChange={handleSwitchPresence}
							/>
						</th>

						{user.project_modify_right && (
							<th className={`beneficiary-buttons`}>
								<BiSave onClick={handleSubmit} />
								<GoX onClick={handleCancel} />
								<BiTrash onClick={handleDeleteBeneficiary} />
							</th>
						)}
					</>
				)}
			</tr>
		</>
	);
};
