/**
 * Import modules
 */
import { Link, useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { BiShow, BiHide } from 'react-icons/bi';

/**
 * Import Modules
 */
import ButtonLoading from '../ButtonLoading/ButtonLoading';
import Message from '../Message/Message';

/**
 * Import Contexts
 */
import { useLoadingContext } from '../../contexts/loadingContext';
import { useKeyContext } from '../../contexts/keyContext';
import { useUserContext } from '../../contexts/userContext';

/**
 * Import locals
 */
import './Login.scss';
import { login } from '../../requests/userRequests';
import utility from '../../utility';
import contents from '../../contents/contents.json';

const Login = ({ setEdited }) => {
	const history = useHistory();

	const { actions } = useLoadingContext();
	const { keySetter } = useKeyContext('');
	const { setUser } = useUserContext();

	const [user, setUser2] = useState({ email: '', password: '' });
	const [showPassword, setShowPassword] = useState(false);
	const [message, setMessage] = useState('');

	const handleInputChange = async (evt) => {
		await setUser2({ ...user, [evt.target.id]: evt.target.value });
		await setMessage('');
	};

	const handleClickEyes = async () => {
		await setShowPassword(!showPassword);
	};

	const handleSubmit = async (evt) => {
		evt.preventDefault();
		actions.setter(true, true);
		const result = await login(user);

		if (!result) {
			actions.setter(false, true);
		}

		if (result.status !== 200) {
			setMessage(result.message);

			if (result.status === 404) {
				history.push('/404');
			}
		} else {
			utility.setToken(result.token);
			await setUser({ ...result.data });
			await setEdited(true);

			switch (result.data.role.title) {
				case 'admin':
					keySetter('subventions');
					history.push('/dashboard');
					break;
				case 'manager':
					keySetter('projects');
					history.push('/dashboard');
					break;
				case 'employee':
					keySetter('projects');
					history.push('/dashboard');
					break;
				case 'superAdmin':
					keySetter('utilisateurs');
					history.push('/backoffice/utilisateurs');
					break;

				default:
					break;
			}
		}
		actions.setter(false, true);
	};

	return (
		<div className={'PageLogin'}>
			<div className="container">
				<h1 className={'title'}>{contents.titles.Login}</h1>
				<form className={'login-form'} onSubmit={handleSubmit}>
					<Message message={message} />
					<label htmlFor="email">{contents.labels.mail}</label>
					<input
						type="text"
						id={'email'}
						name={'email'}
						value={user.email}
						onChange={handleInputChange}
					/>
					<label htmlFor="password">{contents.labels.password}</label>
					<input
						type={showPassword ? 'text' : 'password'}
						id={'password'}
						name={'password'}
						value={user.password}
						onChange={handleInputChange}
					/>
					{showPassword ? (
						<BiShow
							className={'input-eyes'}
							data-set={'password'}
							onClick={handleClickEyes}
						/>
					) : (
						<BiHide
							className={'input-eyes'}
							data-set={'password'}
							onClick={handleClickEyes}
						/>
					)}
					<Link
						to={`/ask-password-reset`}
						key={`ask-password-reset`}
						className="ask-password-reset"
					>
						{contents.links.forgotPassword}
					</Link>
					<div className="button-container">
						<ButtonLoading title={'Se connecter'} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default Login;
