/**
 * Import modules
 */
import React from 'react';
import { Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

/**
 * Import components
 */
import Buttons from '../../Buttons/Buttons';
import Message from '../../Message/Message';

/**
 * Import contexts
 */
import { useProjectContext } from '../../../contexts/projectContext';
import { useLoadingContext } from '../../../contexts/loadingContext';

/**
 * Import locals
 */
import './DeleteProjectModal.scss';
import { deleteProject } from '../../../requests/projectsRequests';
import contents from '../../../contents/contents.json';

const DeleteProjectModal = () => {
	const { actions } = useLoadingContext();
	const {
		selectedProject,
		showDeleteProjectModal,
		switchShowDeleteProjectModal,
	} = useProjectContext();

	const history = useHistory();

	const handleValidDeleteSub = async (evt) => {
		actions.setter(true, true);
		evt.preventDefault();
		const result = await deleteProject(selectedProject.id);

		if (result.data !== 1) {
			//error
		} else {
			switchShowDeleteProjectModal();
			history.push('/dashboard');
		}
		actions.setter(false, true);
	};

	return (
		<Modal
			key={'57631305d-fb5a-4453-9967-342d77064815'}
			show={showDeleteProjectModal}
			onHide={switchShowDeleteProjectModal}
			size={'l'}
			aria-labelledby={'DeleteProjectModal'}
			centered
			restoreFocus
			dialogClassName={'DeleteProjectModal'}
		>
			<Modal.Header
				closeButton
			>
				<Modal.Title >{contents.titles.DeleteProjectModal}</Modal.Title >
			</Modal.Header >
			<Modal.Body >
				<Message />
				<span
					className='advertizing'
				>Attention opération irréversible</span >

				<Buttons
					key={'9c174b9a-4f77-44bd-9703-21da7d40ff54'}
					customCSS={'editButtons'}
					handleSubmit={handleValidDeleteSub}
					handleCancel={switchShowDeleteProjectModal}
				/>
			</Modal.Body >
		</Modal >
	);
};

export default DeleteProjectModal;
