/**
 * Import modules
 */
import React, { useState } from 'react';

/**
 * Import components
 */
import Message from '../../Message/Message';
import Buttons from '../../Buttons/Buttons';

/**
 * Import Contexts
 */
import { useLoadingContext } from '../../../contexts/loadingContext';

/**
 * Import locals
 */
import '../AssociationDetail.scss';
import { Form, Modal } from 'react-bootstrap';
import { updateAssociation } from '../../../requests/AdminRequest';
import contents from '../../../contents/contents.json';

const AssociationDetailModal = ({ item, setEdited, editMode, setEditMode }) => {
	const { actions } = useLoadingContext();
	const [association, setAssociation] = useState(item);
	const [editedAssociation, setEditedAssociation] = useState(association);

	const [errorMessage, setErrorMessage] = useState(null);

	const handleCloseEditModal = async () => {
		await setEditMode(!editMode);
	};

	const handleInputChange = async (evt) => {
		setErrorMessage(null);
		const editAssos = { ...editedAssociation };
		const { id, value } = evt.target;

		editAssos[id] = value;

		setEditedAssociation(editAssos);
	};

	const handleClickCancel = async (evt) => {
		evt.preventDefault();
		await setEditMode(!editMode);
		await setEditedAssociation(association);
	};

	const handleSubmit = async (evt) => {
		evt.preventDefault();
		actions.setter(true, true);

		const res = await updateAssociation(editedAssociation);

		if (res.status !== 200) {
			setErrorMessage(res?.message);
		} else {
			await setAssociation(res.data);
			await setEditedAssociation(res.data);
			await setEditMode(false);
			await setEdited(true);
		}
		actions.setter(false, true);
	};

	const statusOptionsHTML = ['active', 'inactive'].map((status, i) => (
		<option
			key={i + status}
			value={status}
		>
			{status === 'active'
				? contents.options.active
				: contents.options.inactive}
		</option >
	));

	return (
		<Modal
			key={'e05cc63c-957b-4aeb-bfbf-a65ebd0034b5'}
			show={editMode}
			onHide={handleCloseEditModal}
			size={'xl'}
			aria-labelledby={'modals-content-option'}
			centered
			restoreFocus
			dialogClassName={'edit-association-modals'}
		>
			<Modal.Header >
				<h2 >{contents.titles.AssociationDetailModal.title}</h2 >
			</Modal.Header >
			<Modal.Body >
				<Message
					status={'error error_message'}
					message={errorMessage}
				/>
				<Form
					onSubmit={handleSubmit}
				>
					<fieldset
						className={'edit-association'}
					>
						<Form.Group
							className="mb-3 form-name"
							controlId="name"
						>
							<Form.Label >{contents.labels.lastname}</Form.Label >
							<Form.Control
								type="text"
								placeholder={contents.placeholders.lastname}
								onChange={handleInputChange}
								value={editedAssociation.name}
							/>
						</Form.Group >

						<Form.Group
							className="mb-3 form-address"
							controlId="address"
						>
							<Form.Label >{contents.labels.address}</Form.Label >
							<Form.Control
								type="text"
								placeholder={contents.placeholders.address}
								onChange={handleInputChange}
								value={editedAssociation.address}
							/>
						</Form.Group >

						<Form.Group
							className="mb-3 form-zipcode"
							controlId="zipcode"
						>
							<Form.Label >{contents.labels.zipCode}</Form.Label >
							<Form.Control
								type="text"
								placeholder={contents.placeholders.zipCode}
								onChange={handleInputChange}
								value={editedAssociation.zipcode}
							/>
						</Form.Group >

						<Form.Group
							className="mb-3 form-city"
							controlId="city"
						>
							<Form.Label >{contents.labels.city}</Form.Label >
							<Form.Control
								type="text"
								placeholder={contents.placeholders.city}
								onChange={handleInputChange}
								value={editedAssociation.city}
							/>
						</Form.Group >

						<Form.Group
							className="mb-3 status"
							controlId="status"
						>
							<Form.Label >{contents.labels.status}</Form.Label >
							<Form.Select
								type="text"
								placeholder={contents.placeholders.status}
								onChange={handleInputChange}
								value={editedAssociation.status}
							>
								{statusOptionsHTML}
							</Form.Select >
						</Form.Group >
					</fieldset >

					<fieldset
						className={'edit-user-buttons'}
					>
						<Buttons
							submitTitle={contents.buttons.save}
							handleCancel={handleClickCancel}
						/>
					</fieldset >
				</Form >
			</Modal.Body >
		</Modal >
	);
};

export default AssociationDetailModal;
