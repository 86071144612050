/**
 * Import modules
 */
import React from 'react';
import { NumericFormat } from 'react-number-format';

/**
 * Import context
 */

/**
 * Import locals
 */
import './TotalCard.scss';

export const TotalCard = ({
	title,
	value,
	customCss = '',
	suffix = '',
	decimals = false,
}) => {
	const floatValue = parseFloat(value);

	return (
		<div className={`TotalCard ${customCss}`}>
			<label>{title}:</label>
			<NumericFormat
				value={floatValue}
				displayType={'text'}
				thousandSeparator={' '}
				suffix={suffix}
				decimalScale={2}
				fixedDecimalScale={decimals}
			/>
		</div>
	);
};
