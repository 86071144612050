/**
 * Import modules
 */
import React, { useEffect, useState } from 'react';
import { ImPlus } from 'react-icons/im';

/**
 * Import components
 */
import { AddUserModal } from '../../../../UserDetail/AddUserModal';

/**
 * Import Contexts
 */
import { useManagerAdminContext } from '../../../../../contexts/ManagerAdminContext';
import { BackOfficeContextProvider } from '../../../../../contexts/backOfficeContext';

/**
 * Import locals
 */
import './UsersTab.scss';
import UsersList from '../../../../UsersList/UsersList';
import { getAllUsers } from '../../../../../requests/userRequests';

const UsersTab = () => {
	const { userList, setUserList } =
		useManagerAdminContext();

	const [edited, setEdited] = useState(true);
	const [showAddModal, setShowAddModal] = useState(false);

	const handleSwitchAddModal = () => setShowAddModal(!showAddModal);



	useEffect(() => {
		if (edited) {
			(async () => {
				const userList = await getAllUsers();
				setUserList(userList.data);
				setEdited(false);
			})();
		}
	}, [edited]);

	return (
		<div className={'UsersTab'}>
			<BackOfficeContextProvider>
				<div className={`User-container`}>
					<ImPlus
						key={'1422c4d2-e893-4c99-921d-c22951a439ef'}
						id={'tiersAccount'}
						onClick={handleSwitchAddModal}
						size={22}
						color={'#47C8F4'}
						data-tip={'Ajouter un compte tiers'}
					/>
					<UsersList users={userList} setEdited={setEdited} />
				</div>
			</BackOfficeContextProvider>
			<AddUserModal
				show={showAddModal}
				onHide={handleSwitchAddModal}
				setShowAddModal={setShowAddModal}
				setEdited={setEdited}
			/>
		</div>
	);
};

export default UsersTab;
