/**
 * Import modules
 */
import React from 'react';
import { Modal } from 'react-bootstrap';

/**
 * Import components
 */
import Buttons from '../../Buttons/Buttons';

/**
 * Import contexts
 */
import { useProjectContext } from '../../../contexts/projectContext';

/**
 * Import locals
 */
import './AddSelfFinancingModal.scss';
import contents from '../../../contents/contents.json';

const AddSelfFinancingModal = () => {
	const {
		editedSection,
		setEditedSection,
		editedProject,
		setEditedProject,
		showAddSelfModal,
		switchShowAddSelfModal,
		newSelf,
		setNewSelf,
		editCancel,
	} = useProjectContext();

	const handleChangeModalSelf = async (evt) => {
		const { value, name } = evt.target;
		const editedSelf = { ...newSelf };
		editedSelf[name] =
			value === '' ? '' : isNaN(value) ? value : parseFloat(value);

		setNewSelf(editedSelf);
	};

	const handleAddSelfSubmit = (evt) => {
		evt.preventDefault();
		setEditedProject({
			...editedProject,
			self_financings: [...editedProject.self_financings, newSelf],
		});
		setEditedSection({ ...editedSection, projectSubventionInfos: true });
		setNewSelf({ ...newSelf, origin: '', amount: '' });
		switchShowAddSelfModal();
	};

	return (
		<Modal
			key={'e0d1bf2c-2435-4b27-93d5-e4a082470e8b'}
			show={showAddSelfModal}
			onHide={switchShowAddSelfModal}
			size={'l'}
			aria-labelledby={'mAddSelfFinancingModal'}
			centered
			restoreFocus
			dialogClassName={'AddSelfFinancingModal'}
		>
			<Modal.Header
				closeButton
			>
				<Modal.Title >{contents.titles.AddSelfFinancingModal}</Modal.Title >
			</Modal.Header >
			<Modal.Body
				bsPrefix="addSelfBody modals-body"
			>
				<form
					onSubmit={handleAddSelfSubmit}
				>
					<div
						key={'bbdacfbb-f2c4-48c5-993b-a05437a2a44b'}
						className="addSelfContainer selfOrigin"
					>
						<label
							key={'f3465a88-9802-4815-9e6a-9d6804469582'}
							htmlFor="origin"
						>
							{contents.labels.origin}
						</label >
						<input
							key={'573b85b2-d75c-4abf-ac83-2f31102b5187'}
							type="text"
							name="origin"
							id="origin"
							value={newSelf.origin}
							onChange={handleChangeModalSelf}
						/>
					</div >
					<div
						key={'299cdc9a-436d-42c4-86cc-fa4ae26270d6'}
						className="addSelfContainer selfAmount"
					>
						<label
							key={'24cef750-db17-4190-a8bb-3ac8e51257a9'}
							htmlFor="amount"
						>
							{contents.labels.amount}
						</label >
						<input
							key={'548aab13-3d5f-4a91-85c5-6f74dbf0c9f4'}
							type="number"
							name="amount"
							id="amount"
							value={newSelf.amount}
							onChange={handleChangeModalSelf}
						/>
					</div >

					<Buttons
						key={'028303fa-2bb2-481f-a32c-df3937b04366'}
						customCSS={'editButtons'}
						handleCancel={editCancel}
					/>
				</form >
			</Modal.Body >
		</Modal >
	);
};

export default AddSelfFinancingModal;
