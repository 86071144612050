import React, { createContext, useContext, useEffect, useState } from 'react';

// context creation
export const formContext = createContext('');

// context hook
export const useDashboardContext = () => {
	const context = useContext(formContext);
	if (!context) {
		console.error(
			'DashboardContext should not be undefined. Did you forget to wrap your component with DashboardContextProvider ?'
		);
	}
	return context;
};

// provider creation
export const DashboardContextProvider = ({ children }) => {
	const initialNewSubvention = {
		ref: 'Choisir une date de début',
		subventionType: null,
		subventionName: '',
		subventionFunder: null,
		subventionManager: null,
		startDate: '',
		endDate: '',
		duration: 0,
		nDuration: 0,
		askedAmount: null,
		contractedAmount: null,
		associationId: null,
		internalRef: '',
		description: '',
	};

	const initialNewProject = {
		projectAnalyticCode: '',
		projectName: '',
		description: '',
		etat: 'Non commencé',
		startDate: '',
		endDate: '',
		uniqueKey: null,
		manager: { first_name: '', last_name: '', phone: '', email: '' },
		associationId: null,
	};
	// Subvention
	const [newSubvention, setNewSubvention] = useState(initialNewSubvention);
	const [NewSubventionMeetRequierements, setNewSubventionMeetRequierements] =
		useState(false);
	const [showCreateSubventionModal, setShowCreateSubventionModal] =
		useState(false);
	const [showDuplicateModal, setShowDuplicateModal] = useState(false);

	// Project
	const [newProject, setNewProject] = useState(initialNewProject);
	const [newProjectMeetRequierements, setNewProjectMeetRequierements] =
		useState(false);
	const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);

	const cancelCreate = () => {
		setNewSubvention(initialNewSubvention);
		setNewProject(initialNewProject);
		setShowCreateSubventionModal(false);
		setShowCreateProjectModal(false);
	};

	const switchShowCreateModal = (key) => {
		switch (key) {
			case 'subventions':
				setShowCreateSubventionModal(!showCreateSubventionModal);
				break;
			case 'projects':
				setShowCreateProjectModal(!showCreateProjectModal);
				break;
			default:
				break;
		}
	};

	const switchShowDuplicateModal = () => {
		setShowDuplicateModal(!showDuplicateModal);
	};

	useEffect(() => {
		setNewSubventionMeetRequierements(
			newSubvention.subventionName !== '' &&
				newSubvention.subventionFunder !== null &&
				newSubvention.startDate !== '' &&
				newSubvention.endDate !== ''
		);
	}, [newSubvention]);

	useEffect(() => {
		setNewProjectMeetRequierements(
			newProject.projectAnalyticCode !== '' &&
				newProject.projectName !== '' &&
				newProject.startDate !== null &&
				newProject.endDate !== null
		);
	}, [newProject]);

	let value = {
		newSubvention,
		setNewSubvention,
		NewSubventionMeetRequierements,
		cancelCreate,
		showCreateSubventionModal,
		setShowCreateSubventionModal,
		newProject,
		setNewProject,
		newProjectMeetRequierements,
		showCreateProjectModal,
		setShowCreateProjectModal,
		showDuplicateModal,
		actions: {
			switchShowDuplicateModal,
			switchShowCreateModal,
		},
	};

	return <formContext.Provider
		value={value}
	       >{children}</formContext.Provider>;
};
