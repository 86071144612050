/**
 * Import modules
 */
import React, { useState, useEffect } from 'react';
import { ImPlus } from 'react-icons/im';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { BiPencil } from 'react-icons/bi';

/**
 * Import components
 */
import { FunderCard } from './FunderCard';
import { WebSites } from './WebSite';
import { AddWebSiteModal } from './modals/AddWebSiteModal';

/**
 * Import Contexts
 */
import { useUserContext } from '../../../../../contexts/userContext';
import { useWebSiteContext } from '../../../../../contexts/webSiteContext';

/**
 * Import locals
 */
import './WebsitePage.scss';
import contents from '../../../../../contents/contents.json';

export const WebsitePage = () => {
	const { init } = useUserContext();
	const { selectedItem, actions } = useWebSiteContext();
	const [editMode, setEditMode] = useState(false);

	const switchEditMode = () => {
		setEditMode(!editMode);
	};

	const [addMode, setAddMode] = useState(false);

	const switchAddMode = () => {
		setAddMode(!addMode);
	};

	useEffect(() => {
		if (
			init &&
			init.FunderList &&
			init.FunderList.length > 0 &&
			!selectedItem.id
		) {
			actions.setSelectedItem(init?.FunderList[0]);
		}
	}, [init.FunderList]);

	return (
		<div className={'WebsitePage'}>
			<ul className={`funderList`}>
				{init.FunderList.map((item, index) => (
					<FunderCard funder={item} key={index} />
				))}
			</ul>
			<WebSites editMode={editMode} />

			<div className={`buttons`}>
				<ImPlus
					key={'b51394ec-926e-492c-b37a-3acdf627b0ee'}
					onClick={switchAddMode}
					size={25}
					color={'#47C8F4'}
					data-tip={contents.toolTipDatas.tiersAccount}
				/>

				<BiPencil
					key={'2ec0d0ce-0ecd-43b9-8bdb-2422f45af053'}
					onClick={switchEditMode}
					size={25}
					color={'#47C8F4'}
					data-tip={contents.toolTipDatas.tiersAccount}
				/>
				<ReactTooltip
					place={'top'}
					effect={'solid'}
					className={'tooltipCustom tiersAccountTooltip test'}
					multiline={true}
				/>
			</div>

			<AddWebSiteModal editMode={addMode} setEditMode={setAddMode} />
		</div>
	);
};
