/**
 * Import modules
 */

/**
 * Import locals
 */
import utility from '../utility';

export const previewFile = async (obj) => {
	const { fileName } = obj;
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'GET',
			`${utility.apiURL}file/preview?fileName=${fileName}`,
			token
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		if (error.message === 'Network Error') {
			return { ...error };
		}
		return { ...error.response.data, status: error.response.status };
	}
};

export const uploadFiles = async (formData) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'POST',
			`${utility.apiURL}file/`,
			token,
			formData
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const deleteFiles = async (fileList, type) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'DELETE',
			`${utility.apiURL}file/`,
			token,
			{ fileIds: fileList, type }
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const downloadFiles = async (obj) => {
	const { type, sub, project, assos, detail } = obj;

	try {
		const token = utility.getToken();
		const url =
			type === 'subvention'
				? `${utility.apiURL}file/dl/${assos}/subvention?subventionId=${sub}&detail=${detail}`
				: `${utility.apiURL}file/dl/${assos}/project?projectId=${project}&detail=${detail}`;
		const res = await utility.axiosReq('GET', url, token);
		return { ...res, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const getAllFilesList = async (associationId) => {
	try {
		const token = utility.getToken();
		const url = `${utility.apiURL}documents/association/${associationId}`;
		const res = await utility.axiosReq('GET', url, token);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};
