/**
 * Import modules
 */
import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { BiShow, BiHide } from 'react-icons/bi';

/**
 * Import components
 */
import Message from '../Message/Message';
import Buttons from '../Buttons/Buttons';

/**
 * Import Contexts
 */
import { useLoadingContext } from '../../contexts/loadingContext';

/**
 * Import locals
 */
import './ResetPassword.scss';
import { resetPassword } from '../../requests/userRequests';
import utility from '../../utility';
import contents from '../../contents/contents.json';

const ResetPassword = ({ title }) => {
	let history = useHistory();

	const { actions } = useLoadingContext();

	const { id, token } = useParams();

	const [newPassword, setNewPassword] = useState({
		password: '',
		confirmePassword: '',
	});

	const [showPassword, setShowPassword] = useState({
		password: false,
		confirmePassword: false,
	});

	const [message, setMessage] = useState('');
	const [status, setStatus] = useState('');

	const handleClickEyes = (evt) => {
		let dataset = evt.target.dataset.set;

		if (evt.target.tagName === 'path') {
			dataset = evt.target.parentNode.dataset.set;
		}
		setShowPassword({ ...showPassword, [dataset]: !showPassword[dataset] });
	};

	const formReset = () => {
		setNewPassword({
			password: '',
			confirmePassword: '',
		});
		setMessage('');
		setStatus('');
	};

	const handleInputChange = (evt) => {
		setNewPassword({ ...newPassword, [evt.target.id]: evt.target.value });
		setMessage('');
		setStatus('');
	};

	const handleSubmit = async (evt) => {
		evt.preventDefault();

		if (!utility.passwordValidation(newPassword.password)) {
			setMessage(contents.messages.resetPasswordwrongFormat);
			setStatus('error');
		} else if (newPassword.password === 'Pu63#td6') {
			setMessage(contents.messages.resetPasswordExamplePassword);
			setStatus('error');
		} else if (
			newPassword.password !== newPassword.confirmePassword ||
			newPassword.password === ''
		) {
			setMessage(contents.messages.resetPasswordNotSamePassword);
			setStatus('error');
		} else {
			const itemToSend = {
				userid: id,
				token,
				...newPassword,
			};
			// send to API
			actions.setter(true, true);
			const result = await resetPassword(itemToSend);

			if (result.status !== 200) {
				setMessage(result.message);
				setStatus('error');
			} else {
				setMessage(contents.messages.resetPasswordDone);
				setStatus('success');
				history.push('/login');
			}
			actions.setter(false, true);
		}
	};

	const handleClickReset = () => {
		formReset();
	};

	return (
		<div
			className={'ResetPassword'}
		>
			<h1
				className="PasswordReset-title"
			>{title}</h1 >

			<form
				className="PasswordReset-form"
				onSubmit={handleSubmit}
			>
				<Message
					status={status}
					message={message}
				/>
				<div
					className="password-container formInput-container"
				>
					<label
						htmlFor="password"
					>{contents.ResetPassword.password}</label >
					<input
						className={'password-input'}
						type={showPassword.password ? 'text' : 'password'}
						id="password"
						name="password"
						placeholder={contents.placeholders.ResetPassword}
						value={newPassword.password}
						onChange={handleInputChange}
					/>
					{showPassword.password ? (
						<BiShow
							size={25}
							className={'input-eyes'}
							data-set={'password'}
							onClick={handleClickEyes}
						/>
					) : (
						<BiHide
							size={25}
							className={'input-eyes'}
							data-set={'password'}
							onClick={handleClickEyes}
						/>
					)}
				</div >

				<div
					className="confirmePassword-container formInput-container"
				>
					<label
						htmlFor="confirmePassword"
					>
						{contents.ResetPassword.verifyPassword}
					</label >
					<input
						className={'confirmePassword-input'}
						type={showPassword.confirmePassword ? 'text' : 'password'}
						id="confirmePassword"
						name="confirmePassword"
						placeholder={contents.placeholders.ResetPassword}
						value={newPassword.confirmePassword}
						onChange={handleInputChange}
					/>
					{showPassword.confirmePassword ? (
						<BiShow
							size={25}
							className={'input-eyes'}
							data-set={'confirmePassword'}
							onClick={handleClickEyes}
						/>
					) : (
						<BiHide
							size={25}
							className={'input-eyes'}
							data-set={'confirmePassword'}
							onClick={handleClickEyes}
						/>
					)}
				</div >

				<span
					dangerouslySetInnerHTML={{
						__html: contents.ResetPassword.contentText,
					}}
				/>

				<Buttons
					customCSS={'resetPassword_buttons'}
					handleCancel={handleClickReset}
				/>
			</form >
		</div >
	);
};

export default ResetPassword;
