/**
 * Import modules
 */
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Switch from 'react-switch';

/**
 * Import components
 */
import Buttons from '../../Buttons/Buttons';

/**
 * Import contexts
 */
import { useProjectContext } from '../../../contexts/projectContext';

/**
 * Import locals
 */
import './AddParticipantModal.scss';
import contents from '../../../contents/contents.json';
import { useUserContext } from '../../../contexts/userContext';
import { addBeneficiary } from '../../../requests/beneficiaryRequests';
import { getOneProjectListById } from '../../../requests/projectsRequests';
import Message from '../../Message/Message';

const AddParticipantModal = () => {
	const {
		selectedProject,
		setParticipants,
		newParticipant,
		setNewParticipant,
		showAddParticipantModal,
		switchShowAddParticipantModal,
		setSelectedProject,
		setEditedProject,
		editCancel,
	} = useProjectContext();

	const { user } = useUserContext();

	const [error, setError] = useState(null);

	const handleChangeInput = async (evt) => {
		setError(null);

		const { value, id } = evt.target;

		setNewParticipant({
			...newParticipant,
			[id]: value,
			association_id: user.assos[0].id,
			project_id: selectedProject.id,
		});
	};

	const handleSwitchChange = async (value, evt, id) => {
		setNewParticipant({ ...newParticipant, [id]: value });
	};

	const handleSubmit = async (evt) => {
		evt.preventDefault();

		const addedBeneficiary = await addBeneficiary(newParticipant);
		console.log(
			'[AddParticipantModal.jsx]{L:66} addedBeneficiary: ',
			addedBeneficiary
		);

		if (addedBeneficiary.status !== 200) {
			switch (addedBeneficiary.message) {
				case 'beneficiary_id must be unique':
					setError('Cette personne participe déjà à ce projet.');
					break;
				default:
					setError(addedBeneficiary.message);
					break;
			}
			return;
		}

		const result = await getOneProjectListById(selectedProject.id);

		if (result.status !== 200) {
			setError(result.message);
			return;
		}

		if (result.data) {
			await setSelectedProject(result.data);
			await setEditedProject({ ...result.data, edited: false });

			await setParticipants(result.data.beneficiaries);
		}

		setNewParticipant({
			last_name: '',
			first_name: '',
			email: '',
			birthday: '',
			zipcode: '',
			city: '',
			association_id: '',
			project_id: '',
			present: false,
		});
		switchShowAddParticipantModal();
	};

	// useEffect(() => {
	// 	(async () => {
	// 		const getBeneficiaries = await getAllbeneficiaries();
	// 		setBeneficiaries(getBeneficiaries.data);
	// 	})();
	// }, []);

	return (
		<Modal
			key={'e0d1bf2c-2435-4b27-93d5-e4a082470e8b'}
			show={showAddParticipantModal}
			onHide={switchShowAddParticipantModal}
			size={'l'}
			aria-labelledby={'AddParticipantModal'}
			centered
			restoreFocus
			dialogClassName={'AddParticipantModal'}
		>
			<Modal.Header closeButton>
				<Modal.Title>{contents.titles.AddParticipantModal}</Modal.Title>
			</Modal.Header>
			<Modal.Body bsPrefix="modals-body">
				<form onSubmit={handleSubmit}>
					<div
						key={'bbdacfbb-f2c4-48c5-993b-a05437a2a44b'}
						className="addParticipantContainer"
					>
						<label
							key={'f3465a88-9802-4815-9e6a-9d6804469582'}
							htmlFor="last_name"
						>
							{contents.labels.lastname}
						</label>
						<input
							key={'573b85b2-d75c-4abf-ac83-2f31102b5187'}
							type="text"
							name="last_name"
							id="last_name"
							value={newParticipant.last_name}
							onChange={handleChangeInput}
							required
						/>
					</div>
					<div
						key={'b1ee0e73-76f0-4c15-aa3b-df30943111c1'}
						className="addParticipantContainer"
					>
						<label
							key={'4f4446fc-f646-4ddb-8781-9fd471c57a0a'}
							htmlFor="first_name"
						>
							{contents.labels.firstname}
						</label>
						<input
							key={'8baa53b3-2dce-43e2-861c-f214796723b0'}
							type="text"
							name="first_name"
							id="first_name"
							value={newParticipant.first_name}
							onChange={handleChangeInput}
							required
						/>
					</div>
					<div
						key={'0fe64011-0430-4c5b-a5a7-94e06d26ac71'}
						className="addParticipantContainer"
					>
						<label key={'a84926c7-6887-4989-ba03-28cc4c99e2c8'} htmlFor="email">
							{contents.labels.mail}
						</label>
						<input
							key={'24a3aa42-51ac-4249-ba24-db4f18d4402d'}
							type="email"
							name="email"
							id="email"
							value={newParticipant.email}
							onChange={handleChangeInput}
							required
						/>
					</div>
					<div
						key={'a3d2ad46-fe94-4c05-8997-bc64c2102cd7'}
						className="addParticipantContainer"
					>
						<label
							key={'c3b40794-b790-4325-bd5c-4a86c45d5b87'}
							htmlFor="birthday"
						>
							{contents.labels.birthday}
						</label>
						<input
							key={'a48fdd3f-1a51-4665-9c8e-1985d25b0bc6'}
							type="date"
							name="birthday"
							id="birthday"
							value={newParticipant.birthday}
							onChange={handleChangeInput}
							required
						/>
					</div>
					<div
						key={'fe393719-f9b8-452b-9be9-99ead7db0a21'}
						className="addParticipantContainer address"
					>
						<label
							key={'73bb17ea-a9a1-4eac-bd5f-ed7ee4a5156d'}
							htmlFor="address"
						>
							{contents.labels.address}
						</label>
						<input
							key={'e9bbdaf3-daca-4f36-bd23-0ecdcb4a76d1'}
							type="text"
							name="address"
							id="address"
							value={newParticipant.address}
							onChange={handleChangeInput}
						/>
					</div>
					<div
						key={'fe393719-f9b8-452b-9be9-99ead7db0a21'}
						className="addParticipantContainer"
					>
						<label
							key={'73bb17ea-a9a1-4eac-bd5f-ed7ee4a5156d'}
							htmlFor="zipcode"
						>
							{contents.labels.zipCode}
						</label>
						<input
							key={'e9bbdaf3-daca-4f36-bd23-0ecdcb4a76d1'}
							type="text"
							name="zipcode"
							id="zipcode"
							value={newParticipant.zipcode}
							onChange={handleChangeInput}
						/>
					</div>
					<div
						key={'f90e28ce-2a43-4156-9910-2dcc7d47574f'}
						className="addParticipantContainer"
					>
						<label key={'6758e9a0-080d-48be-a534-0e1c62f6428b'} htmlFor="city">
							{contents.labels.city}
						</label>
						<input
							key={'c2b7f111-deff-4504-bb89-6d6d9b251f03'}
							type="text"
							name="city"
							id="city"
							value={newParticipant.city}
							onChange={handleChangeInput}
						/>
					</div>
					<div
						key={'262e3840-16ab-427a-98da-9a933f4ee8e7'}
						className="addParticipantContainer present"
					>
						<label
							key={'1d26504b-b58f-49a7-8080-83b7a4149e38'}
							htmlFor="presence"
						>
							{contents.labels.presence}
						</label>
						<Switch
							id={'presence'}
							onChange={handleSwitchChange}
							checked={newParticipant.presence}
						/>
					</div>

					<Message message={error} status={`error`} />

					<Buttons
						key={'fc193dbd-87a5-4078-8d2e-308b17e84717'}
						customCSS={'editButtons'}
						handleCancel={editCancel}
					/>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default AddParticipantModal;
