import React, { useState, createContext, useContext } from 'react';

/**
 * import contexts
 */

// context creation
export const formContext = createContext('');

// context hook
export const useBackOfficeContext = () => {
	const context = useContext(formContext);
	if (!context) {
		console.error(
			'BackOfficeContext should not be undefined. Did you forget to wrap your component with BackOfficeContextProvider ?'
		);
	}
	return context;
};

// provider creation
export const BackOfficeContextProvider = ({ children }) => {
	const [edited, setEdited] = useState(true);

	const [associations, setAssociations] = useState([]);
	const [filteredAssociationList, setFilteredAssociationList] = useState([
		...associations,
	]);

	const [recievers, setRecievers] = useState([]);
	const [filteredRecievers, setFilteredRecievers] = useState([...recievers]);

	const [filteredUserList, setFilteredUserList] = useState([]);

	const [showAddModal, setShowAddModal] = useState(false);

	let value = {
		edited,
		recievers,
		filteredRecievers,
		showAddModal,
		filteredUserList,
		associations,
		filteredAssociationList,
		actions: {
			setEdited,
			setRecievers,
			setFilteredRecievers,
			setFilteredUserList,
			setShowAddModal,
			setAssociations,
			setFilteredAssociationList,
		},
	};

	return <formContext.Provider value={value}>{children}</formContext.Provider>;
};
