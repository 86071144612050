/**
 * Import modules
 */

/**
 * Import locals
 */
import utility from '../utility';

export const getAllWebSiteByFunderId = async (funderId) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'GET',
			`${utility.apiURL}websites?funderId=${funderId}`,
			token
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const createWebSite = async (website) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'POST',
			`${utility.apiURL}websites`,
			token,
			website
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const updateWebSite = async (website) => {
	const url = `${utility.apiURL}websites/${website.id}`;
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq('PATCH', url, token, website);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const deleteWebSite = async (websiteId) => {
	const url = `${utility.apiURL}websites/${websiteId}`;
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq('DELETE', url, token);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};
