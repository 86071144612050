/**
 * Import modules
 */

/**
 * Import locals
 */
import utility from '../utility';

export const addProductAccount = async (newAccount) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'POST',
			`${utility.apiURL}account/product/add`,
			token,
			newAccount
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const editProductAccount = async (editedAccount) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'PUT',
			`${utility.apiURL}account/product/update`,
			token,
			editedAccount
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const addTiersAccount = async (newAccount) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'POST',
			`${utility.apiURL}account/tiers/add`,
			token,
			newAccount
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};

export const editTiersAccount = async (editedAccount) => {
	try {
		const token = utility.getToken();
		const res = await utility.axiosReq(
			'PUT',
			`${utility.apiURL}account/tiers/update`,
			token,
			editedAccount
		);
		return { ...res.data, status: res.status };
	} catch (error) {
		return { ...error.response.data, status: error.response.status };
	}
};
